<template>
  <div
    v-if="getErrors.length"
    class="error-block">
    {{ getTranslation('form_error') }}:

    <div class="errors-list">
      <div v-if="getErrors.indexOf('agree-tp') > -1">
        {{ getTranslation('validation_need_to_check_regulations') }}
      </div>
      <div v-if="getErrors.indexOf('name') > -1">
        {{ getTranslation('validation_name_required') }}
      </div>
      <div v-if="getErrors.indexOf('name-format') > -1">
        {{ getTranslation('validation_wrong_name') }}
      </div>
      <div v-if="getErrors.indexOf('people-number') > -1">
        {{ getTranslation('validation_wrong_people_number') }}
      </div>
      <div v-if="getErrors.indexOf('children-number') > -1">
        {{ getTranslation('validation_wrong_children_number') }}
      </div>
      <div v-if="getErrors.indexOf('days-number') > -1 && !selectedServiceHasDailyEndAvailable">
        {{ getTranslation('validation_wrong_days_number') }}
      </div>
      <div v-if="getErrors.indexOf('days-number') > -1 && selectedServiceHasDailyEndAvailable">
        {{ getTranslation('validation_wrong_nights_number') }}
      </div>
      <div v-if="getErrors.indexOf('email') > -1">
        {{ getTranslation('validation_email_required') }}
      </div>
      <div v-if="getErrors.indexOf('email-format') > -1">
        {{ getTranslation('validation_wrong_email') }}
      </div>
      <div v-if="getErrors.indexOf('phone') > -1">
        {{ getTranslation('validation_phone_required') }}
      </div>
      <div v-if="getErrors.indexOf('phone-format') > -1">
        {{ getTranslation('validation_wrong_phone') }}
      </div>
      <div v-if="getErrors.indexOf('worker') > -1">
        {{ getTranslation('worker_required') }}
      </div>
      <div v-if="getErrors.indexOf('date') > -1">
        {{ getTranslation('validation_need_to_choose_termin') }}
      </div>
      <div v-if="getErrors.indexOf('hour') > -1">
        {{ getTranslation('validation_need_to_choose_hour') }}
      </div>
      <div v-if="getErrors.indexOf('periodicity') > -1">
        {{ getTranslation('validation_kind_of_reservations') }}
      </div>
      <div v-if="getErrors.indexOf('invoice_company_title') > -1 || getErrors.indexOf('invoice_address') > -1 || getErrors.indexOf('invoice_post_code') > -1 || getErrors.indexOf('invoice_city') > -1 || getErrors.indexOf('invoice_nip') > -1 || getErrors.indexOf('invoice_country') > -1">
        {{ getTranslation('validation_invoice_fields') }}
      </div>
      <div v-if="getErrors.join(',').indexOf('field-') > -1">
        {{ getTranslation('validation_parameters_not_filled') }}
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'errors',
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'getErrors',
      'selectedServiceHasDailyEndAvailable'
    ])
  }
}
</script>

<style lang="scss" scoped>
.error-block {
  margin: 10px 0 0 0;

  .errors-list {
    font-size: 13px;
    font-weight: bold;
    margin: 10px 0 5px 0;

    & > div {
      margin: 5px 0 0 0;
    }
  }
}
</style>
