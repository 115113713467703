<template>
  <div
    v-if="selectedServiceIsDailyService"
    class="days-section">
    <div :class="{
      'field': true,
      'is-required': true,
      'is-invalid': hasError('people-number')
    }">
      <label
        for="bookero-plugin-days-number"
        v-pure-html="getTranslation(selectedServiceHasDailyEndAvailable ? 'nights_count' : 'days_count')"></label>
      <div class="days-number-wrapper">
        <input
          type="number"
          v-model="daysNumber"
          id="bookero-plugin-days-number"
          @change="emitChange()"
          :min="1"
          step="1" />
        <div
          class="days-number-plus"
          @click="increaseDays()">+</div>
        <div
          class="days-number-minus"
          @click="decreaseDays()">–</div>
      </div>

      <div
        v-if="hasError('days-number')"
        class="error-message">
        <template v-if="selectedServiceHasDailyEndAvailable">
        {{ getTranslation('validation_wrong_nights_number') }}
        </template>
        <template v-else>
        {{ getTranslation('validation_wrong_days_number') }}
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'days',
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'hasError',
      'addError',
      'getDaysNumber',
      'getServices',
      'getSelectedService',
      'selectedServiceIsDailyService',
      'selectedServiceHasDailyEndAvailable'
    ])
  },
  data () {
    return {
      daysNumber: 1,
      isInvalid: false
    };
  },
  mounted () {
    this.daysNumber = this.getDaysNumber;
    this.emitChange();
    this.$bus.$on('bookero-plugin-reset-days-number', this.resetDaysNumber);
    this.$bus.$on('bookero-plugin-validate', this.validate);
  },
  methods: {
    emitChange () {
      this.$store.commit('removeError', 'days-number');
      this.daysNumber = parseInt(this.daysNumber.toString().replace(/[^0-9]/gmi, ''), 10);
      this.checkDaysNumberLimits();

      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:days-changed', {
        number: this.daysNumber
      });
      this.$store.commit('setDaysNumber', this.daysNumber);
    },
    checkDaysNumberLimits () {
      if (this.daysNumber <= 0) {
        this.daysNumber = 1;
      }
    },
    increaseDays () {
      this.daysNumber = parseInt(this.daysNumber.toString().replace(/[^0-9]/gmi, ''), 10);

      if (isNaN(this.daysNumber)) {
        this.daysNumber = 1;
      } else {
        this.daysNumber++;
      }

      this.emitChange();
    },
    decreaseDays () {
      if (isNaN(this.daysNumber)) {
        this.daysNumber = 1;
      }

      if (this.daysNumber > 1) {
        this.daysNumber = parseInt(this.daysNumber.toString().replace(/[^0-9]/gmi, ''), 10);

        if (isNaN(this.daysNumber)) {
          this.daysNumber = 1;
        } else {
          this.daysNumber--;
        }

        this.emitChange();
      }
    },
    resetDaysNumber () {
      let prevDaysNumber = this.daysNumber;
      this.daysNumber = 1;

      if (this.daysNumber !== prevDaysNumber) {
        this.emitChange();
      }
    },
    validate () {
      if (!this.daysNumber || isNaN(this.daysNumber)) {
        this.$store.commit('addError', 'days-number');
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-reset-days-number', this.resetDaysNumber);
    this.$bus.$off('bookero-plugin-validate', this.validate);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.days-section {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  width: 100%;

  .field {
    width: calc(50% - 10px);
  }

  #bookero-plugin-days-number {
    max-width: unset;
    padding: 0 50px;
    text-align: center;
    width: 100%;
  }

  .days-number-wrapper {
    position: relative;
    width: 100%;

    .days-number-plus,
    .days-number-minus {
      align-items: center;
      color: darken($color-border, 35%);
      cursor: pointer;
      display: flex;
      font-size: 20px;
      height: 48px;
      justify-content: center;
      position: absolute;
      top: 0;
      user-select: none;
      width: 50px;

      &:active,
      &:focus,
      &:hover {
        color: $color-primary;
      }
    }

    .days-number-plus {
      right: 0;
    }

    .days-number-minus {
      left: 0;
    }
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
</style>
