<template>
  <div :class="{
    'submit-section': true,
    'is-active': isActive,
    'submit-section-cart-enabled': getConfig.cart_enabled,
    'submit-section-cart-is-empty': !getInquiries.length,
    'submit-section-cart-step-1': $parent.cartStep === 1,
    'submit-section-cart-step-2': $parent.cartStep === 2
  }">
    <template v-if="step === 'cart-step-1'">
      <button
        class="submit-button submit-button-step-1"
        @click.prevent="goToStep(2)"
        :disabled="getConfig.cart_enabled && !hasInquiries">
        {{ getTranslation('go_to_summary') }}
      </button>

      <div
        v-if="getWaitingListInCurrentSelection"
        class="add-to-cart-waiting-list-notice">
        {{ getTranslation('you_are_adding_waiting_list_inquiry') }}
      </div>
    </template>

    <template v-if="step === 'cart-step-2'">
      <button
        :class="{
          'submit-button': true,
          'submit-button-step-2': true,
          'is-blocked': isSending
        }"
        :disabled="isSending"
        @click.prevent="submit">
        <template v-if="hasPaymentMethods && getPrice !== '0.00' && !!getPrice">
          <template v-if="selectedPaymentMethodIsCash || getWaitingListInInquiries">
            <template v-if="!isSending">
              {{ getTranslation('send') }}
            </template>
            <template v-else>
              {{ getTranslation('sent') }}
            </template>
          </template>
          <template v-else>
            <template v-if="!isSending">
              <template v-if="getConfig.auto_accept">
                {{ getTranslation('summary_payment_button') }}
              </template>
              <template v-else>
                {{ getTranslation('send') }}
              </template>
            </template>
            <template v-else>
              {{ getTranslation('sent') }}
            </template>
          </template>
        </template>
        <template v-else>
          <template v-if="!isSending">
            {{ getTranslation('send') }}
          </template>
          <template v-else>
            {{ getTranslation('sent') }}
          </template>
        </template>
      </button>

      <button
        class="back-button"
        @click.prevent="goToStep(1)">
          <template v-if="getConfig.cart_enabled">{{ getTranslation('back_to_cart') }}</template>
          <template v-else>{{ getTranslation('back_to_reservation') }}</template>
      </button>
    </template>
  </div>
</template>

<script>
import CommonMethods from './../mixins/CommonMethods';
import Tracking from './../mixins/Tracking';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'Submit',
  mixins: [
    CommonMethods,
    Tracking,
    Translations
  ],
  props: [
    'step',
    'isActive'
  ],
  computed: {
    ...mapGetters([
      'getCartID',
      'getConfig',
      'getErrors',
      'getFormState',
      'getInquiries',
      'getInquiryPrice',
      'getPrice',
      'getSelectedService',
      'getServices',
      'getServiceName',
      'getMode',
      'getWaitingListInCurrentSelection',
      'getWaitingListInInquiries',
      'hasPaymentMethods',
      'selectedPaymentMethodIsCash',
      'getWorkers',
      'selectedServiceIsDailyService',
      'selectedServiceRequiresChildren'
    ]),
    hasInquiries () {
      if (this.getInquiries.length > 0) {
        return true;
      }

      return false;
    },
    userReserveServiceWithInvoices () {
      let serviceIDs = [];
      let servicesWithInvoices = [];

      if (this.getConfig.invoice_settings) {
        servicesWithInvoices = this.getConfig.invoice_settings.services.map(serviceItem => serviceItem.id);
      }

      if (this.getInquiries.length) {
        serviceIDs = this.getInquiries.map(serviceData => serviceData.service);
      } else {
        serviceIDs = [this.getFormState.selectedService];
      }

      return servicesWithInvoices.some(serviceID => serviceIDs.includes(serviceID));
    }
  },
  data () {
    return {
      isSending: false
    };
  },
  methods: {
    submit () {
      this.$bus.$emit('bookero-plugin-validate');

      if (this.getErrors.length) {
        return;
      }

      this.trackingSubmitReservation();
      let pluginConfig = this.$store.state.pluginConfig;
      this.isSending = true;
      let paramsData = [];
      paramsData = paramsData.concat(this.$store.state.formState.specialParamsValues);
      paramsData = paramsData.concat(this.$store.state.formState.paramsValues);

      let params = {
        'data': {
          'name': this.getFormState.name,
          'phone': this.getFormState.phone,
          'parameters': paramsData.filter(obj => !(Object.keys(obj).length === 0 && obj.constructor === Object)),
          'services_names': this.getServiceNames(),
          'services_values': this.getServiceValues()
        }
      };

      let inquiries = [];

      if (!this.getConfig.cart_enabled) {
        let workerID = null;

        if (this.getFormState.selectedWorker) {
          workerID = this.getFormState.selectedWorker;
        } else if (this.getFormState.preSelectedWorker) {
          workerID = this.getFormState.preSelectedWorker;
        }

        let inquiryData = {
          plugin_comment: JSON.stringify(params),
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          date: this.getFormState.date,
          hour: this.getFormState.time,
          email: this.getFormState.email.trim(),
          service: this.getFormState.selectedService,
          worker: workerID,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          plugin_inquiry_id: null
        };

        if (this.selectedServiceIsDailyService) {
          inquiryData.days = this.getFormState.daysNumber;
          inquiryData.worker = null;
          inquiryData.hour = null;

          if (this.selectedServiceRequiresChildren) {
            inquiryData.children = this.getFormState.childrenNumber;
          }
        }

        inquiries.push(inquiryData);
      } else {
        inquiries = this.getInquiries;
        inquiries = this.fillInquiriesWithCustomerData(inquiries);
      }

      let dataToSubmit = {
        bookero_id: pluginConfig.id,
        agree_tp: this.getFormState.agreeTp,
        agree_newsletter: this.getFormState.agreeNewsletter,
        agree_telemarketing: this.getFormState.agreeTelemarketing,
        discount_code: this.getFormState.discountCode,
        configuration_payment_id: this.getFormState.selectedPaymentMethod,
        lang: pluginConfig.lang,
        cart_id: this.getFormState.cartID,
        name: this.getFormState.name,
        phone: this.getFormState.phone,
        email: this.getFormState.email.trim(),
        inquiries: inquiries
      };

      if (
        this.getConfig.invoice_settings &&
        this.getConfig.invoice_settings.invoices_available &&
        this.userReserveServiceWithInvoices && (
          this.getFormState.user_want_invoice ||
          this.getConfig.invoice_settings.invoices_required
        )
      ) {
        dataToSubmit.invoice_data = {
          is_company: this.getFormState.invoice_is_company,
          company_title: this.getFormState.invoice_company_title,
          address: this.getFormState.invoice_address,
          post_code: this.getFormState.invoice_post_code,
          city: this.getFormState.invoice_city,
          country: this.getFormState.invoice_country,
          nip: this.getFormState.invoice_nip
        };
      }

      this.$http.post(this.$endpointURL + '/add', dataToSubmit).then(res => {
        if (res && res.data && res.data.result && res.data.result > 0) {
          this.$bus.$emit(
            'bookero-plugin-show-result-popup',
            res.data.data,
            JSON.parse(JSON.stringify(this.getInquiries)),
            JSON.parse(JSON.stringify(this.getServices)),
            JSON.parse(JSON.stringify(this.getWorkers))
          );
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:form-submitted', { cartID: this.getFormState.cartID });
          this.$store.commit('removeInquiries');
          this.$store.commit('clearForm');

          if (
            this.getConfig.service_two_step_choice || (
              window.bookero_config &&
              window.bookero_config.custom_config &&
              window.bookero_config.custom_config.use_service_category_id
            ) || (
              window.bookero_config &&
              window.bookero_config.custom_config &&
              window.bookero_config.custom_config.select_service_category_id
            )
          ) {
            this.$store.commit('setDefaultServiceCategory');
          }

          this.$store.commit('setDefaultService');
          this.$store.commit('setDefaultPaymentMethod');
          this.$bus.$emit('bookero-plugin-form-clear');
          this.$bus.$emit('bookero-plugin-show-inquiry-form');
          this.goToStep(1);

          setTimeout(() => {
            this.$store.commit('setDefaultService');
          }, 0);
        } else {
          this.$bus.$emit('bookero-plugin-show-alert-popup', {
            title: this.getTranslation('validation_error_has_occurred'),
            desc: this.getTranslation('validation_add_reservation_failed')
          });
        }

        this.isSending = false;
      }).catch(() => {
        this.isSending = false;
        this.$bus.$emit('bookero-plugin-show-alert-popup', {
          title: this.getTranslation('validation_error_has_occurred'),
          desc: this.getTranslation('validation_add_reservation_failed')
        });
      });
    },
    goToStep (stepNumber) {
      if (stepNumber === 2 && !this.getConfig.cart_enabled) {
        this.$bus.$emit('bookero-plugin-validate');

        if (this.getFormState.waitingList) {
          this.$store.commit('setWaitingListInInquiries', true);
        } else {
          this.$store.commit('setWaitingListInInquiries', false);
        }

        setTimeout(() => {
          if (this.getErrors.length) {
            return;
          }

          this.trackingAddToCart();
          this.$bus.$emit('bookero-plugin-get-price');
          this.$parent.hideInquiryForm();
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:form-go-to-step', { step: stepNumber });
          this.navigateTo(stepNumber, '.bookero-plugin-form', 10);
        }, 250);
      } else {
        if (stepNumber === 2) {
          if (this.getErrors.length) {
            return;
          }

          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:form-go-to-step', { step: stepNumber });
          this.navigateTo(stepNumber, '.bookero-plugin-header-summary', 10);
        } else {
          if (stepNumber === 1 && !this.getConfig.cart_enabled) {
            this.$parent.showInquiryForm();
          }

          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:form-go-to-step', { step: stepNumber });
          this.navigateTo(stepNumber);
        }
      }
    },
    navigateTo (stepNumber, destinationElement = '.bookero-plugin-form', offset = 50) {
      this.$parent.cartStep = stepNumber;
      this.$store.commit('removeErrors');

      setTimeout(() => {
        if (this.getMode === 'sticky') {
          document.querySelector(destinationElement).scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
        } else {
          let scrollOffset = offset;

          if (window.bookero_config.override && window.bookero_config.override.scroll_after_add_to_cart_additional_offset) {
            scrollOffset += parseInt(window.bookero_config.override.scroll_after_add_to_cart_additional_offset, 10);
          }

          this.$smoothScrollTo(document.querySelector(destinationElement), scrollOffset);
        }
      }, 350);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../assets/scss/variables.scss';

.submit-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 20px 0 0 0;
  padding: 0;
  width: 100%;

  &:not(.is-active) {
    opacity: .25;
    pointer-events: none;
  }

  .add-to-cart-waiting-list-notice {
    background: lighten($color-warning, 30%);
    border: 1px solid $color-warning;
    border-radius: 5px;
    color: darken($color-warning, 15%);
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
  }

  &-cart-enabled {
    &.submit-section-cart-step-1 {
      &.submit-section-cart-is-empty {
        margin: 10px 0 0 0;
      }
    }
  }

  &-cart-step-2 {
    width: 100%;

    .submit-button {
      margin: 0 0 0 auto;
      order: 2;
    }

    .back-button {
      order: 1;
    }
  }

  .back-button {
    background: transparent;
    border: none;
    color: $color-text-light;
    cursor: pointer;
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 0;
    padding: 0;
    text-decoration: none;
    text-transform: none;

    &:active,
    &:focus,
    &:hover {
      color: $color-primary;
    }
  }

  .submit-button {
    background: $color-primary;
    border: none;
    border-radius: $border-radius-small;
    color: $color-light;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0;
    padding: 13px 60px 13px 30px;
    position: relative;
    transition: right .2s ease-out;
    text-transform: none;

    &[disabled] {
      cursor: not-allowed;
      opacity: .5;
    }

    &.is-blocked {
      pointer-events: none;
    }

    &:after {
      border: 2px solid $color-light;
      border-left: none;
      border-bottom: none;
      content: "";
      height: 8px;
      position: absolute;
      right: 28px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      transition: all .2s ease-out;
      width: 8px;
    }

    &:active,
    &:focus,
    &:hover {
      background: darken($color-primary, 10%);

      &:after {
        right: 25px;
      }
    }
  }
}

@media (max-width: 540px) {
  .submit-section {
    .submit-button {
      margin: 10px 0 0 0;
      width: 100%;
    }

    .back-button {
      margin: 20px 0 0 0;
      order: 2;
      width: 100%;
    }
  }
}
</style>
