<template>
  <div
    v-if="getTemporaryPrice && getTemporaryPrice.substr(0, 4) !== '0.00' && getTemporaryPrice.replace(/[^0-9,.]/gmi, '') !== '0,00'"
    :data-error-text="getTranslation('price_load_error')"
    :class="{
      'temporary-price-section': true,
      'is-loading': priceIsLoading,
      'is-error': !priceIsLoading && priceLoadError
    }"
    @click="reloadPrice()">
    <p class="temporary-price-quote">
      {{ getTranslation('cart_price') }}:
      <strong class="temporary-price-price">
        {{ getFinalPrice() }}
      </strong>
    </p>

    <small
      v-if="getServicePeriodicityPaymentType(getSelectedService)">
      {{ getTranslation('price_for_single_reservation') }}
    </small>
  </div>
</template>

<script>
import CurrencyUtils from './../helpers/CurrencyUtils';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'temporary-price',
  mixins: [
    Translations
  ],
  props: [
    'priceLoadError'
  ],
  computed: {
    ...mapGetters([
      'getTemporaryPrice',
      'getTemporaryCurrency',
      'getSelectedService',
      'getServicePeriodicityPaymentType'
    ])
  },
  data () {
    return {
      priceIsLoading: false
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-get-temporary-price-start', this.getPriceStart);
    this.$bus.$on('bookero-plugin-get-temporary-price-end', this.getPriceEnd);
  },
  methods: {
    getFinalPrice () {
      return CurrencyUtils.getQuote(this.getTemporaryPrice, this.getTemporaryCurrency);
    },
    getPriceStart () {
      this.priceIsLoading = true;
    },
    getPriceEnd () {
      this.priceIsLoading = false;
    },
    reloadPrice () {
      if (this.priceLoadError) {
        this.priceIsLoading = true;
        this.$bus.$emit('bookero-plugin-get-temporary-price-reload');
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-get-temporary-price-start', this.getPriceStart);
    this.$bus.$off('bookero-plugin-get-temporary-price-end', this.getPriceEnd);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.temporary-price-section {
  border-top: 1px solid $color-border;
  margin: 0 0 30px 0;
  padding: 30px 0 20px 0;
  position: relative;
  text-align: right;
  width: 100%;

  &.is-loading {
    &:after {
      background: rgba(255, 255, 255, .5);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:before {
      animation: spinner 1s linear infinite;
      border: 2px solid $color-primary;
      border-top-color: $color-light!important;
      border-radius: $border-radius-full;
      content: "";
      display: block;
      height: 32px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 32px;
      z-index: 1;
    }
  }

  &.is-error {
    &:after {
      background: rgba(255, 255, 255, .95);
      content: "";
      left: 0;
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:before {
      color: $color-danger;
      content: attr(data-error-text);
      display: block;
      height: auto;
      left: 50%;
      line-height: 18px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      z-index: 1;
    }
  }

  .temporary-price {
    &-quote {
      color: $color-text;
      font-size: 14px;
      margin: 0;
      padding: 0;
      text-align: right;
    }

    &-method {
      color: $color-text;
      font-size: 14px;
      margin: 5px 0 0 0;
      text-align: right;
    }

    &-price {
      color: $color-text-dark;
      font-size: 24px;
      font-weight: 500;
      padding-left: 5px;
    }

    &-payment-method {
      color: $color-text-dark;
      font-style: normal;
      font-weight: bold;
    }
  }
}

@keyframes spinner {
  0% {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  100% {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}
</style>
