import Vue from 'vue';
import BookeroPlugin from './BookeroPlugin.vue';
import SmoothScrollTo from './components/helpers/SmoothScroll.js';
import Axios from 'axios';
import DOMPurify from 'dompurify';
import store from './store/index';

DOMPurify.addHook('afterSanitizeAttributes', function (node) {
  // set all elements owning target to target=_blank
  if ('target' in node) {
    node.setAttribute('target', '_blank');
    node.setAttribute('rel', 'noopener noreferrer');
  }

  // set non-HTML/MathML links to xlink:show=new
  if (!node.hasAttribute('target') && (node.hasAttribute('xlink:href') || node.hasAttribute('href'))) {
    node.setAttribute('xlink:show', 'new');
  }
});

(function () {
  if (!window.bookero_config) {
    return;
  }

  if (
    window.bookero_config.type !== 'sticky' &&
    (
      !window.bookero_config.container ||
      !document.querySelector('#' + window.bookero_config.container)
    )
  ) {
    return;
  }

  if (window.bookero_config.type === 'sticky') {
    let stickyContainer = document.createElement('div');
    stickyContainer.setAttribute('id', 'bookero-sticky-plugin');
    document.body.appendChild(stickyContainer);
    window.bookero_config.container = 'bookero-sticky-plugin';
  }

  Vue.config.productionTip = false;
  Vue.prototype.$bus = new Vue();
  Vue.prototype.$http = Axios;
  Vue.prototype.$endpointURL = process.env.VUE_APP_API_URL;
  Vue.prototype.$isDev = process.env.VUE_APP_DEV_MODE;
  Vue.prototype.$http.defaults.headers['Cache-Control'] = 'no-store,max-age=0';
  Vue.prototype.$smoothScrollTo = SmoothScrollTo;

  const DOMPurifyConfig = {
    ADD_TAGS: ['iframe'],
    ADD_ATTR: ['allow', 'allowfullscreen', 'frameborder', 'scrolling']
  };

  // Register v-pure-html directive
  Vue.directive('pure-html', {
    inserted: function (el, binding) {
      if (binding.oldValue === binding.value) {
        return;
      }
      el.innerHTML = DOMPurify.sanitize(binding.value, DOMPurifyConfig);
    },
    update: function (el, binding) {
      if (binding.oldValue === binding.value) {
        return;
      }
      el.innerHTML = DOMPurify.sanitize(binding.value, DOMPurifyConfig);
    }
  });

  (function () {
    if (!window.bookero_config.plugin_css || window.location.host === 'localhost:8080') {
      return;
    }

    var d = document;
    var l = d.createElement('link');
    l.setAttribute('rel', 'stylesheet');
    l.setAttribute('type', 'text/css');
    l.href = process.env.VUE_APP_ASSETS_URL + 'css/bookero-compiled-v-' + process.env.VUE_APP_VERSION + '.css';
    d.head.appendChild(l);
  })();

  if (process.env.VUE_APP_TEST_API === 'yes') {
    window.bookero_plugin_test_api = new Vue({
      store,
      render: h => h(BookeroPlugin)
    }).$mount('#' + window.bookero_config.container);
  } else {
    new Vue({
      store,
      render: h => h(BookeroPlugin),
      data () {
        return {
          instanceID: ''
        };
      },
      beforeMount () {
        this.instanceID = this.generateUUID();
      },
      methods: {
        generateUUID () {
          return Math.random().toString(36).substr(2, 9);
        },
        dispatchEvent (eventType, eventData = {}) {
          let event = new CustomEvent(eventType, {
            detail: {
              instanceID: this.instanceID,
              data: eventData
            }
          });

          document.body.dispatchEvent(event);
        }
      }
    }).$mount('#' + window.bookero_config.container);
  }
})();
