<template>
  <div class="agreements-section">
    <p
      v-if="getConfig.information_agree"
      class="agreement-disclaimer"
      v-pure-html="getConfig.information_agree_text"></p>

    <p
      v-if="hasMoreThanOneAgreement"
      class="agreements-agree">
      <switcher
        v-model="allAgreements"
        :label="getTranslation('check_all_agrees')"
        :isSpecial="true"
        :isAgreement="true"
        key="agree-tp-all"
        @click.native="setAllAgreements()" />
    </p>

    <p
      v-if="$store.state.config.agree_tp"
      class="agreements-agree">
      <switcher
        v-model="agreeTp"
        :isInvalid="hasError('agree-tp')"
        :isRequired="true"
        :isAgreement="true"
        :label="$store.state.config.agree_tp_text"
        key="agree-tp-switcher"
        @click.native="setAgreement('agreeTp')" />
    </p>

    <div
      v-if="hasError('agree-tp')"
      class="error-message">
      {{ getTranslation('validation_the_field_is_required') }}
    </div>

    <p
      v-if="$store.state.config.agree_newsletter"
      class="agreements-agree">
      <switcher
        v-model="agreeNewsletter"
        :label="$store.state.config.agree_newsletter_text"
        :isAgreement="true"
        key="agree-newsletter-switcher"
        @click.native="setAgreement('agreeNewsletter')" />
    </p>

    <p
      v-if="$store.state.config.agree_telemarketing"
      class="agreements-agree">
      <switcher
        v-model="agreeTelemarketing"
        :label="$store.state.config.agree_telemarketing_text"
        :isAgreement="true"
        key="agree-telemarketing-switcher"
        @click.native="setAgreement('agreeTelemarketing')" />
    </p>
  </div>
</template>

<script>
import Switcher from '../elements/Switcher';
import Translations from '../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'agreements',
  components: {
    'switcher': Switcher
  },
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'getConfig',
      'getFormState',
      'hasError'
    ]),
    hasMoreThanOneAgreement () {
      let sum = 0;
      let agreements = ['agree_tp', 'agree_newsletter', 'agree_telemarketing'];

      for (let i = 0; i < agreements.length; i++) {
        if (this.$store.state.config[agreements[i]]) {
          sum++;
        }
      }

      return sum > 1;
    }
  },
  data () {
    return {
      allAgreements: false,
      agreeTp: false,
      agreeNewsletter: false,
      agreeTelemarketing: false
    }
  },
  mounted () {
    this.$bus.$on('bookero-plugin-validate', this.validate);
    this.agreeTp = this.getFormState.agreeTp;
    this.agreeNewsletter = this.getFormState.agreeNewsletter;
    this.agreeTelemarketing = this.getFormState.agreeTelemarketing;
  },
  methods: {
    setAgreement (type) {
      let payload = {
        type: type,
        value: this[type]
      }

      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:agreement-changed', { agreementType: type, agreementValue: this[type] });
      this.$store.commit('setAgreement', payload);
      this.$store.commit('removeError', 'agree-tp');
    },
    setAllAgreements () {
      if (this.$store.state.config.agree_tp) {
        this.agreeTp = this.allAgreements;
        this.setAgreement('agreeTp');
      }

      if (this.$store.state.config.agree_newsletter) {
        this.agreeNewsletter = this.allAgreements;
        this.setAgreement('agreeNewsletter');
      }

      if (this.$store.state.config.agree_telemarketing) {
        this.agreeTelemarketing = this.allAgreements;
        this.setAgreement('agreeTelemarketing');
      }
    },
    validate () {
      if (this.$store.state.config.agree_tp && !this.getFormState.agreeTp) {
        this.$store.commit('addError', 'agree-tp');
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);
  }
}
</script>

<style scoped lang="scss">
@import '../../assets/scss/variables.scss';

.agreements-section {
  color: $color-text;
  padding: 10px 0;
  width: 100%;

  .agreement-disclaimer {
    font-size: 14px;
    line-height: 1.6;
    margin: 20px 0;
    padding: 0;
  }

  .agreements-agree {
    align-items: center;
    display: flex;
    margin: 10px 0!important;
    padding: 0;

    /deep/ .switcher-label {
      font-size: 14px;
    }

    /deep/ .switcher {
      margin: 0;
    }
  }

  .error-message {
    margin: -15px 0 0 40px;
  }
}
</style>
