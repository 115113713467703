<script>
export default {
  name: 'Translations',
  methods: {
    getTranslation (key, noOverrides = false) {
      if ((key === 'services_title' || key === 'cart_inquiry_service') && this.$store.getters.getConfig.service_name && !noOverrides) {
        return this.$store.getters.getConfig.service_name;
      }

      if (this.$store.state.translations && this.$store.state.translations[key]) {
        return this.$store.state.translations[key];
      }

      return this.$isDev === 'yes' ? key : '-';
    }
  }
}
</script>
