<template>
  <div
    :style="'top: ' + y + 'px; left: ' + x + 'px;'"
    :class="{ 'tooltip': true, 'is-displayed': isDisplayed, 'is-visible': isVisible }">
    <div 
      ref="tooltip-content"
      class="tooltip-inner"
      :style="'left: ' + contentX + ';'">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'tooltip',
  data () {
    return {
      text: '',
      x: 0,
      y: 0,
      contentX: 'auto',
      isDisplayed: false,
      isVisible: false
    };
  },
  mounted () {
    this.$bus.$on('show-tooltip', this.show);
    this.$bus.$on('hide-tooltip', this.hide);
  },
  methods: {
    show (text, sourceElement) {
      this.isVisible = true;
      this.text = text;
      let wrapperElement = document.querySelector('.bookero-plugin-form-wrapper[data-instance-id="' + this.$root.instanceID + '"] > form');
      let wrapperElementBox = wrapperElement.getBoundingClientRect();
      let sourceElementBox = sourceElement.getBoundingClientRect();
      this.x = (sourceElementBox.left + (sourceElementBox.width / 2)) - wrapperElementBox.left;
      this.y = ((sourceElementBox.top + sourceElementBox.height) + 5) - wrapperElementBox.top;

      setTimeout(() => {
        let tooltipWidth = this.$refs['tooltip-content'].clientWidth;
        let tooltipBaseOffset = this.x;
        
        if (tooltipBaseOffset < tooltipWidth / 2) {
          this.contentX = Math.abs(tooltipBaseOffset - (tooltipWidth / 2)) + 'px';
        } else if (wrapperElementBox.width - tooltipBaseOffset < (tooltipWidth / 2)) {
          this.contentX = (-1 * ((tooltipWidth / 2) - (wrapperElementBox.width - tooltipBaseOffset))) + 'px';
        } else {
          this.contentX = 'auto';
        }

        this.isDisplayed = true;
      }, 0);
    },
    hide () {
      this.isVisible = false;
      this.isDisplayed = false;
    }
  },
  beforeDestroy () {
    this.$bus.$off('show-tooltip', this.show);
    this.$bus.$off('hide-tooltip', this.hide);
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.tooltip {
  display: none;
  opacity: 0;
  padding: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(-50%);
  transition: opacity .25s ease-out;
  z-index: 1;

  &.is-visible {
    display: block;
  }

  &.is-displayed {
    opacity: 1;
  }

  &:after {
    content: "";
    border: 5px solid $color-dark;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    left: 50%;
    position: absolute;
    top: -10px;
    transform: translateX(-50%);
  }

  .tooltip-inner {
    background: $color-dark;
    border-radius: $border-radius-small;
    color: $color-light;
    font-size: 12px;
    font-weight: 500;
    padding: 5px 10px;
    position: absolute;
    pointer-events: none;
    transform: translateX(-50%);
    white-space: nowrap;
  }
}

@media (max-width: 1040px) {
  .tooltip {
    .tooltip-inner {
      padding: 2px 3px;
      text-align: center;
      white-space: normal;
      width: 80px;
    }
  }
}
</style>
