<template>
  <div :class="{
    'add-to-cart-section': true,
    'is-active': isActive
  }">
    <a
      v-if="getInquiries.length"
      href="#close"
      class="cancel-button"
      @click.prevent="hideInquiryForm()">
      {{ getTranslation('action_cancel') }}
    </a>

    <button
      :class="{ 'add-button': true, 'is-blocked': isLoading || getWaitingListInInquiries }"
      @click.prevent="addToCart">
      <template v-if="this.getFormState.waitingList">
        {{ getTranslation('go_to_summary') }}
      </template>
      <template v-else-if="!isLoading">
        {{ getTranslation('add_to_cart') }}
      </template>
      <template v-else-if="isLoading">
        {{ getTranslation('adding_to_cart') }}
      </template>
    </button>

    <div
      v-if="getWaitingListInCurrentSelection"
      class="add-to-cart-waiting-list-notice">
      {{ getTranslation('you_are_adding_waiting_list_inquiry') }}
    </div>
  </div>
</template>

<script>
import CommonMethods from './../mixins/CommonMethods';
import Tracking from './../mixins/Tracking';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'AddToCart',
  mixins: [
    CommonMethods,
    Tracking,
    Translations
  ],
  props: [
    'isActive'
  ],
  computed: {
    ...mapGetters([
      'getWaitingListInCurrentSelection',
      'getChildrenNumber',
      'getConfig',
      'getDaysNumber',
      'getDate',
      'getErrors',
      'getFormState',
      'getInquiries',
      'getTime',
      'getServices',
      'getSelectedService',
      'getSelectedWorker',
      'getPreSelectedWorker',
      'getPeopleNumber',
      'getWaitingListInInquiries',
      'selectedServiceIsDailyService',
      'selectedServiceRequiresChildren'
    ]),
    selectedServiceHasDates () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === this.getSelectedService)[0];

        if (!service) {
          return false;
        }

        return service.available_in_dates === 1;
      }

      return false;
    }
  },
  data () {
    return {
      isLoading: false
    };
  },
  methods: {
    addToCart () {
      if (this.isLoading) {
        return;
      }

      this.$bus.$emit('bookero-plugin-validate');

      setTimeout(() => {
        if (this.getErrors.length) {
          return;
        }

        this.submit();
      }, 500);
    },
    submit () {
      if (this.getFormState.waitingList) {
        this.$store.commit('setWaitingListInInquiries', true);
        this.$store.commit('addInquiry', {
          params: this.getParams(),
          inquiryID: 0,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration
        });
        this.$bus.$emit('bookero-plugin-hide-inquiry-form');
        this.trackingAddToCart();
        return;
      }

      let pluginConfig = this.$store.state.pluginConfig;
      this.isLoading = true;

      let paramsData = [];
      paramsData = paramsData.concat(this.$store.state.formState.specialParamsValues);
      paramsData = paramsData.concat(this.$store.state.formState.paramsValues);

      let params = {
        'data': {
          'parameters': paramsData.filter(obj => !(Object.keys(obj).length === 0 && obj.constructor === Object))
        }
      };

      let workerID = 0;

      if (this.getSelectedWorker) {
        workerID = this.getSelectedWorker;
      } else if (this.getPreSelectedWorker) {
        workerID = this.getPreSelectedWorker;
      }

      let inquiryData = {
        bookero_id: pluginConfig.id,
        plugin_comment: JSON.stringify(params),
        worker: workerID,
        service: this.getSelectedService,
        people: this.getPeopleNumber,
        date: this.getDate,
        hour: this.getTime,
        cart_id: this.getFormState.cartID,
        periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
        custom_duration_id: this.getFormState.selectedCustomDuration
      };

      if (this.selectedServiceIsDailyService) {
        inquiryData.days = this.getDaysNumber;
        inquiryData.worker = null;
        inquiryData.hour = null;

        if (this.selectedServiceRequiresChildren) {
          inquiryData.children = this.getChildrenNumber
        }
      }

      this.$http.post(this.$endpointURL + '/blockTermin', inquiryData).then(res => {
        if (res.data.result === 1 && res.data.data.cart_id) {
          let inquiryID = res.data.data.plugin_inquiry_id;

          this.$store.commit('addInquiry', {
            params: this.getParams(),
            inquiryID: inquiryID,
            connectedTermins: res.data.data.connectedTermins,
            periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
            custom_duration_id: this.getFormState.selectedCustomDuration
          });

          /* WAITS FOR ISSUE #43
          this.$store.commit('addCurrentlyBlockedTermin', {
            id: inquiryID,
            parentID: 0,
            date: this.getDate,
            time: this.getTime
          });

          for (let i = 0; i < res.data.data.connectedTermins.length; i++) {
            let connectedTermin = res.data.data.connectedTermins[i];
            let dateTime = connectedTermin.start_at.split(' ');
            let date = dateTime[0];
            let time = dateTime[1].split(':');
            time = [time[0], time[1]].join(':');

            this.$store.commit('addCurrentlyBlockedTermin', {
              id: connectedTermin.plugin_inquiry_id,
              parentID: inquiryID,
              date: date,
              time: time
            });
          }
          */

          this.$bus.$emit('bookero-plugin-get-price');
          this.$store.commit('setCartID', res.data.data.cart_id);
          this.isLoading = false;
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:inquiry-added', { inquiryID: inquiryID });
          this.trackingAddToCart();
          this.resetDateTime();
          this.$bus.$emit('bookero-plugin-hide-inquiry-form');
        } else {
          this.isLoading = false;
          this.$bus.$emit('bookero-plugin-show-alert-popup', {
            title: this.getTranslation('validation_error_has_occurred'),
            desc: this.getTranslation('validation_add_to_cart_failed')
          });
        }
      }).catch((e) => {
        this.isLoading = false;
        this.$bus.$emit('bookero-plugin-show-alert-popup', {
          title: this.getTranslation('validation_error_has_occurred'),
          desc: this.getTranslation('validation_add_to_cart_failed')
        });
      });
    },
    getParams () {
      let paramsData = [];
      paramsData = paramsData.concat(this.$store.state.formState.specialParamsValues);
      paramsData = paramsData.concat(this.$store.state.formState.paramsValues);

      let params = {
        'data': {
          'name': this.getFormState.name,
          'parameters': paramsData.filter(obj => !(Object.keys(obj).length === 0 && obj.constructor === Object)),
          'services_names': this.getServiceNames(),
          'services_values': this.getServiceValues()
        }
      };

      return params;
    },
    resetDateTime () {
      if (this.getConfig.add_to_cart_reset === 'datetime') {
        this.$store.dispatch('setDate', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        this.$store.dispatch('setTime', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
      }

      if (this.getConfig.add_to_cart_reset === 'time' && !this.selectedServiceHasDates) {
        this.$store.dispatch('setTime', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
      }

      setTimeout(() => {
        this.$bus.$emit('bookero-plugin-reload-date', 'add-to-cart');
        this.$bus.$emit('bookero-plugin-reload-time', 'add-to-cart');
      }, 0);
    },
    hideInquiryForm () {
      this.$bus.$emit('bookero-plugin-hide-inquiry-form');
    }
  }
};
</script>

<style lang="scss" scoped>
@import './../../assets/scss/variables.scss';

.add-to-cart-section {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  margin: 10px 0 0 0;
  padding: 0;
  width: 100%;

  &:not(.is-active) {
    opacity: .25;
    pointer-events: none;
  }

  .add-to-cart-waiting-list-notice {
    background: lighten($color-warning, 30%);
    border: 1px solid $color-warning;
    border-radius: 5px;
    color: darken($color-warning, 15%);
    font-size: 14px;
    font-weight: bold;
    margin: 10px 0;
    padding: 10px 15px;
    text-align: center;
    width: 100%;
  }

  .cancel-button {
    color: $color-text-light;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    text-decoration: none;
    text-transform: none;

    &:active,
    &:focus,
    &:hover {
      color: $color-danger;
    }
  }

  .add-button {
    background: $color-primary;
    border: none;
    border-radius: $border-radius-small;
    color: $color-light;
    cursor: pointer;
    font-size: 16px;
    letter-spacing: 0;
    margin: 0 0 0 auto;
    padding: 13px 60px 13px 30px;
    position: relative;
    text-transform: none;

    &:after {
      border: 2px solid $color-light;
      border-left: none;
      border-bottom: none;
      content: "";
      height: 8px;
      position: absolute;
      right: 28px;
      top: 50%;
      transform: translateY(-50%) rotate(45deg);
      transition: all .2s ease-out;
      width: 8px;
    }

    &:active,
    &:focus,
    &:hover {
      background: darken($color-primary, 10%);

      &:after {
        right: 25px;
      }
    }

    &[disabled] {
      opacity: .5;
      pointer-events: none;
    }

    &.is-blocked {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

@media (max-width: 580px) {
  .add-to-cart-section {
    display: flex;
    flex-wrap: wrap;

    .add-button {
      width: 100%;
    }

    .cancel-button {
      margin: 20px 0 0 0;
      order: 2;
      text-align: center;
      width: 100%;
    }
  }
}
</style>
