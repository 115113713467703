import Vue from 'vue';
import defaultState from './default.state';

export default {
  clear (state) {
    state = Object.assign(state, defaultState);
  },
  initialized (state) {
    state.initialized = true;
  },
  setAgreement (state, payload) {
    Vue.set(state.formState, payload.type, payload.value);
  },
  setCalendarData (state, calendarData) {
    Vue.set(state, 'calendarData', calendarData);
  },
  setCalendarCurrentMonth: (state, month) => {
    Vue.set(state.calendarState, 'currentMonth', month);
  },
  setWeekData (state, weekData) {
    Vue.set(state, 'weekData', weekData);
  },
  setCurrentWeek: (state, week) => {
    Vue.set(state.weekState, 'currentWeek', week);
  },
  setConfig (state, config) {
    let servicesList = JSON.parse(JSON.stringify(config.services_list));

    if (
      window.bookero_config &&
      window.bookero_config.custom_config &&
      window.bookero_config.custom_config.use_worker_id
    ) {
      let workerID = parseInt(window.bookero_config.custom_config.use_worker_id, 10);
      servicesList = servicesList.filter(service => service.workers.indexOf(workerID) > -1);
    } else if (
      window.bookero_config &&
      window.bookero_config.custom_config &&
      window.bookero_config.custom_config.use_worker_ids
    ) {
      let workerIDs = window.bookero_config.custom_config.use_worker_ids.map(n => parseInt(n, 10));

      servicesList = servicesList.filter(service => {
        let matchingWorkers = service.workers.filter(worker => workerIDs.includes(worker));
        return matchingWorkers.length === 1;
      });
    }

    let servicesIDs = servicesList.map(service => service.id);

    let specialParams = config.parameters.filter(param => param.update_hours).map(param => {
      param.connected_services = param.connected_services.filter(serviceID => servicesIDs.indexOf(serviceID) > -1);
      return param;
    });

    let normalParams = config.parameters.filter(param => !param.update_hours).map(param => {
      param.connected_services = param.connected_services.filter(serviceID => servicesIDs.indexOf(serviceID) > -1);
      return param;
    });

    if (config.invoice_settings && config.invoice_settings.personal_invoice_as_default) {
      Vue.set(state.formState, 'invoice_is_company', false);
    }

    Vue.set(state, 'config', config);
    Vue.set(state, 'specialParams', specialParams);
    Vue.set(state, 'params', normalParams);
    Vue.set(state, 'services', servicesList);
    Vue.set(state, 'workers', JSON.parse(JSON.stringify(config.workers_list)));
    Vue.set(state, 'translations', JSON.parse(JSON.stringify(config.lang_vars)));
    Vue.delete(state.config, 'services_list');
    Vue.delete(state.config, 'workers_list');
  },
  setCustomConfig (state, config) {
    Vue.set(state, 'custom_config', config);
  },
  setCustomerName (state, name) {
    Vue.set(state.formState, 'name', name);
  },
  setCustomerEmail (state, email) {
    Vue.set(state.formState, 'email', email);
  },
  setCustomerPhone (state, phone) {
    if (phone.indexOf('+') === -1 && phone.trim() !== '') {
      phone = '+' + phone;
    }

    Vue.set(state.formState, 'phone', phone);
  },
  setCurrency (state, currency) {
    Vue.set(state.formState, 'currency', currency);
  },
  setTemporaryCurrency (state, currency) {
    Vue.set(state.formState, 'temporaryCurrency', currency);
  },
  setDiscountCode (state, discountCode) {
    Vue.set(state.formState, 'discountCode', discountCode);
  },
  setPaymentMethod (state, paymentMethod) {
    Vue.set(state.formState, 'selectedPaymentMethod', paymentMethod);
  },
  setPrice (state, price) {
    Vue.set(state.formState, 'price', price);
  },
  setTemporaryPrice (state, price) {
    Vue.set(state.formState, 'temporaryPrice', price);
  },
  setInquiryPrices (state, prices) {
    Vue.set(state.formState, 'prices', prices);
  },
  setDefaultPaymentMethod (state) {
    if (state.config.payment_methods.length) {
      state.formState.selectedPaymentMethod = state.config.payment_methods.filter(method => method.is_default)[0].id;
    }
  },
  setDefaultService (state) {
    if (state.config.services) {
      if (
        window.bookero_config &&
        window.bookero_config.custom_config &&
        window.bookero_config.custom_config.use_service_id
      ) {
        Vue.set(state.formState, 'selectedService', window.bookero_config.custom_config.use_service_id);
      } else if (
        window.bookero_config &&
        window.bookero_config.custom_config &&
        window.bookero_config.custom_config.select_service_id
      ) {
        Vue.set(state.formState, 'selectedService', window.bookero_config.custom_config.select_service_id);
      } else {
        if (
          window.bookero_config &&
          window.bookero_config.custom_config &&
          window.bookero_config.custom_config.use_service_category_id
        ) {
          let catID = window.bookero_config.custom_config.use_service_category_id;
          let servicesInCategory = state.services.filter(service => service.category_id === catID);

          if (servicesInCategory[0]) {
            Vue.set(state.formState, 'selectedService', servicesInCategory[0].id);
          } else {
            Vue.set(state.formState, 'selectedService', state.services[0].id);
          }
        } else if (
          window.bookero_config &&
          window.bookero_config.custom_config &&
          window.bookero_config.custom_config.select_service_category_id
        ) {
          let catID = window.bookero_config.custom_config.select_service_category_id;
          let servicesInCategory = state.services.filter(service => service.category_id === catID);

          if (servicesInCategory[0]) {
            Vue.set(state.formState, 'selectedService', servicesInCategory[0].id);
          } else {
            if (state.config.select_first_service || state.services.length < 2) {
              Vue.set(state.formState, 'selectedService', state.services[0].id);
            } else {
              Vue.set(state.formState, 'selectedService', 0);
            }
          }
        } else {
          if (state.config.select_first_service || state.services.length < 2) {
            Vue.set(state.formState, 'selectedService', state.services[0].id);
          } else {
            Vue.set(state.formState, 'selectedService', 0);
          }
        }
      }
    }
  },
  setDefaultServiceCategory (state) {
    if (state.config.services) {
      if (
        window.bookero_config &&
        window.bookero_config.custom_config &&
        window.bookero_config.custom_config.use_service_category_id
      ) {
        Vue.set(state.formState, 'selectedServiceCategory', window.bookero_config.custom_config.use_service_category_id);
      } else if (
        window.bookero_config &&
        window.bookero_config.custom_config &&
        window.bookero_config.custom_config.select_service_category_id
      ) {
        Vue.set(state.formState, 'selectedServiceCategory', window.bookero_config.custom_config.select_service_category_id);
      } else {
        Vue.set(state.formState, 'selectedServiceCategory', state.services[0].category_id);
      }
    }
  },
  setServices (state, services) {
    Vue.set(state, 'services', services);
  },
  setWorkers (state, workers) {
    Vue.set(state, 'workers', workers);
  },
  setService (state, serviceID) {
    Vue.set(state.formState, 'selectedService', serviceID);
  },
  setServiceCategory (state, serviceCategoryID) {
    Vue.set(state.formState, 'selectedServiceCategory', serviceCategoryID);
  },
  setWorker (state, workerID) {
    Vue.set(state.formState, 'selectedWorker', workerID);
  },
  setPreSelectedWorker (state, workerID) {
    Vue.set(state.formState, 'preSelectedWorker', workerID);
  },
  setSpecialParams (state, specialParams) {
    Vue.set(state, 'specialParams', specialParams);
  },
  setParams (state, params) {
    Vue.set(state, 'params', params);
  },
  setPeopleNumber (state, peopleNumber) {
    Vue.set(state.formState, 'peopleNumber', peopleNumber);
  },
  setChildrenNumber (state, childrenNumber) {
    Vue.set(state.formState, 'childrenNumber', childrenNumber);
  },
  setDaysNumber (state, daysNumber) {
    Vue.set(state.formState, 'daysNumber', daysNumber);
  },
  setPeriodicity (state, periodicity) {
    Vue.set(state.formState, 'selectedPeriodicity', periodicity);
  },
  setCustomDuration (state, customDuration) {
    Vue.set(state.formState, 'selectedCustomDuration', customDuration);
  },
  setDateTime (state, dateTime) {
    Vue.set(state.formState, 'date', dateTime.date);
    Vue.set(state.formState, 'time', dateTime.time);
  },
  setDate (state, payload) {
    if (typeof payload === 'string') {
      payload = {
        date: payload,
        isWaitingList: false
      };
    }

    Vue.set(state.formState, 'date', payload.date);
    Vue.set(state.formState, 'waitingList', payload.isWaitingList === 1);
  },
  setDateMonthOffset (state, offset) {
    Vue.set(state.calendarState, 'currentMonthOnDateSelect', offset);
  },
  setDateWeekOffset (state, offset) {
    Vue.set(state.weekState, 'currentWeekOnDateSelect', offset);
  },
  setTime (state, payload) {
    if (typeof payload === 'string') {
      payload = {
        time: payload,
        isWaitingList: false
      };
    }

    Vue.set(state.formState, 'time', payload.time);
    Vue.set(state.formState, 'waitingList', payload.isWaitingList === -1);
  },
  setTranslations (state, translations) {
    Vue.set(state, 'translations', translations);
  },
  setParamValues (state, payload) {
    if (payload.type === 'special') {
      Vue.set(state.formState, 'specialParamsValues', payload.values);
    } else {
      Vue.set(state.formState, 'paramsValues', payload.values);
    }
  },
  addError (state, errorName) {
    if (state.errors.indexOf(errorName) === -1) {
      state.errors.push(errorName);
    }
  },
  removeError (state, errorName) {
    state.errors = state.errors.filter(function (value) {
      return value !== errorName;
    });
  },
  removeErrors (state) {
    state.errors = [];
  },
  setFormState (state, key, value) {
    Vue.set(state.formState, key, value);
  },
  setWaitingListInInquiries (state, value) {
    Vue.set(state, 'waitingListInInquiries', value);
  },
  addInquiry (state, payload) {
    let workerID = null;

    if (state.formState.selectedWorker) {
      workerID = state.formState.selectedWorker;
    } else if (state.formState.preSelectedWorker) {
      workerID = state.formState.preSelectedWorker;
    }

    let inquiry = {
      plugin_inquiry_id: payload.inquiryID,
      plugin_comment: JSON.stringify(payload.params),
      phone: state.formState.phone,
      people: state.formState.peopleNumber,
      date: state.formState.date,
      hour: state.formState.time,
      email: state.formState.email,
      service: state.formState.selectedService,
      worker: workerID,
      periodicity_id: payload.periodicity_id,
      custom_duration_id: payload.custom_duration_id,
      discount_code: state.formState.discountCode,
      connectedTermins: payload.connectedTermins
    };

    if (state.formState.selectedService !== 0) {
      let service = state.services.filter(service => service.id === state.formState.selectedService)[0];

      if (service && service.available_in_dates === 3) {
        inquiry.days = state.formState.daysNumber;
        inquiry.worker = null;
        inquiry.hour = null;

        if (service.children_required) {
          inquiry.children = state.formState.childrenNumber;
        }
      }
    }

    Vue.set(state.formState, 'waitingList', false);
    state.inquiries.push(inquiry);
  },
  removeInquiry (state, pluginInquiryID) {
    let index = state.inquiries.findIndex(inquiry => inquiry.plugin_inquiry_id === pluginInquiryID);
    state.inquiries.splice(index, 1);

    if (!state.inquiries.length) {
      Vue.set(state, 'waitingListInInquiries', false);
    }
  },
  removeInquiries (state) {
    Vue.set(state, 'inquiries', []);
    Vue.set(state, 'waitingListInInquiries', false);
  },
  setCommonPaymentMethods (state, methods) {
    state.commonPaymentMethods = methods;
  },
  clearForm (state) {
    Vue.set(state, 'formState', JSON.parse(JSON.stringify(defaultState.formState)));

    if (state.config.invoice_settings && state.config.invoice_settings.personal_invoice_as_default) {
      Vue.set(state.formState, 'invoice_is_company', false);
    }

    Vue.set(state, 'inquiries', []);
    Vue.set(state, 'errors', []);
    Vue.set(state, 'commonPaymentMethods', []);
    Vue.set(state, 'waitingListInInquiries', false);
  },
  setCartID (state, id) {
    Vue.set(state.formState, 'cartID', id);
  },
  addCurrentlyBlockedTermin (state, payload) {
    state.currentlyBlockedTermins.push({
      id: payload.id,
      parentID: payload.parentID,
      date: payload.date,
      time: payload.time
    });
  },
  removeCurrentlyBlockedTermin (state, id) {
    state.currentlyBlockedTermins = state.currentlyBlockedTermins.filter(termin => termin.id === id || termin.parentID === id);
  },
  removeServicesByCategories (state, ids) {
    state.services = state.services.filter(service => ids.indexOf(service.category_id) === -1);
  },
  removeServicesById (state, ids) {
    state.services = state.services.filter(service => ids.indexOf(service.id) === -1);
  },
  setDiscountCodeVisibility (state, newValue) {
    state.formState.discountCodeVisibility = newValue;
  },
  setDiscountCodeStatus (state, newValue) {
    state.formState.discountCodeStatus = newValue;
  },
  setPriceBeforeDiscount (state, newValue) {
    state.formState.priceBeforeDiscount = newValue;
  },
  setPriceAfterDiscount (state, newValue) {
    state.formState.priceAfterDiscount = newValue;
  },
  setDiscountValue (state, newValue) {
    state.formState.discountValue = newValue;
  },
  setCustomDurationsData (state, payload) {
    state.customDurations[payload.serviceID] = payload.customDurationsData;
  },
  userWantInvoice (state, payload) {
    state.formState['user_want_invoice'] = payload;
  },
  setInvoiceField (state, payload) {
    state.formState['invoice_' + payload.field] = payload.value;
  }
};
