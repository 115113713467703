function distanceToTop (element, predefinedOffset) {
  return Math.floor(element.getBoundingClientRect().top - predefinedOffset);
};

function smoothScrollTo (element, predefinedOffset = 0) {
  if (!element) {
    return;
  }

  let topOffset = distanceToTop(element, predefinedOffset);
  window.scrollBy({ top: topOffset, left: 0, behavior: 'smooth' });

  let scrollIsDone = setInterval(function () {
    let bottomAchieved = window.innerHeight + window.pageYOffset >= document.body.offsetHeight - 2;

    if (distanceToTop(element, predefinedOffset) === 0 || bottomAchieved) {
      clearInterval(scrollIsDone);
    }
  }, 100);
};

export default smoothScrollTo;
