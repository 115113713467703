<template>
  <div :class="{
    'services-section': true,
    'has-data-loading-issue': hasDataLoadingIssue
  }">
    <div
      v-if="getConfig.service_two_step_choice"
      class="field is-required">
      <label
        v-if="getCustomConfigField('use_service_category_id') === null"
        for="bookero-plugin-service-category"
        v-pure-html="getTranslation('choose_service_category')"></label>
      <select
        v-if="!getConfig.use_styled_dropdowns && getCustomConfigField('use_service_category_id') === null"
        id="bookero-plugin-service-category"
        v-model="selectedCategory"
        @change="changedCategory">
        <option
          v-if="!getConfig.select_first_service && getServices.length > 1"
          key="services-category-00-0"
          :value="-1">
          {{ getTranslation('select_service_category') }}
        </option>
        <option
          v-if="servicesInCategory(0).length"
          :value="0"
          key="services-category-00">
          {{ getTranslation('services_without_category') }}
        </option>
        <option
          v-for="(servicesCategory, index) of filteredServicesCategories"
          :value="servicesCategory.id"
          :key="'services-category-' + index">
          {{ servicesCategory.name }}
        </option>
      </select>

      <v-select
        v-if="getConfig.use_styled_dropdowns && getCustomConfigField('use_service_category_id') === null"
        v-model="selectedCategory"
        @input="changedCategory"
        :options="filteredServicesCategoriesForVueSelect"
        :custom-label="categoryNameById"
        :placeholder="getTranslation('choose_service_category')"
        :allowEmpty="false"
        :showLabels="false"
        :searchable="false">
        <div slot="noResult">
          {{ getTranslation('cant_find_category') }}
        </div>
        <div slot="noOptions">
          {{ getTranslation('the_list_is_empty') }}
        </div>
      </v-select>

      <span
        v-if="getCustomConfigField('use_service_category_id') !== null"
        class="bookero-plugin-service-category-value">
        <template v-if="getServiceCategoryName(getCustomConfigField('use_service_category_id')) === null">
          <strong>{{ getTranslation('service_categories') }}:</strong>
          {{ getTranslation('services_without_category') }}
        </template>
        <template v-else>
          <strong>{{ getTranslation('service_categories') }}:</strong>
          {{ getServiceCategoryName(getCustomConfigField('use_service_category_id')) }}
        </template>
      </span>
    </div>

    <div :class="{
      'field': true,
      'is-required': true,
      'is-invalid': hasError('service'),
      'is-inactive': selectedCategory === -1
    }">
      <label
        v-if="getCustomConfigField('use_service_id') === null"
        for="bookero-plugin-service"
        v-pure-html="getTranslation('services_title')"></label>
      <select
        v-if="!getConfig.use_styled_dropdowns && !getConfig.service_two_step_choice && getCustomConfigField('use_service_category_id') === null && getCustomConfigField('use_service_id') === null"
        id="bookero-plugin-service"
        v-model="selectedService"
        @change="changedService">
        <option
          v-if="!getConfig.select_first_service && getServices.length > 1"
          key="services-list-group-00"
          :value="0">
          {{ getTranslation('select_service') }}
        </option>
        <option
          v-for="(service, subindex) of servicesInCategory(0)"
          :value="service.id"
          :key="'services-list-group-0-' + subindex">
          {{ service.name }}
          <template v-if="showServicePrice(service)">
             ({{ getServicePrice(service.additional_price) }})
          </template>
        </option>
        <optgroup
          v-for="(servicesCategory, index) of filteredServicesCategories"
          :label="servicesCategory.name"
          :key="'services-list-group-' + index">
          <option
            v-for="(service, subindex) of servicesInCategory(servicesCategory.id)"
            :value="service.id"
            :key="'services-list-group-' + index + '-' + subindex">
            {{ service.name }}
            <template v-if="service.additional_price !== '0.00' && !!service.additional_price && !!service.payment && showPaymentsInForm">
               ({{ getServicePrice(service.additional_price) }})
            </template>
          </option>
        </optgroup>
      </select>

      <select
        v-if="!getConfig.use_styled_dropdowns && (getConfig.service_two_step_choice || getCustomConfigField('use_service_category_id') !== null) && getCustomConfigField('use_service_id') === null"
        id="bookero-plugin-service"
        v-model="selectedService"
        @change="changedService">
        <option
          v-if="!getConfig.select_first_service && getServices.length > 1"
          key="services-list-group-00"
          :value="0">
          {{ getTranslation('select_service') }}
        </option>
        <option
          v-for="(service, index) of servicesInCategory(selectedCategory)"
          :value="service.id"
          :key="'services-list-group-' + index">
          {{ service.name }}
          <template v-if="showServicePrice(service)">
             ({{ getServicePrice(service.additional_price) }})
          </template>
        </option>
      </select>

      <v-select
        v-if="getConfig.use_styled_dropdowns && (getConfig.service_two_step_choice || getCustomConfigField('use_service_category_id') !== null) && getCustomConfigField('use_service_id') === null"
        v-model="selectedService"
        @input="changedService"
        :options="servicesInCategoryForVueSelect"
        :custom-label="serviceNameById"
        :placeholder="getTranslation('services_title')"
        :allowEmpty="false"
        :showLabels="false"
        :searchable="false">
        <div slot="noResult">
          {{ getTranslation('cant_find_service') }}
        </div>
        <div slot="noOptions">
          {{ getTranslation('the_list_is_empty') }}
        </div>
      </v-select>

      <v-select
        v-if="getConfig.use_styled_dropdowns && !getConfig.service_two_step_choice && getCustomConfigField('use_service_category_id') === null && getCustomConfigField('use_service_id') === null"
        v-model="selectedService"
        @input="changedService"
        :options="servicesInCategoryForVueSelectGrouped"
        :custom-label="serviceNameById"
        :placeholder="getTranslation('services_title')"
        :allowEmpty="false"
        :showLabels="false"
        group-values="services"
        group-label="category"
        :group-select="false"
        :class="{
          'bookero-plugin-services-list-styled-all': true,
          'has-first-group-to-hide': true,
          'has-only-one-group': hasOnlyOneServiceCategory
        }"
        :searchable="false">
        <div slot="noResult">
          {{ getTranslation('cant_find_service') }}
        </div>
        <div slot="noOptions">
          {{ getTranslation('the_list_is_empty') }}
        </div>
      </v-select>

      <span
        v-if="getCustomConfigField('use_service_id') !== null"
        class="bookero-plugin-service-value">
        <strong>{{ getTranslation('cart_inquiry_service') }}:</strong>
        {{ getServiceName(getCustomConfigField('use_service_id')) }}
      </span>

      <div
        v-if="hasError('service')"
        class="error-message">
        {{ getTranslation('validation_choose_the_service') }}
      </div>
    </div>

    <div
      v-if="selectedServiceHasCustomDurations(selectedService)"
      :class="{
        'field': true,
        'custom-duration-field': true,
        'is-loading': durationsAreLoading
      }">
      <label for="bookero-plugin-service-custom-duration" v-pure-html="getTranslation('custom_duration_label')"></label>

      <select
        v-if="!getConfig.use_styled_dropdowns"
        id="bookero-plugin-service-custom-duration"
        v-model="selectedCustomDuration"
        :class="{ 'is-loading': durationsAreLoading }"
        @change="changedCustomDuration">
        <option
          v-for="(duration, index) of customDurations"
          :value="duration.id"
          :key="'custom-duration-' + index">
          {{ duration.name }}
          <template v-if="duration.price !== '0.00' && !!duration.price && showPaymentsInForm && !serviceIsCyclic()">
             ({{ getServicePrice(duration.price) }})
          </template>
        </option>
      </select>

      <v-select
        v-if="getConfig.use_styled_dropdowns"
        v-model="selectedCustomDuration"
        @input="changedCustomDuration"
        :options="customDurationsForVueSelect"
        :custom-label="durationNameById"
        :class="{ 'is-loading': durationsAreLoading }"
        :placeholder="getTranslation('custom_duration_label')"
        :allowEmpty="false"
        :showLabels="false"
        :searchable="false">
      </v-select>

      <small
        v-if="hasDataLoadingIssue"
        class="loading-error-info">
        {{ getTranslation('custom_duration_loading_error') }}
        <a
          href="#"
          @click.prevent="getCustomDurations(false, true)">
          {{ getTranslation('custom_duration_loading_error_try_again') }}
        </a>
      </small>
    </div>
  </div>
</template>

<script>
import CurrencyUtils from './../helpers/CurrencyUtils';
import CommonMethods from './../mixins/CommonMethods';
import Translations from './../mixins/Translations';
import MultiSelect from 'vue-multiselect';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'services',
  mixins: [
    CommonMethods,
    Translations
  ],
  components: {
    'v-select': MultiSelect
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getCustomConfigField',
      'getCurrency',
      'getServices',
      'getServiceName',
      'getServiceCategoryName',
      'getCustomDuration',
      'getSelectedService',
      'getSelectedServiceCategory',
      'getFormState',
      'hasError',
      'selectedServiceHasCustomDurations',
      'showPaymentsInForm'
    ]),
    filteredServicesCategories () {
      let categoriesToUse = this.getCustomConfigField('filter_categories_by_id');

      if (categoriesToUse) {
        categoriesToUse = categoriesToUse.map(n => parseInt(n, 10));
      }

      return this.servicesCategories.filter(servicesCategory => {
        if (categoriesToUse) {
          return categoriesToUse.indexOf(servicesCategory.id) > -1 && this.servicesInCategory(servicesCategory.id).length;
        }

        return this.servicesInCategory(servicesCategory.id).length;
      });
    },
    hasOnlyOneServiceCategory () {
      return this.servicesCategories.filter(servicesCategory => {
        return this.servicesInCategory(servicesCategory.id).length;
      }).length === 0;
    },
    filteredServicesCategoriesForVueSelect () {
      if (this.servicesInCategory(0).length) {
        if (!this.getConfig.select_first_service && this.getServices.length > 1) {
          return [-1, 0].concat(this.filteredServicesCategories.map(category => category.id));
        } else {
          return [0].concat(this.filteredServicesCategories.map(category => category.id));
        }
      }

      if (!this.getConfig.select_first_service && this.getServices.length > 1) {
        return [-1].concat(this.filteredServicesCategories.map(category => category.id));
      } else {
        return this.filteredServicesCategories.map(category => category.id);
      }
    },
    customDurationsForVueSelect () {
      return this.customDurations.map(duration => duration.id);
    },
    servicesInCategoryForVueSelect () {
      if (this.getConfig.select_first_service || this.servicesInCategory(this.selectedCategory).length < 2) {
        return this.servicesInCategory(this.selectedCategory).map(service => service.id);
      }

      return [0].concat(this.servicesInCategory(this.selectedCategory).map(service => service.id));
    },
    servicesInCategoryForVueSelectGrouped () {
      let output = [];

      if (this.getConfig.select_first_service || this.getServices.length < 2) {
        output = [
          {
            category: this.hasOnlyOneServiceCategory ? null : this.getTranslation('services_without_category'),
            services: this.servicesInCategory(0).map(service => service.id)
          }
        ];
      } else {
        output = [
          {
            category: '',
            services: [0]
          },
          {
            category: this.hasOnlyOneServiceCategory ? null : this.getTranslation('services_without_category'),
            services: this.servicesInCategory(0).map(service => service.id)
          }
        ];
      }

      for (let i = 0; i < this.servicesCategories.length; i++) {
        let id = this.servicesCategories[i].id;

        output.push({
          category: this.categoryNameById(id),
          services: this.servicesInCategory(id).map(service => service.id)
        });
      }

      return output;
    },
    servicesCategories () {
      let categoriesIDs = [];
      let categories = [];
      let services = this.getServices.filter(service => !this.filteredServices || (this.filteredServices.indexOf(service.id) > -1))

      for (let i = 0; i < services.length; i++) {
        let categoryID = services[i].category_id;

        if (categoryID === 0) {
          continue;
        } else if (categoriesIDs.indexOf(categoryID) === -1) {
          categoriesIDs.push(categoryID);
          categories.push(services[i].category);
        }
      }

      return categories;
    },
    hasPreselectedServiceCategory () {
      return this.getConfig.service_two_step_choice && this.getCustomConfigField('use_service_category_id') !== null;
    },
    hasPreselectedService () {
      return this.getCustomConfigField('use_service_id');
    }
  },
  data () {
    return {
      filteredServices: null,
      selectedCategory: 0,
      selectedService: 0,
      selectedCustomDuration: 0,
      customDurations: [],
      durationsAreLoading: false,
      hasDataLoadingIssue: false
    };
  },
  beforeMount () {
    this.hideValues();

    if (this.getConfig.service_two_step_choice && !this.getConfig.select_first_service && this.getServices.length > 2) {
      this.selectedCategory = -1;
    }

    if (this.$parent.paramExistsInUrl('filter_categories_by_id')) {
      let filteredCategoriesByID = this.$parent.getParamFromUrl('filter_categories_by_id', 'comma-separated-int') || null;

      if (filteredCategoriesByID) {
        window.bookero_config.custom_config.filter_categories_by_id = filteredCategoriesByID;
      }
    }
  },
  mounted () {
    if (!this.getConfig.cart_enabled && !!this.getSelectedService) {
      this.selectedCategory = this.getSelectedServiceCategory;

      Vue.nextTick(() => {
        this.selectedService = this.getSelectedService;

        Vue.nextTick(() => {
          if (this.getCustomDuration) {
            this.selectedCustomDuration = this.getCustomDuration;
          }

          this.getCustomDurations(true);
        });
      });

      return;
    }

    if (
      (
        this.getConfig.add_to_cart_reset === 'none' ||
        this.getConfig.add_to_cart_reset === 'time' ||
        this.hasPreselectedServiceCategory ||
        this.hasPreselectedService
      ) &&
      !!this.getSelectedService
    ) {
      this.selectedCategory = this.getSelectedServiceCategory;

      Vue.nextTick(() => {
        this.selectedService = this.getSelectedService;

        Vue.nextTick(() => {
          this.getCustomDurations();
        });
      });

      return;
    }

    this.$store.commit('setDefaultService');

    if (this.getConfig.service_two_step_choice && this.filteredServicesCategoriesForVueSelect.length) {
      this.selectedCategory = this.filteredServicesCategoriesForVueSelect[0];
      this.changedCategory();
    }

    this.setInitialValues();
    this.getCustomDurations();
  },
  methods: {
    hideValues () {
      let hiddenCategories = this.getCustomConfigField('hidden_service_category_ids') || [];
      let hiddenServices = this.getCustomConfigField('hidden_service_ids') || [];

      if (hiddenCategories.length) {
        this.$store.commit('removeServicesByCategories', hiddenCategories);
      }

      if (hiddenServices.length) {
        this.$store.commit('removeServicesById', hiddenServices);
      }
    },
    setInitialValues () {
      let loadedFromUrl = this.$parent.getParamsAlreadyLoadedFromUrl();
      let initializedCustomConfig = this.$parent.getInitializedCustomConfig();
      let selectedServiceID = this.getCustomConfigField('select_service_id');
      let selectedServiceCategoryID = this.getCustomConfigField('select_service_category_id');
      let forcedServiceID = this.getCustomConfigField('use_service_id');
      let forcedServiceCategoryID = this.getCustomConfigField('use_service_category_id');
      let filteredServicesByID = this.getCustomConfigField('filter_services_by_id');

      if (!window.bookero_config.custom_config) {
        window.bookero_config.custom_config = {};
      }

      if (this.$parent.paramExistsInUrl('use_service_id')) {
        forcedServiceID = this.$parent.getParamFromUrl('use_service_id', 'int') || null;

        if (forcedServiceID) {
          window.bookero_config.custom_config.use_service_id = forcedServiceID;
        }
      }

      if (this.$parent.paramExistsInUrl('use_service_category_id')) {
        forcedServiceCategoryID = this.$parent.getParamFromUrl('use_service_category_id', 'int') || null;

        if (forcedServiceCategoryID) {
          window.bookero_config.custom_config.use_service_category_id = forcedServiceCategoryID;
        }
      }

      if (this.$parent.paramExistsInUrl('filter_services_by_id')) {
        filteredServicesByID = this.$parent.getParamFromUrl('filter_services_by_id', 'comma-separated-int') || null;

        if (filteredServicesByID) {
          window.bookero_config.custom_config.filter_services_by_id = filteredServicesByID;
        }
      }

      if (filteredServicesByID) {
        this.filteredServices = filteredServicesByID;
      }

      if (filteredServicesByID) {
        this.selectedService = this.filteredServices[0];

        if (!this.getConfig.service_two_step_choice) {
          this.changedService();
        } else {
          let service = this.getServices.filter(service => service.id === this.selectedService)[0];
          this.selectedCategory = service.category_id;
          this.changedCategory(false);
          this.changedService();
        }

        return;
      }

      if (initializedCustomConfig.indexOf('service_category') === -1 && (selectedServiceCategoryID !== null || forcedServiceCategoryID !== null)) {
        if (selectedServiceCategoryID !== null) {
          this.selectedCategory = this.serviceCategoryIdExists(selectedServiceCategoryID) ? selectedServiceCategoryID : 0;
        } else {
          this.selectedCategory = this.serviceCategoryIdExists(forcedServiceCategoryID) ? forcedServiceCategoryID : 0;
        }

        this.changedCategory();
        this.$parent.setCustomConfigAsInitialized('service_category');
      } else if (this.$parent.paramExistsInUrl('service_category_id') && loadedFromUrl.indexOf('service_category_id') === -1) {
        let idToSet = this.$parent.getParamFromUrl('service_category_id', 'int') || this.getSelectedServiceCategory;
        this.selectedCategory = this.serviceCategoryIdExists(idToSet) ? idToSet : 0;
        this.changedCategory();
        this.$parent.setParamAsLoadedFromUrl('service_category_id');
      } else {
        this.selectedCategory = this.getSelectedServiceCategory;
      }

      if (initializedCustomConfig.indexOf('service') === -1 && (selectedServiceID !== null || forcedServiceID !== null)) {
        if (selectedServiceID !== null) {
          this.selectedService = this.serviceIdExists(selectedServiceID) ? selectedServiceID : this.getSelectedService;
        } else {
          this.selectedService = this.serviceIdExists(forcedServiceID) ? forcedServiceID : this.getSelectedService;
        }

        if (!this.getConfig.service_two_step_choice) {
          this.changedService();
          this.$parent.setCustomConfigAsInitialized('service');
        } else {
          let service = this.getServices.filter(service => service.id === this.selectedService)[0];
          this.selectedCategory = service.category_id;
          this.changedCategory(false);
          this.changedService();
          this.$parent.setCustomConfigAsInitialized('service');
        }
      } else if (this.$parent.paramExistsInUrl('service_id') && loadedFromUrl.indexOf('service_id') === -1) {
        if (!this.getConfig.service_two_step_choice) {
          let idToSet = this.$parent.getParamFromUrl('service_id', 'int') || this.getSelectedService;
          this.selectedService = this.serviceIdExists(idToSet) ? idToSet : this.getSelectedService;
          this.changedService();
          this.$parent.setParamAsLoadedFromUrl('service_id');
        } else {
          let idToSet = this.$parent.getParamFromUrl('service_id', 'int') || this.getSelectedService;
          this.selectedService = this.serviceIdExists(idToSet) ? idToSet : this.getSelectedService;
          let service = this.getServices.filter(service => service.id === this.selectedService)[0];
          this.selectedCategory = service.category_id;
          this.changedCategory(false);
          this.changedService();
          this.$parent.setParamAsLoadedFromUrl('service_id');
        }
      } else {
        if (!this.getConfig.service_two_step_choice) {
          this.selectedService = this.getSelectedService;
        } else {
          this.selectedService = this.getSelectedService;
          let service = this.getServices.filter(service => service.id === this.selectedService)[0];

          if (service) {
            this.selectedCategory = service.category_id;
          }
        }
      }

      if (this.getCustomConfigField('use_worker_ids')) {
        let service = this.getServices.filter(service => service.id === this.selectedService);
        let workerIDs = this.getCustomConfigField('use_worker_ids');

        if (service && service[0]) {
          let firstWorkerID = service[0].workers.filter(worker => workerIDs.indexOf(worker) > -1);

          if (firstWorkerID && firstWorkerID[0]) {
            this.$store.commit('setWorker', firstWorkerID[0]);
          }
        }
      }
    },
    categoryNameById (categoryID) {
      if (categoryID === -1) {
        return this.getTranslation('select_service_category');
      }

      if (categoryID === 0) {
        return this.getTranslation('services_without_category');
      }

      return this.servicesCategories.filter(category => category.id === categoryID)[0].name;
    },
    serviceNameById (serviceID) {
      if (serviceID === 0) {
        return this.getTranslation('select_service');
      }

      let service = this.getServices.filter(service => service.id === serviceID)[0];

      if (this.showServicePrice(service)) {
        return service.name + ' (' + this.getServicePrice(service.additional_price) + ')';
      }

      return service.name;
    },
    showServicePrice (service) {
      return service.additional_price !== '0.00' && !!service.additional_price && !service.periodicity && !service.custom_duration && !!service.payment && this.showPaymentsInForm;
    },
    durationNameById (durationID) {
      if (durationID === 0) {
        return '';
      }

      let duration = this.customDurations.filter(duration => duration.id === durationID);

      if (!duration.length) {
        return '';
      }

      if (!this.serviceIsCyclic() && duration[0].price !== '0.00' && !!duration[0].price && this.showPaymentsInForm) {
        return duration[0].name + ' (' + this.getServicePrice(duration[0].price) + ')';
      }

      return duration[0].name;
    },
    changedService () {
      this.$bus.$emit('bookero-plugin-duration-loading-issue-state', false);
      this.hasDataLoadingIssue = false;

      this.selectedCustomDuration = 0;
      this.$store.commit('setService', this.selectedService);
      this.$store.commit('removeErrors');
      this.$store.commit('setCustomDuration', 0);
      this.$bus.$emit('bookero-plugin-reset-termin');
      this.$bus.$emit('bookero-plugin-reset-params');

      if (!this.getCustomConfigField('use_worker_id') && !this.getCustomConfigField('use_worker_ids')) {
        Vue.nextTick(() => {
          this.$bus.$emit('bookero-plugin-reset-worker');
        });
      }

      if (this.getCustomConfigField('use_worker_ids')) {
        let service = this.getServices.filter(service => service.id === this.selectedService);
        let workerIDs = this.getCustomConfigField('use_worker_ids');

        if (service && service[0]) {
          let firstWorkerID = service[0].workers.filter(worker => workerIDs.indexOf(worker) > -1);

          if (firstWorkerID && firstWorkerID[0]) {
            this.$store.commit('setWorker', firstWorkerID[0]);
          }
        }
      }

      this.$bus.$emit('bookero-plugin-reset-people-number');
      this.$bus.$emit('bookero-plugin-reset-days-number');
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:service-updated', { serviceID: this.selectedService });

      setTimeout(() => {
        if (this.getSelectedService !== 0) {
          this.getCustomDurations();
        } else {
          this.customDurations = [];
        }
      }, 0);
    },
    changedCategory (setService = true) {
      this.$bus.$emit('bookero-plugin-duration-loading-issue-state', false);
      this.hasDataLoadingIssue = false;

      if (this.selectedCategory === '' || this.selectedCategory === null) {
        this.selectedCategory = 0;
      }

      this.$store.commit('setServiceCategory', this.selectedCategory);

      if (!this.getCustomConfigField('use_worker_id')) {
        Vue.nextTick(() => {
          this.$bus.$emit('bookero-plugin-reset-worker');
        });
      }

      this.$bus.$emit('bookero-plugin-reset-people-number');
      this.$bus.$emit('bookero-plugin-reset-days-number');
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:service-category-updated', { categoryID: this.selectedCategory });

      if (setService !== false || this.selectedCategory === -1) {
        if (this.selectedCategory !== -1 && (this.getConfig.select_first_service || this.servicesInCategory(this.selectedCategory).length < 2)) {
          if (this.servicesInCategory(this.selectedCategory)[0]) {
            this.selectedService = this.servicesInCategory(this.selectedCategory)[0].id;
          }
        } else {
          this.selectedService = 0;
        }

        this.changedService();
      } else {
        // service change will trigger params reset,
        // so there is no need to do it again during category change if service is set
        this.$bus.$emit('bookero-plugin-reset-params');
      }
    },
    servicesInCategory (categoryID) {
      return this.getServices.filter(service => !this.filteredServices || (this.filteredServices.indexOf(service.id) > -1)).filter(service => service.category_id === categoryID);
    },
    selectedServiceRequiresPeopleNumber () {
      let result = true;
      let currentService = this.getServices.filter(service => service.id === parseInt(this.selectedService, 10));

      if (currentService.length) {
        return !!currentService[0].people_required;
      }

      return result;
    },
    getServicePrice (price) {
      if (price > 0) {
        return CurrencyUtils.getQuote(price, this.getCurrency);
      }

      return CurrencyUtils.getQuote(price, this.getCurrency);
    },
    changedCustomDuration () {
      this.$store.commit('setCustomDuration', this.selectedCustomDuration);
    },
    getCustomDurations (skipDurationReload = false, loadDueError = false) {
      if (this.getSelectedService === 0) {
        return;
      }

      let pluginConfig = this.$store.state.pluginConfig;
      let service = this.getSelectedService;
      this.durationsAreLoading = true;

      this.$http.get(this.$endpointURL + '/getCustomDuration', {
        params: {
          bookero_id: pluginConfig.id,
          lang: pluginConfig.lang,
          service: service
        }
      }).then(res => {
        if (res.data && res.data.data && service === this.getSelectedService) {
          this.customDurations = JSON.parse(JSON.stringify(res.data.data));

          if (!this.getConfig.cart_enabled) {
            this.$store.commit('setCustomDurationsData', {
              serviceID: this.getSelectedService,
              customDurationsData: JSON.parse(JSON.stringify(res.data.data))
            });
          }

          if (this.customDurations.length && this.selectedCustomDuration === 0 && !skipDurationReload) {
            this.selectedCustomDuration = this.customDurations[0].id;

            if (this.getConfig.cart_enabled) {
              this.$store.commit('setCustomDurationsData', {
                serviceID: this.getSelectedService,
                customDurationsData: JSON.parse(JSON.stringify(res.data.data))
              });
            }

            this.changedCustomDuration();
          }
        }

        this.$bus.$emit('bookero-plugin-duration-loading-issue-state', false);
        this.hasDataLoadingIssue = false;
        this.durationsAreLoading = false;

        if (loadDueError) {
          this.$bus.$emit('bookero-plugin-reload-date');
        }
      }).catch(() => {
        setTimeout(() => {
          this.$bus.$emit('bookero-plugin-duration-loading-issue-state', true);
          this.hasDataLoadingIssue = true;
          this.durationsAreLoading = false;
        }, 100);
      });
    },
    serviceIdExists (idToCheck) {
      if (parseInt(idToCheck, 10) === 0) {
        return true;
      }

      if (this.getServices.filter(service => !this.filteredServices || (this.filteredServices.indexOf(service.id) > -1)).filter(service => service.id === parseInt(idToCheck, 10)).length) {
        return true;
      }

      return false;
    },
    serviceCategoryIdExists (idToCheck) {
      if (parseInt(idToCheck, 10) === 0) {
        return true;
      }

      if (this.servicesCategories.filter(category => category.id === parseInt(idToCheck, 10)).length) {
        return true;
      }

      return false;
    }
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.services-section {
  padding: 10px 0;
  width: 100%;

  &.has-data-loading-issue /deep/ .custom-duration-field label {
    color: $color-danger;
  }

  &.has-data-loading-issue /deep/ .custom-duration-field select,
  &.has-data-loading-issue /deep/ .custom-duration-field .multiselect {
    border: 2px solid $color-danger;
    border-radius: 5px;
  }

  /deep/ .custom-duration-field .loading-error-info {
    color: $color-danger;
    position: relative;
  }

  /deep/ .custom-duration-field.is-loading .loading-error-info > a {
    display: none;
  }

  /deep/ .custom-duration-field.is-loading .loading-error-info:after {
    animation: spinner .5s linear infinite;
    border: 2px solid $color-danger;
    border-radius: 50%;
    border-top-color: transparent;
    content: "";
    height: 12px;
    position: absolute;
    right: -18px;
    top: 1px;
    width: 12px;
  }

  .field.is-inactive {
    opacity: .25;
    pointer-events: none;
  }

  .bookero-plugin-service-value,
  .bookero-plugin-service-category-value {
    color: $color-text;
    font-size: 14px;
  }

  .custom-duration-field.is-loading {
    opacity: .5;
    pointer-events: none;
  }
  /deep/ .multiselect.bookero-plugin-services-list-styled-all.has-only-one-group {
    .multiselect__content .multiselect__element:nth-child(3) > .multiselect__option.multiselect__option--group.multiselect__option--disabled {
      display: none!important;
    }
  }

  /deep/ .multiselect.bookero-plugin-services-list-styled-all.has-first-group-to-hide {
    .multiselect__content .multiselect__element:first-child > .multiselect__option.multiselect__option--group.multiselect__option--disabled {
      display: none!important;
    }
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
