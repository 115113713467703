export default {
  setDate ({ commit }, date) {
    commit('setDate', date);

    if (date !== '') {
      commit('removeError', 'date');
    }
  },
  setTime ({ commit }, payload) {
    commit('setTime', payload);

    if (payload.time !== '') {
      commit('removeError', 'hour');
    }
  },
  setDateTime ({ commit }, dateTime) {
    commit('setDate', dateTime.date);
    commit('setTime', dateTime.time);

    if (dateTime.date !== '') {
      commit('removeError', 'date');
    }

    if (dateTime.time.time !== '') {
      commit('removeError', 'hour');
    }
  }
};
