import CurrencyConfig from './../config/currencies.js';

export default class CurrencyUtils {
  static getQuote (value, currency) {
    if (value === 0) {
      value = '0,00';
    }

    let config = CurrencyConfig[currency];

    if (config) {
      let output = config.format.replace('{value}', value).replace('{symbol}', config.currencySymbol);
      return output.replace('.', ',');
    }

    return (value).replace('.', ',');
  }
}
