export default {
  pluginConfig: {
    id: window.bookero_config ? window.bookero_config.id : '',
    lang: window.bookero_config ? window.bookero_config.lang : '',
    type: window.bookero_config ? window.bookero_config.type : ''
  },
  inquiries: [],
  initialized: false,
  config: false,
  custom_config: {},
  services: false,
  workers: false,
  customDurations: {},
  specialParams: false,
  params: false,
  translations: false,
  waitingListInInquiries: false,
  currentlyBlockedTermins: [],
  formState: {
    agreeTp: false,
    agreeNewsletter: false,
    agreeTelemarketing: false,
    currency: '',
    temporaryCurrency: '',
    selectedService: 0,
    selectedServiceCategory: 0,
    selectedWorker: 0,
    preSelectedWorker: 0,
    peopleNumber: 1,
    childrenNumber: 0,
    daysNumber: 1,
    specialParamsValues: {},
    selectedPeriodicity: 0,
    selectedCustomDuration: 0,
    paramsValues: {},
    price: 0,
    priceBeforeDiscount: 0,
    priceAfterDiscount: 0,
    temporaryPrice: 0,
    selectedPaymentMethod: 0,
    discountCode: '',
    discountCodeVisibility: false,
    discountCodeStatus: '',
    discountValue: 0,
    prices: false,
    phone: '',
    email: '',
    name: '',
    date: '',
    time: '',
    user_want_invoice: false,
    invoice_is_company: true,
    invoice_company_title: '',
    invoice_address: '',
    invoice_post_code: '',
    invoice_city: '',
    invoice_country: '',
    invoice_nip: '',
    waitingList: false,
    cartID: 0
  },
  calendarData: false,
  calendarState: {
    currentMonth: 0,
    currentMonthOnDateSelect: 0
  },
  weekData: false,
  weekState: {
    currentWeek: 0,
    currentWeekOnDateSelect: 0
  },
  commonPaymentMethods: [],
  errors: []
};
