<template>
  <div class="inquiry">
    <div class="inquiry-heading">
      <h3 class="inquiry-title">
        <template v-if="cartEnabled && !isWaitingListItem">
          {{ getTranslation('cart_order') }} {{ listIndex + 1 }}
        </template>
        <template v-if="isWaitingListItem">
          {{ getTranslation('reserve_list_header') }}
        </template>
        <template v-if="!cartEnabled && !isWaitingListItem">
          {{ getTranslation('cart_your_order') }}
        </template>
      </h3>
      <div
        v-if="showPaymentsInSummary && hasPaymentMethods && getInquiryPrice(this.inquiryData.plugin_inquiry_id) && inquiryPrice.replace(/[^0-9,.]/gmi, '') !== '0,00'"
        class="inquiry-price">
        {{ inquiryPrice }}
      </div>
    </div>
    <div class="inquiry-datetime">
      <span class="inquiry-label">
        <template v-if="inquiryData.connectedTermins && inquiryData.connectedTermins.length">
          {{ getTranslation('calendar_terms') }}:
        </template>
        <template v-else>
          {{ getTranslation('term') }}:
        </template>
      </span>
      {{ inquiryData.date }}
      <template v-if="inquiryUseDailyService && inquiryData.days">
        &mdash; {{ calculateEndDate(inquiryData) }}
      </template>
      <template v-if="!inquiryUseDailyService && getHoursVisibility">
        {{ getTranslation('cart_inquiry_hour') }} {{ inquiryData.hour }}
      </template>
      <span
        v-if="inquiryData.connectedTermins && inquiryData.connectedTermins.length"
        class="inquiry-connected-termins">
        {{ getConnectedTerminsLabel(inquiryData.connectedTermins) }}
        <span class="inquiry-connected-termins-popup">
          <span
            v-for="(termin, index) of inquiryData.connectedTermins"
            :key="'connected-termin-' + index"
            class="inquiry-connected-termins-popup-item">
            <template v-if="getHoursVisibility">
              {{ termin.start_at.replace(' ', ' ' + getTranslation('cart_inquiry_hour') + ' ') }}
            </template>
            <template v-else>
              {{ termin.start_at.split(' ')[0] }}
            </template>
          </span>
        </span>
      </span>
    </div>
    <div
      v-if="inquiryData.service"
      class="inquiry-service">
      <span class="inquiry-label">
        <template v-if="getServiceCategoryNameByServiceID(inquiryData.service, servicesBackup)">
          {{ getServiceCategoryNameByServiceID(inquiryData.service, servicesBackup) }}:
        </template>
        <template v-else>
          {{ getTranslation('cart_inquiry_service') }}:
        </template>
      </span>

      {{ getServiceName(inquiryData.service) }}
    </div>
    <div
      v-if="hasWorkers && (inquiryData.worker || inquiryData.worker === 0) && !getCustomConfigField('hide_worker_info')"
      class="inquiry-worker">
      <span class="inquiry-label">{{ getTranslation('cart_inquiry_worker') }}:</span>
      {{ getWorkerName(inquiryData.worker, workersBackup) }}
    </div>
    <div class="inquiry-actions">
      <a
        href="#"
        class="inquiry-actions-details"
        @click.prevent="showDetails(listIndex + 1)">
        {{ getTranslation('show_the_details') }}
      </a>

      <span
        v-if="inquiryStatus === 3"
        class="inquiry-status is-accepted">
        <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="clock" stroke="none" stroke-width="1" fill="#FFFFFF" fill-rule="evenodd">
            <path d="M21.284,11.513 L14.5,20.2780214 L9.386,14.377 C9.02694504,14.0754127 8.53267285,13.9920384 8.09455599,14.1591581 C7.65643912,14.3262777 7.34326047,14.717654 7.27627671,15.1817537 C7.20929294,15.6458534 7.39902028,16.1098144 7.772,16.394 L13.6928013,23.5532924 C14.2066827,23.9642178 14.8949923,24.0183116 15.360493,23.5532924 L23.113,13.336 C23.5861968,12.8261169 23.5707537,12.0331143 23.0780642,11.5420409 C22.5853746,11.0509676 21.7923255,11.0381304 21.284,11.513 Z M15.5,0 C9.23083521,0 3.57897275,3.77645376 1.17986725,9.5684068 C-1.21923826,15.3603598 0.106875953,22.0271862 4.53984489,26.4601551 C8.97281383,30.893124 15.6396402,32.2192383 21.4315932,29.8201328 C27.2235462,27.4210273 31,21.7691648 31,15.5 C30.9906308,6.94347022 24.0565298,0.00936917568 15.5,0 L15.5,0 Z M15.5,28.417 C8.36613789,28.417 2.583,22.6338621 2.583,15.5 C2.583,8.36613789 8.36613789,2.583 15.5,2.583 C22.6338621,2.583 28.417,8.36613789 28.417,15.5 C28.4087341,22.6304355 22.6304355,28.4087341 15.5,28.417 L15.5,28.417 Z"></path>
          </g>
        </svg>
        {{ getTranslation('reservation_accepted') }}
      </span>

      <span
        v-if="inquiryStatus === 4"
        class="inquiry-status is-rejected">
        <svg xmlns="http://www.w3.org/2000/svg" width="23.719" height="23.719" viewBox="0 0 23.719 23.719">
          <path fill="#ffffff" fill-rule="evenodd" d="M448.257,100.534a11.861,11.861,0,1,0,0,16.773A11.874,11.874,0,0,0,448.257,100.534Zm-1.4,15.376a9.884,9.884,0,1,1,0-13.978A9.9,9.9,0,0,1,446.859,115.91Zm-5.591-6.989,2.8-2.8a0.988,0.988,0,1,0-1.4-1.4l-2.8,2.8-2.8-2.8a0.988,0.988,0,1,0-1.4,1.4l2.795,2.8-2.795,2.795a0.989,0.989,0,1,0,1.4,1.4l2.8-2.8,2.8,2.8a0.988,0.988,0,1,0,1.4-1.4Z" transform="translate(-428 -97.063)"/>
        </svg>
        {{ getTranslation('reservation_rejected') }}
      </span>

      <span
        v-if="inquiryStatus === 6"
        class="inquiry-status is-waiting-list">
        <svg width="31px" height="31px" viewBox="0 0 31 31" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
          <g id="clock" stroke="none" stroke-width="1" fill="#FFFFFF" fill-rule="evenodd">
            <path d="M21.284,11.513 L14.5,20.2780214 L9.386,14.377 C9.02694504,14.0754127 8.53267285,13.9920384 8.09455599,14.1591581 C7.65643912,14.3262777 7.34326047,14.717654 7.27627671,15.1817537 C7.20929294,15.6458534 7.39902028,16.1098144 7.772,16.394 L13.6928013,23.5532924 C14.2066827,23.9642178 14.8949923,24.0183116 15.360493,23.5532924 L23.113,13.336 C23.5861968,12.8261169 23.5707537,12.0331143 23.0780642,11.5420409 C22.5853746,11.0509676 21.7923255,11.0381304 21.284,11.513 Z M15.5,0 C9.23083521,0 3.57897275,3.77645376 1.17986725,9.5684068 C-1.21923826,15.3603598 0.106875953,22.0271862 4.53984489,26.4601551 C8.97281383,30.893124 15.6396402,32.2192383 21.4315932,29.8201328 C27.2235462,27.4210273 31,21.7691648 31,15.5 C30.9906308,6.94347022 24.0565298,0.00936917568 15.5,0 L15.5,0 Z M15.5,28.417 C8.36613789,28.417 2.583,22.6338621 2.583,15.5 C2.583,8.36613789 8.36613789,2.583 15.5,2.583 C22.6338621,2.583 28.417,8.36613789 28.417,15.5 C28.4087341,22.6304355 22.6304355,28.4087341 15.5,28.417 L15.5,28.417 Z"></path>
          </g>
        </svg>
        {{ getTranslation('reservation_waiting_list') }}
      </span>
    </div>
  </div>
</template>

<script>
import CurrencyUtils from './../helpers/CurrencyUtils';
import Translations from './../mixins/Translations.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'inquiry-status',
  props: [
    'cartEnabled',
    'inquiryStatus',
    'inquiryData',
    'listIndex',
    'servicesBackup',
    'workersBackup',
    'getHoursVisibility'
  ],
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'getInquiries',
      'getServices',
      'getServiceCategoryNameByServiceID',
      'getServiceCustomLabel',
      'getServiceName',
      'getServiceNameWithCategory',
      'getWorkerName',
      'getInquiryPrice',
      'getCurrency',
      'hasPaymentMethods',
      'hasWorkers',
      'showPaymentsInSummary',
      'getCustomConfigField'
    ]),
    inquiryPrice () {
      return CurrencyUtils.getQuote(this.getInquiryPrice(this.inquiryData.plugin_inquiry_id), this.getCurrency);
    },
    isWaitingListItem () {
      return this.inquiryData.plugin_inquiry_id === 0;
    },
    inquiryUseDailyService () {
      let serviceID = this.inquiryData.service;
      let services = this.getServices;
      let usedService = services.filter(service => service.id === serviceID);

      if (usedService[0]) {
        return usedService[0].available_in_dates === 3;
      }

      return false;
    }
  },
  data () {
    return {
      isRemoving: false
    };
  },
  methods: {
    showDetails (listIndex) {
      this.$bus.$emit('bookero-plugin-show-details-popup', this.inquiryData, listIndex);
    },
    getConnectedTerminsLabel (connectedTermins) {
      let count = connectedTermins.length;

      if (count === 1) {
        return this.getTranslation('periodicity_and_one_more');
      }

      if (
        count % 10 >= 2 &&
        count % 10 <= 4 &&
        (
          count % 100 <= 10 ||
          count % 100 >= 20
        )
      ) {
        return this.getTranslation('periodicity_and_s_more').replace('%s', count);
      }

      return this.getTranslation('periodicity_and_n_more').replace('%s', count);
    },
    calculateEndDate (inquiryData) {
      let serviceEndsInTheNextDay = false;
      let serviceData = this.getServices.filter(service => service.id === inquiryData.service);
      let daysOffset = inquiryData.days;
      let startDateParts = inquiryData.date.split('-').map(n => parseInt(n, 10));

      if (serviceData && serviceData[0] && serviceData[0].daily_end_available === 1) {
        serviceEndsInTheNextDay = true;
      }

      if (!serviceEndsInTheNextDay) {
        daysOffset--;
      }

      let endDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2], 12, 0, 0, 0);
      endDate.setDate(endDate.getDate() + daysOffset);

      return [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()].map(n => n < 10 ? '0' + n : n).join('-');
    }
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.inquiry {
  background: $color-light;
  border: 1px solid $color-border;
  border-radius: $border-radius-small;
  color: $color-text;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 10px 15px;
  text-align: left;

  &:first-child {
    margin: 0;
  }

  & > div {
    padding: 2px 0;
  }

  &-label {
    font-weight: 600;
  }

  &-datetime,
  &-service,
  &-worker {
    margin: 0;
    width: 100%;
  }

  &-heading {
    align-items: center;
    display: flex;
    margin: 0 0 5px 0;
    width: 100%;
  }

  &-title {
    font-size: 18px;
    font-weight: 400;
    margin: 0;
    width: 75%;
  }

  &-price {
    font-size: 18px;
    text-align: right;
    width: 25%;
  }

  &-actions {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    margin: 0;
    width: 100%;

    &-details {
      align-items: center;
      display: flex;
      letter-spacing: 0;
      padding-right: 10px;
      position: relative;
      text-transform: none;

      &:after {
        border-top: 2px solid $color-primary;
        border-right: 2px solid $color-primary;
        content: "";
        display: block;
        height: 5px;
        position: absolute;
        right: 0;
        top: 50%;
        transition: all .3s ease-out;
        transform: rotate(45deg) translateY(-60%);
        width: 5px;
      }

      &:hover {
        &:after {
          border-top-color: darken($color-primary, 15%);
          border-right-color: darken($color-primary, 15%);
          transform:  translateX(3px) rotate(45deg) translateY(-60%);
        }
      }
    }
  }

  &-connected-termins {
    cursor: pointer;
    color: $color-primary;
    display: inline-block;
    font-weight: 500;
    position: relative;

    &-popup {
      background: $color-light;
      border-radius: $border-radius-small;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
      display: block;
      font-weight: 300;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      padding: 3px 0;
      position: absolute;
      text-decoration: none;
      top: 20px;
      transform: translateX(-50%) translateY(10px);
      transition: all .25s ease-out;
      width: 260px;
      z-index: 1;

      &-item {
        border-top: 1px solid $color-border;
        color: $color-text;
        display: block;
        padding: 6px 0;
        text-align: center;
        text-decoration: none;

        &:first-child {
          border-top: none;
        }
      }
    }

    &:hover {
      .inquiry-connected-termins-popup {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(-50%) translateY(0);
      }
    }
  }

  &-status {
    align-items: center;
    border-radius: 20px;
    color: $color-light;
    display: flex;
    font-size: 13px;
    font-weight: bold;
    justify-content: center;
    margin: 0 0 0 auto;
    padding: 5px 0 5px 20px;
    position: relative;
    width: 160px;

    & > svg {
      left: 16px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 16px;
    }

    &.is-accepted {
      background: $color-success;
    }

    &.is-waiting-list {
      background: $color-primary;
    }

    &.is-rejected {
      background: $color-danger;
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 540px) {
  .inquiry {
    padding: 10px 15px;

    &-datetime,
    &-price,
    &-service,
    &-worker {
      width: 100%;
    }

    &-title,
    &-price {
      font-size: 16px;
      font-weight: bold;
      width: auto;
    }

    &-price {
      margin: 0 0 0 auto;
    }
  }
}
</style>
