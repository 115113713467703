<script>
export default {
  name: 'CommonMethods',
  methods: {
    getServiceNames () {
      if (this.getSelectedService === 0) {
        return [];
      }

      let selectedService = this.getServices.filter(service => service.id === this.getSelectedService)[0];
      let selectedServiceCategoryName = '';

      if (selectedService.category_id) {
        selectedServiceCategoryName = selectedService.category.name;
      }

      return [
        {
          'category': selectedServiceCategoryName,
          'value': selectedService.name,
          'require_people': selectedService.people_required
        }
      ];
    },
    getServiceValues () {
      if (this.getSelectedService === 0) {
        return [];
      }

      let selectedService = this.getServices.filter(service => service.id === this.getSelectedService)[0];

      return [
        {
          'category': selectedService.category_id,
          'value': selectedService.id,
          'require_people': selectedService.people_required
        }
      ];
    },
    fillInquiriesWithCustomerData (inquiries) {
      for (let i = 0; i < inquiries.length; i++) {
        inquiries[i].phone = this.getFormState.phone;
        inquiries[i].email = this.getFormState.email;
        let pluginComment = JSON.parse(inquiries[i].plugin_comment);
        pluginComment.data.name = this.getFormState.name;
        pluginComment.data.phone = this.getFormState.phone;
        inquiries[i].plugin_comment = JSON.stringify(pluginComment);
      }

      return inquiries;
    },
    serviceIsCyclic () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === this.getSelectedService)[0];

        if (!service) {
          return false;
        }

        return !!service.periodicity;
      }

      return false;
    }
  }
}
</script>
