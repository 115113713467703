<script>
export default {
  name: 'API',
  mounted () {
    this.initAPI();
  },
  methods: {
    initAPI () {
      if (!window.bookero_plugin_form) {
        window.bookero_plugin_form = {};
        window.bookero_plugin_form.getAvailableInstances = () => {
          return Object.keys(window.bookero_plugin_form).filter(instance => instance !== 'getAvailableInstances');
        };
      }

      window.bookero_plugin_form[this.$root.instanceID] = {
        getServices: (serviceCategoryID = false) => {
          if (!this.$refs['formServices']) {
            console.warn('[BOOKERO PLUGIN JS API] There are no services control in the given instance:', this.$root.instanceID);
            return;
          }

          if (serviceCategoryID !== false) {
            return this.$refs['formServices'].getServices.filter(service => service.category_id === parseInt(serviceCategoryID, 10)).map(service => ({
              id: service.id,
              category: service.category,
              category_id: service.category_id,
              name: service.name,
              ordering: service.ordering,
              worker_ids: service.workers.slice()
            }));
          }

          return this.$refs['formServices'].getServices.map(service => ({
            id: service.id,
            category: service.category,
            category_id: service.category_id,
            name: service.name,
            ordering: service.ordering,
            worker_ids: service.workers.slice()
          }));
        },
        getServiceCategories: () => {
          if (!this.$refs['formServices']) {
            console.warn('[BOOKERO PLUGIN JS API] There are no services control in the given instance:', this.$root.instanceID);
            return;
          }

          let foundedCategories = this.$refs['formServices'].getServices.map(service => ({
            id: service.category_id,
            name: service.category ? service.category.name : null,
            ordering: service.category ? service.category.ordering : null
          }));

          let categories = [];
          let foundedCategoryIDs = [];

          for (let i = 0; i < foundedCategories.length; i++) {
            if (foundedCategoryIDs.indexOf(foundedCategories[i].id) === -1) {
              categories.push(foundedCategories[i]);
              foundedCategoryIDs.push(foundedCategories[i].id);
            }
          }

          return categories;
        },
        setServiceID: (serviceID) => {
          if (!this.$refs['formServices']) {
            console.warn('[BOOKERO PLUGIN JS API] There are no services control in the given instance:', this.$root.instanceID);
            return false;
          }

          if (!this.$refs['formServices'].getServices.filter(service => service.id === parseInt(serviceID, 10)).length) {
            console.warn('[BOOKERO PLUGIN JS API] There are no service with given ID:', serviceID, ' for the given instance:', this.$root.instanceID);
            return false;
          }

          this.$refs['formServices'].selectedService = serviceID;

          if (!this.getConfig.service_two_step_choice) {
            this.$refs['formServices'].changedService();
          } else {
            let service = this.$refs['formServices'].getServices.filter(service => service.id === serviceID)[0];
            this.$refs['formServices'].selectedCategory = service.category_id;
            this.$refs['formServices'].changedCategory(false);
            this.$refs['formServices'].changedService();
          }

          return true;
        },
        setServiceCategoryID: (serviceCategoryID) => {
          if (!this.$refs['formServices']) {
            console.warn('[BOOKERO PLUGIN JS API] There are no services control in the given instance:', this.$root.instanceID);
            return false;
          }

          if (!this.$refs['formServices'].getServices.filter(service => service.category_id === parseInt(serviceCategoryID, 10)).length) {
            console.warn('[BOOKERO PLUGIN JS API] There are no services category with given ID:', serviceCategoryID, ' for the given instance:', this.$root.instanceID);
            return false;
          }

          if (!this.getConfig.service_two_step_choice) {
            console.warn('[BOOKERO PLUGIN JS API]Two step choice of the services is DISABLED for the given instance:', this.$root.instanceID);
            return false;
          }

          this.$refs['formServices'].selectedCategory = serviceCategoryID;
          this.$refs['formServices'].changedCategory();

          return true;
        }
      };
    }
  }
}
</script>
