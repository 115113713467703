<template>
  <div class="switcher-wrapper">
    <div
      :class="cssClasses"
      @click="toggle"></div>
    <component
      :is="(state || isSpecial) ? 'strong' : 'span'"
      v-pure-html="label + requiredSpan"
      class="switcher-label"
      @click="toggle" />
  </div>
</template>

<script>
export default {
  name: 'switcher',
  props: {
    value: {
      type: [Number, Boolean],
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    isSpecial: {
      type: Boolean,
      default: false
    },
    isInvalid: {
      type: Boolean,
      default: false
    },
    isAgreement: {
      type: Boolean,
      default: false
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      state: false
    }
  },
  watch: {
    value (newValue) {
      this.state = !!newValue;
    }
  },
  computed: {
    cssClasses () {
      return {
        'switcher': true,
        'is-active': this.state,
        'is-disabled': this.disabled,
        'is-invalid': this.isInvalid,
        'is-agreement': this.isAgreement
      };
    },
    requiredSpan () {
      if (this.isRequired) {
        return ' <span class="is-required">*</span>';
      }

      return '';
    }
  },
  mounted () {
    this.state = !!this.value;
    this.$emit('input', this.state);
  },
  methods: {
    toggle (event) {
      if (event.target.tagName === 'A') {
        return;
      }

      this.state = !this.state;
      this.$emit('input', this.state);
    },
    getValue () {
      return this.state;
    },
    setValue (newValue) {
      this.state = newValue;
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.switcher {
  background: $color-light;
  border: 1px solid $color-border;
  border-radius: $border-radius-small;
  cursor: pointer;
  height: 22px;
  margin: 3px 0;
  position: relative;
  transition: all .25s ease-out;
  width: 22px;

  &-wrapper {
    align-items: flex-start;
    display: flex;
    width: 100%;
  }

  &-label {
    color: $color-text;
    cursor: pointer;
    font-size: 15px;
    line-height: 1.4;
    margin: 4px 0 4px auto;
    width: calc(100% - 40px);

    /deep/ .is-required {
      opacity: .66;
    }
  }

  &:after,
  &:before {
    background: $color-light;
    content: "";
    display: block;
    position: absolute;
  }

  &:before {
    height: 3px;
    left: 5px;
    top: 9px;
    transform: rotate(45deg);
    transition: width .15s ease-out;
    transform-origin: left top;
    width: 0;
  }

  &:after {
    height: 3px;
    left: 8px;
    top: 13px;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    transition: width .15s ease-out;
    width: 0;
  }

  &.is-active {
    background: $color-primary;
    border-color: $color-primary;

    &:before {
      width: 7px;
    }

    &:after {
      transition-delay: .125s;
      width: 12px;
    }
  }

  &.is-invalid {
    border-color: $color-danger;
  }

  &.is-agreement {
    & + .switcher-label {
      margin: 2px 0 4px 18px;
    }
  }

  &.is-disabled {
    opacity: .4;
    pointer-events: none;
  }

  &.is-invalid + .switcher-label {
    color: $color-danger;
  }
}
</style>
