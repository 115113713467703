<template>
  <div
    id="bookero-plugin"
    :data-mode="getMode"
    :data-position="getPosition"
    :class="{ 'is-open': isOpen }">
    <template v-if="getMode === 'sticky'">
      <div :class="{ 'bookero-sticky-plugin-content': true, 'is-open': isOpen }">
        <div class="bookero-sticky-plugin-content-inner">
          <bookero-form />
        </div>

        <button
          @click="toggleStickyForm"
          class="bookero-sticky-plugin-toggle">
          <template>{{ getTranslation('close') }}</template>
        </button>
      </div>
      <button
        @click="toggleStickyForm"
        class="bookero-sticky-plugin-toggle">
        {{ getTranslation('reservation') }}
      </button>
    </template>
    <template v-else>
      <bookero-form />
    </template>
  </div>
</template>

<script>
import Form from './components/BookeroForm';
import Translations from './components/mixins/Translations.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'bookero-plugin',
  mixins: [
    Translations
  ],
  components: {
    'bookero-form': Form
  },
  computed: {
    ...mapGetters(['getMode']),
    getPosition () {
      if (window.bookero_config.position !== '' && ['bottom-left', 'bottom-right'].indexOf(window.bookero_config.position) > -1) {
        return window.bookero_config.position;
      }

      return 'bottom-left';
    }
  },
  data () {
    return {
      isOpen: false
    };
  },
  methods: {
    toggleStickyForm () {
      this.isOpen = !this.isOpen;

      setTimeout(() => {
        if (this.isOpen) {
          document.querySelector('.bookero-sticky-plugin-content').scrollTop = 0;
        }
      }, 0);
    }
  }
}
</script>

<style lang="scss">
@import './assets/scss/variables.scss';

#bookero-plugin {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  * {
    box-sizing: border-box;
    outline: none;

    ::-webkit-input-placeholder {
      color: $color-text-light;
    }
    ::-moz-placeholder {
      color: $color-text-light;
    }
    :-ms-input-placeholder {
      color: $color-text-light;
    }
    :-moz-placeholder {
      color: $color-text-light;
    }
  }
}

/* Sticky mode */
#bookero-plugin[data-mode="sticky"] {
  bottom: 0;
  height: 100%;
  min-width: 200px;
  pointer-events: none;
  position: fixed;
  z-index: 1000000;

  .bookero-plugin-form {
    height: 100%;
    margin: 0;
  }

  .bookero-sticky-plugin-content {
    background: $color-light;
    display: block;
    height: 100%;
    opacity: 0;
    padding: 0;
    position: relative;
    transform: translateX(560px);
    width: 0;
    z-index: 1;

    .bookero-sticky-plugin-toggle {
      bottom: 0;
      left: 0!important;
      position: absolute;
      right: 0!important;
      width: 100%!important;
      z-index: 1;
    }
  }

  .bookero-sticky-plugin-content-inner {
    background: $color-light;
    height: 100%;
    overflow: auto;
    padding: 0;
    width: 560px;

    .bookero-plugin-form-wrapper {
      padding: 20px 20px 70px 20px;
    }
  }

  &.is-open {
    pointer-events: auto;

    .bookero-sticky-plugin-content {
      box-shadow: 0 0 10px rgba(0, 0, 0, .125);
      display: block;
      height: 100%;
      opacity: 1;
      transform: translateX(0);
      width: 100%;
    }
  }

  .bookero-sticky-plugin-toggle {
    -webkit-appearance: none;
    background: $color-primary;
    border: none;
    bottom: 0;
    color: $color-light;
    cursor: pointer;
    font-size: 16px;
    font-weight: bold;
    height: 48px;
    letter-spacing: 0;
    pointer-events: auto;
    position: absolute;
    right: 0;
    text-transform: none;
    width: 200px;
  }

  &[data-position="bottom-right"] {
    right: 0;
  }

  &[data-position="bottom-left"] {
    left: 0;

    .bookero-sticky-plugin-content {
      transform: translateX(-560px);
    }

    .bookero-sticky-plugin-toggle {
      left: 0;
      right: auto;
    }

    &.is-open {
      .bookero-sticky-plugin-content {
        transform: translateX(0);
      }
    }
  }
}

#bookero-plugin[data-mode="sticky"][data-is-initialized="true"] {
  .bookero-sticky-plugin-content {
    transition: all .5s ease-out;
  }
}

@media (max-width: 720px) {
  #bookero-plugin[data-mode="sticky"] {
    max-width: 100%;

    .bookero-sticky-plugin-content {
      max-width: 100%;
    }

    .bookero-sticky-plugin-content-inner {
      max-width: 100%;
      padding: 10px;
      width: 100%;

      .bookero-plugin-form {
        padding: 0;
      }
    }
  }
}
</style>
