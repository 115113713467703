<template>
  <div
    :class="{ 'bookero-plugin-form-wrapper': true }"
    :data-instance-id="$root.instanceID"
    data-loaded-from-url=""
    data-initialized-custom-config="">
    <form :class="{ 'bookero-plugin-form': true, 'is-initialized': isInitialized, 'has-error': loadError }">
      <div
        v-if="!!getConfig.block_widget_text"
        class="bookero-plugin-form-message"
        :data-type="getConfig.block_widget_type"
        v-pure-html="getConfig.block_widget_text">
      </div>

      <div
        v-if="!this.hasValidUrlParams()"
        class="bookero-plugin-form-message"
        data-type="danger"
        v-pure-html="getCustomConfigField('invalid_url_params_error_message')">
      </div>

      <div
        v-if="loadError"
        class="bookero-plugin-error">
        <p class="bookero-plugin-error-msg">
          {{ getTranslation('validation_load_form_error') }}
        </p>

        <button
          @click.prevent="getPluginConfig()"
          class="bookero-plugin-error-btn">
          {{ getTranslation('validation_please_reload') }}
        </button>
      </div>

      <template v-if="isInitialized && !loadError && getConfig && getConfig.block_widget === 0">
        <inquiries-list
          v-if="getInquiries.length || cartStep === 2"
          :is-editable="cartStep === 1" />

        <div class="bookero-plugin-hideable-part bookero-plugin-new-reservation-form" ref="hideable-part">
          <div class="bookero-plugin-hideable-part-wrapper" ref="hideable-part-wrapper">
            <h2
              v-if="isInitialized && !loadError && inquiryFormVisible && cartStep === 1"
              class="bookero-plugin-header">
              <template v-if="!getInquiries.length">
                {{ getTranslation('header_add_reservation') }}
              </template>
              <template v-if="getInquiries.length">
                {{ getTranslation('header_add_reservation_no') }} {{ getInquiries.length + 1 }}
                <span
                  class="bookero-plugin-header-close"
                  @click="hideInquiryForm()"></span>
              </template>
            </h2>

            <div
              v-if="cartStep === 1 && inquiryFormVisible"
              class="bookero-plugin-form-cart-step-1">
              <h3
                v-if="hasServices"
                class="bookero-plugin-form-heading">
                {{ getTranslation('summary_main_info') }}
              </h3>
              <workers
                v-if="!selectedServiceIsDailyService && getCustomConfigField('use_worker_id') && !getCustomConfigField('use_worker_ids') && hasWorkers && hasSelectedService" />
              <services
                v-if="hasServices"
                ref="formServices" />
              <reservation-type />
              <workers
                v-if="!selectedServiceIsDailyService && !getCustomConfigField('use_worker_id') && !getCustomConfigField('use_worker_ids') && hasWorkers && hasSelectedService" />
              <people
                v-if="peopleAreRequired"
                :isActive="canBeDisplayed" />
              <days ref="days" />
              <params
                v-if="hasSpecialParams"
                :isActive="canBeDisplayed"
                paramsType="special" />
              <h3
                :class="{
                  'bookero-plugin-form-heading': true,
                  'is-inactive': !canBeDisplayed
                }">
                {{ getTranslation('term') }}
              </h3>
              <dates
                :isActive="canBeDisplayed"
                ref="dates" />
              <hours v-if="['calendar', 'standard', 'sticky'].indexOf(getMode) > -1 && !selectedServiceIsDailyService" />
              <h3
                v-if="hasParams && hasVisibleParams"
                class="bookero-plugin-form-heading has-separator">
                {{ getTranslation('summary_additional_info') }}
              </h3>
              <params
                v-if="hasParams"
                paramsType="normal"
                :isActive="canBeDisplayed" />
              <required-fields-notice />
              <temporary-price
                v-if="getConfig.show_prices > 1 && hasPaymentMethods && getConfig.show_temporary_price"
                :priceLoadError="temporaryPriceLoadError" />
              <errors v-if="cartStep === 1"/>
              <add-to-cart
                v-if="getConfig.cart_enabled"
                :isActive="canBeDisplayed && !temporaryPriceLoadError && !temporaryPriceIsLoading" />
              <submit
                v-if="!getConfig.cart_enabled"
                step="cart-step-1"
                :isActive="canBeDisplayed && !temporaryPriceLoadError && !temporaryPriceIsLoading" />
            </div>
          </div>
        </div>

        <div
          v-if="cartStep === 2"
          class="bookero-plugin-form-cart-step-2">
          <h2 class="bookero-plugin-header bookero-plugin-header-summary">
            {{ getTranslation('the_summary') }}
          </h2>
          <h3 class="bookero-plugin-form-heading">
            {{ getTranslation('your_data') }}
          </h3>
          <customer />
          <invoice />
          <agreements
            v-if="agreementsAreAvailable" />
          <required-fields-notice />
          <payment-and-discount
            v-if="(hasPaymentMethods || hasDiscountCode)" />
          <payment-summary
            v-if="hasPaymentMethods && showPaymentsInSummary"
            :show-only-price="cartStep === 1"
            :showDiscountCodeCalculations="cartStep === 2"
            :priceLoadError="priceLoadError" />
        </div>

        <errors v-if="cartStep === 2"/>
        <submit
          v-if="!inquiryFormVisible || cartStep === 2"
          :isActive="!priceLoadError && !priceIsLoading"
          :step="'cart-step-' + cartStep" />
      </template>

      <div
        v-if="isInitialized && !getConfig.whitelabeling"
        class="bookero-plugin-footer" v-pure-html="getTranslation('reservations_can_be_made_thanks_to')">
      </div>

      <tooltip />
    </form>

    <result-popup />
    <details-popup />
    <alert-popup />
    <debug
      v-if="getConfig.debug_options"/>
  </div>
</template>

<script>
import AddToCart from './sections/AddToCart.vue';
import AlertPopup from './AlertPopup.vue';
import Agreements from './sections/Agreements.vue';
import CalendarUtils from './helpers/CalendarUtils.js';
import CommonMethods from './mixins/CommonMethods.vue';
import CustomConfigHelpers from './mixins/CustomConfigHelpers.vue';
import Customer from './sections/Customer.vue';
import Dates from './sections/Dates.vue';
import Days from './sections/Days.vue';
import Debounce from './mixins/Debounce.vue';
import DetailsPopup from './DetailsPopup.vue';
import Errors from './sections/Errors.vue';
import Hours from './sections/Hours.vue';
import InquiriesList from './sections/InquiriesList.vue';
import Invoice from './sections/Invoice.vue';
import PaymentAndDiscount from './sections/PaymentAndDiscount.vue';
import PaymentSummary from './sections/PaymentSummary.vue';
import Params from './sections/Params.vue';
import People from './sections/People.vue';
import PluginAPI from './mixins/PluginAPI.vue';
import ReservationType from './sections/ReservationType.vue';
import ResultPopup from './ResultPopup.vue';
import RequiredFieldsNotice from './sections/RequiredFieldsNotice.vue';
import Services from './sections/Services.vue';
import Submit from './sections/Submit.vue';
import TemporaryPrice from './sections/TemporaryPrice.vue';
import Tooltip from './elements/Tooltip.vue';
import Tracking from './mixins/Tracking.vue';
import Translations from './mixins/Translations.vue';
import UrlHelpers from './mixins/UrlHelpers.vue';
import Workers from './sections/Workers.vue';
import { mapGetters } from 'vuex';
import Vue from 'vue';
// @debug
import Debug from './debug/Debug.vue';

export default {
  name: 'bookero-form',
  mixins: [
    Debounce,
    PluginAPI,
    CommonMethods,
    CustomConfigHelpers,
    UrlHelpers,
    Tracking,
    Translations
  ],
  components: {
    'add-to-cart': AddToCart,
    'alert-popup': AlertPopup,
    'agreements': Agreements,
    'customer': Customer,
    'dates': Dates,
    'days': Days,
    'details-popup': DetailsPopup,
    'errors': Errors,
    'hours': Hours,
    'inquiries-list': InquiriesList,
    'invoice': Invoice,
    'payment-and-discount': PaymentAndDiscount,
    'payment-summary': PaymentSummary,
    'params': Params,
    'people': People,
    'reservation-type': ReservationType,
    'result-popup': ResultPopup,
    'required-fields-notice': RequiredFieldsNotice,
    'services': Services,
    'submit': Submit,
    'temporary-price': TemporaryPrice,
    'tooltip': Tooltip,
    'workers': Workers,
    // @debug
    'debug': Debug
  },
  computed: {
    ...mapGetters([
      'agreementsAreAvailable',
      'getConfig',
      'getFilteredPaymentMethods',
      'getMode',
      'getFormState',
      'getInquiries',
      'getServices',
      'getSelectedService',
      'hasDiscountCode',
      'hasSelectedService',
      'hasServices',
      'hasSpecialParams',
      'hasParams',
      'hasVisibleParams',
      'hasPaymentMethods',
      'hasWorkers',
      'isInitialized',
      'peopleAreRequired',
      'showPaymentsInSummary',
      'getWaitingListInInquiries',
      'getCustomConfigField',
      'selectedServiceIsDailyService',
      'selectedServiceRequiresChildren'
    ]),
    ...mapGetters({
      'getStorePrice': 'getPrice'
    }),
    defaultWorkerDisabled () {
      return this.getCustomConfigField('disable_default_worker') || this.getConfig.disable_default_worker;
    },
    canBeDisplayed () {
      if (!this.hasServices) {
        return true;
      }

      if (this.hasServices) {
        if (!this.selectedServiceIsDailyService && this.hasSelectedService) {
          if (this.hasWorkers) {
            if (!this.defaultWorkerDisabled) {
              return true;
            }

            return !!this.getFormState.selectedWorker;
          } else {
            return true;
          }
        } else if (this.selectedServiceIsDailyService && this.hasSelectedService) {
          return true;
        }
      }

      return false;
    },
    specialParamsGroupedValues () {
      if (this.$store.state.specialParams) {
        let filteredParamIDs = this.$store.state.specialParams.map(param => param.id);
        let values = this.$store.state.formState.specialParamsValues;
        let filteredValues = [];

        for (let i = 0; i < values.length; i++) {
          if (filteredParamIDs.indexOf(parseInt(values[i].id, 10)) !== -1) {
            filteredValues.push(values[i]);
          }
        }

        return JSON.stringify(filteredValues);
      }

      return false;
    },
    paramsGroupedValues () {
      if (this.$store.state.params) {
        let filteredParamIDs = this.$store.state.params.map(param => param.id);
        let values = this.$store.state.formState.paramsValues;
        let filteredValues = [];

        for (let i = 0; i < values.length; i++) {
          if (filteredParamIDs.indexOf(parseInt(values[i].id, 10)) !== -1) {
            filteredValues.push(values[i]);
          }
        }

        return JSON.stringify(filteredValues);
      }

      return false;
    },
    priceRequestTrigger () {
      if (this.getConfig.cart_enabled) {
        return [
          this.$store.state.formState.selectedPaymentMethod,
          this.$store.state.formState.discountCode
        ].join(';');
      }

      return [
        this.$store.state.formState.selectedPaymentMethod,
        this.$store.state.formState.peopleNumber,
        this.$store.state.formState.discountCode,
        this.$store.state.formState.name,
        this.$store.state.formState.email,
        this.$store.state.formState.phone,
        this.$store.state.formState.selectedWorker,
        this.$store.state.formState.selectedService,
        this.$store.state.formState.selectedCustomDuration,
        this.$store.state.formState.selectedPeriodicity,
        this.$store.state.formState.date,
        this.$store.state.formState.time,
        this.specialParamsGroupedValues,
        this.paramsGroupedValues
      ].join(';');
    },
    temporaryPriceRequestTrigger () {
      if (!this.getConfig.show_temporary_price) {
        return false;
      }

      return [
        this.$store.state.formState.selectedPaymentMethod,
        this.$store.state.formState.peopleNumber,
        this.$store.state.formState.childrenNumber,
        this.$store.state.formState.daysNumber,
        this.$store.state.formState.discountCode,
        this.$store.state.formState.name,
        this.$store.state.formState.email,
        this.$store.state.formState.phone,
        this.$store.state.formState.selectedWorker,
        this.$store.state.formState.selectedService,
        this.$store.state.formState.selectedCustomDuration,
        this.$store.state.formState.selectedPeriodicity,
        this.$store.state.formState.date,
        this.$store.state.formState.time,
        this.specialParamsGroupedValues,
        this.paramsGroupedValues
      ].join(';');
    }
  },
  watch: {
    priceRequestTrigger (newValue, oldValue) {
      if (this.cartStep === 2) {
        this.getPrice();
      }
    },
    temporaryPriceRequestTrigger (newValue, oldValue) {
      if (this.cartStep === 1 && this.inquiryFormVisible) {
        this.getTemporaryPrice();
      }
    },
    isInitialized (newValue, oldValue) {
      if (newValue === true) {
        document.getElementById('bookero-plugin').setAttribute('data-is-initialized', 'true');
        this.$root.dispatchEvent('bookero-plugin:loaded');
      }
    }
  },
  data () {
    return {
      loadError: false,
      inquiryFormVisible: true,
      cartStep: 1,
      trackingTemporaryData: {},
      priceLoadError: false,
      priceIsLoading: false,
      temporaryPriceLoadError: false,
      temporaryPriceIsLoading: false,
      defaultCurrency: ''
    };
  },
  mounted () {
    this.getPluginCustomConfig();
    this.getCustomerData();

    if (!this.hasValidUrlParams()) {
      return;
    }

    this.getPluginConfig();
    this.$bus.$on('bookero-plugin-get-price', this.getPrice);
    this.$bus.$on('bookero-plugin-dispatch-event', this.$root.dispatchEvent);
    this.$bus.$on('bookero-plugin-show-inquiry-form', this.showInquiryForm);
    this.$bus.$on('bookero-plugin-hide-inquiry-form', this.hideInquiryForm);
    this.$bus.$on('bookero-plugin-get-temporary-price-start', this.getTemporaryPriceStart);
    this.$bus.$on('bookero-plugin-get-temporary-price-end', this.getTemporaryPriceEnd);
    this.$bus.$on('bookero-plugin-get-price-start', this.getPriceStart);
    this.$bus.$on('bookero-plugin-get-price-end', this.getPriceEnd);
    this.$bus.$on('bookero-plugin-get-price-reload', this.getPrice);
    this.$bus.$on('bookero-plugin-get-temporary-price-reload', this.getTemporaryPrice);
  },
  debounceMethods: {
    getPrice: 1000,
    getTemporaryPrice: 1000
  },
  methods: {
    getPluginCustomConfig () {
      if (!window.bookero_config) {
        window.bookero_config = {};
      }

      if (!window.bookero_config.custom_config) {
        window.bookero_config.custom_config = {};
      }

      this.$store.commit('setCustomConfig', window.bookero_config.custom_config);
    },
    getPluginConfig () {
      let pluginConfig = this.$store.state.pluginConfig;

      this.$http.get(this.$endpointURL + '/init', {
        params: {
          bookero_id: pluginConfig.id,
          requestFrom: JSON.stringify({
            url: window.location.href,
            domain: window.location.host,
            catalog: window.location.pathname,
            queryString: window.location.search
          }),
          lang: pluginConfig.lang,
          type: pluginConfig.type
        }
      }).then(res => {
        if (!res.data.result || res.data.result === -1) {
          this.loadError = true;

          if (res.data.messages) {
            this.$store.commit('setTranslations', res.data.messages);
          }

          return;
        } else {
          this.loadError = false;
        }

        let newConfig;
        this.$store.commit('setCurrency', res.data.default_currency);
        this.defaultCurrency = res.data.default_currency;

        if (window.bookero_config.override) {
          newConfig = Object.assign({}, JSON.parse(JSON.stringify(res.data)), window.bookero_config.override);
        } else {
          newConfig = JSON.parse(JSON.stringify(res.data));
        }

        if (window.bookero_config.override_lang_vars && typeof window.bookero_config.override_lang_vars === 'object') {
          let langVarsToOverride = Object.keys(window.bookero_config.override_lang_vars);

          for (let i = 0; i < langVarsToOverride.length; i++) {
            let langVar = langVarsToOverride[i];

            if (newConfig.lang_vars[langVar]) {
              newConfig.lang_vars[langVar] = window.bookero_config.override_lang_vars[langVar];
            }
          }
        }

        if (this.paramExistsInUrl('use_worker_id')) {
          let forcedWorkerID = this.getParamFromUrl('use_worker_id', 'int') || null;

          if (forcedWorkerID) {
            window.bookero_config.custom_config.use_worker_id = forcedWorkerID;
          }
        } else if (this.paramExistsInUrl('use_worker_ids')) {
          let forcedWorkerIDs = this.getParamFromUrl('use_worker_ids', 'comma-separated-int') || null;

          if (forcedWorkerIDs) {
            window.bookero_config.custom_config.use_worker_ids = forcedWorkerIDs;
          }
        }

        if (this.paramExistsInUrl('hide_worker_info')) {
          let hideWorkerInfo = this.getParamFromUrl('hide_worker_info', 'int') || null;

          if (hideWorkerInfo !== null && (hideWorkerInfo === 1 || hideWorkerInfo === 0)) {
            window.bookero_config.custom_config.hide_worker_info = hideWorkerInfo;
          }
        }

        this.$store.commit('setConfig', newConfig);
        this.$store.commit('setDefaultPaymentMethod');

        setTimeout(() => {
          this.$store.commit('initialized');
          this.parseUrl();
          this.getPrice();

          let customStartDate = this.getCustomConfigField('use_start_date');

          if (customStartDate && new RegExp(/[0-9]{4,4}-[0-9]{2,2}-[0-9]{2,2}/gmi).test(customStartDate)) {
            this.setDate(customStartDate);
          }

          setTimeout(() => {
            this.trackingDisplay();
          }, 1000);
        }, 0);
      }).catch((e) => {
        console.log(e);
        console.log('Response:', e.response);
        this.loadError = true;
      });
    },
    getTemporaryPrice () {
      this.getPrice(true);
    },
    getPrice (temporaryPriceRequest = false) {
      // skip getting price if there is no payment methods
      if (this.$store.state.config && this.$store.state.config.payment_methods && !this.$store.state.config.payment_methods.length) {
        return;
      }

      let priceType = temporaryPriceRequest ? 'temporary-price' : 'price';
      let pluginConfig = this.$store.state.pluginConfig;
      let paramsData = [];
      paramsData = paramsData.concat(this.$store.state.formState.specialParamsValues);
      paramsData = paramsData.concat(this.$store.state.formState.paramsValues);
      this.priceLoadError = false;
      this.temporaryPriceLoadError = false;

      let params = {
        'data': {
          'parameters': paramsData.filter(obj => !(Object.keys(obj).length === 0 && obj.constructor === Object)),
          'services_names': this.getServiceNames(),
          'services_values': this.getServiceValues()
        }
      };

      let inquiries = [];

      if (!this.getConfig.cart_enabled || temporaryPriceRequest) {
        let workerID = 0;

        if (this.getFormState.selectedWorker) {
          workerID = this.getFormState.selectedWorker;
        } else if (this.getFormState.preSelectedWorker) {
          workerID = this.getFormState.preSelectedWorker;
        }

        let inquiryData = {
          plugin_comment: JSON.stringify(params),
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          date: this.getFormState.date || '',
          hour: this.getFormState.time || '',
          email: this.getFormState.email || '',
          service: this.getFormState.selectedService,
          worker: workerID,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          discount_code: this.getFormState.discountCode
        };

        if (this.selectedServiceIsDailyService) {
          inquiryData.days = this.getFormState.daysNumber;
          inquiryData.worker = null;
          inquiryData.hour = null;

          if (this.selectedServiceRequiresChildren) {
            inquiryData.children = this.getFormState.childrenNumber;
          }
        }

        inquiries.push(inquiryData);
      } else {
        inquiries = this.getInquiries;
      }

      if (!inquiries.length) {
        return;
      }

      if (!inquiries.filter(inquiry => inquiry.service).length) {
        return;
      }

      this.$bus.$emit('bookero-plugin-get-' + priceType + '-start');

      this.$http.post(this.$endpointURL + '/getPrice?temporary=' + temporaryPriceRequest, {
        bookero_id: pluginConfig.id,
        lang: pluginConfig.lang,
        inquiries: inquiries,
        discount_code: this.getFormState.discountCode,
        configuration_payment_id: this.getFormState.selectedPaymentMethod
      }).then(res => {
        if (res && res.data && res.data.result) {
          let commonPaymentMethods = Object.keys(res.data.data.common_payment_methods).map(key => res.data.data.common_payment_methods[key]);

          if (temporaryPriceRequest) {
            this.$store.commit('setTemporaryCurrency', res.data.data.currency);
            this.$store.commit('setTemporaryPrice', res.data.data.total_price);
          } else {
            this.$store.commit('setCurrency', res.data.data.currency);
            this.$store.commit('setPrice', res.data.data.total_price);
            this.$store.commit('setInquiryPrices', res.data.data.prices);
            this.$store.commit('setCommonPaymentMethods', commonPaymentMethods);
            this.$bus.$emit('bookero-plugin-payment-methods-updated');
          }

          this.$root.dispatchEvent('bookero-plugin:price-updated', { price: res.data.data.total_price });
        } else if (!inquiries.length) {
          if (temporaryPriceRequest) {
            this.$store.commit('setTemporaryCurrency', '');
            this.$store.commit('setTemporaryPrice', 0);
          } else {
            this.$store.commit('setCurrency', this.defaultCurrency);
            this.$store.commit('setPrice', 0);
            this.$store.commit('setCommonPaymentMethods', []);
            this.$bus.$emit('bookero-plugin-payment-methods-updated');
          }
          this.$root.dispatchEvent('bookero-plugin:' + priceType + '-updated', { price: 0 });
        } else {
          if (temporaryPriceRequest) {
            this.priceLoadError = false;
            this.temporaryPriceLoadError = true;
          } else {
            this.priceLoadError = true;
            this.temporaryPriceLoadError = false;
          }
        }

        this.$bus.$emit('bookero-plugin-get-' + priceType + '-end');
      }).catch(() => {
        if (temporaryPriceRequest) {
          this.priceLoadError = false;
          this.temporaryPriceLoadError = true;
        } else {
          this.priceLoadError = true;
          this.temporaryPriceLoadError = false;
        }

        this.$bus.$emit('bookero-plugin-get-' + priceType + '-end');
      });
    },
    parseUrl () {
      let urlData = window.location.search.replace('?', '');

      if (urlData !== '') {
        urlData = urlData.split('&').map(urlPart => urlPart.split('='));
      } else {
        return;
      }

      let dateParam = urlData.filter(urlPart => urlPart[0] === 'date');

      if (dateParam.length) {
        dateParam = dateParam[0][1];

        if (!this.getCustomConfigField('use_start_date') && new RegExp(/[0-9]{4,4}-[0-9]{2,2}-[0-9]{2,2}/gmi).test(dateParam)) {
          this.setDate(dateParam);
        }
      }
    },
    setDate (dateValue) {
      let dateParts = dateValue.split('-').map(n => parseInt(n, 10));
      let dateObject = new Date(dateParts[0], dateParts[1] - 1, dateParts[2]);
      let currentDate = new Date();

      if (this.getMode === 'calendar' || this.getMode === 'sticky') {
        let offset = CalendarUtils.getMonthsDiff(currentDate, dateObject);

        if (offset >= 0) {
          this.$store.dispatch('setDate', dateValue);
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: dateValue });
          this.$store.commit('setCalendarCurrentMonth', offset);
          this.$store.commit('setDateMonthOffset', offset);
        }
      }

      if (this.getMode === 'full') {
        let offset = CalendarUtils.getWeeksDiff(currentDate, dateObject);

        if (offset >= 0) {
          this.$store.dispatch('setDate', dateValue);
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: dateValue });
          this.$store.commit('setCurrentWeek', offset);
          this.$store.commit('setDateWeekOffset', offset);
        }
      }

      if (this.getMode === 'standard') {
        this.$store.dispatch('setDate', dateValue);
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: dateValue });
        this.$refs['dates'].selectedDate = dateObject;
      }
    },
    showInquiryForm () {
      this.inquiryFormVisible = true;

      setTimeout(() => {
        this.$refs['hideable-part'].classList.add('has-overflow');
        this.$refs['hideable-part'].style.height = this.$refs['hideable-part-wrapper'].getBoundingClientRect().height + 'px';
        this.$refs['hideable-part'].classList.remove('is-hidden');

        setTimeout(() => {
          this.$refs['hideable-part'].style.height = 'auto';
          this.$refs['hideable-part'].classList.remove('has-overflow');
        }, 350);
      }, 0);
    },
    hideInquiryForm () {
      this.$refs['hideable-part'].classList.add('has-overflow');
      this.$refs['hideable-part'].style.height = this.$refs['hideable-part-wrapper'].getBoundingClientRect().height + 'px';

      setTimeout(() => {
        this.$refs['hideable-part'].style.height = '0px';
        this.$refs['hideable-part'].classList.add('is-hidden');
        this.$refs['hideable-part'].classList.remove('has-overflow');

        setTimeout(() => {
          this.inquiryFormVisible = false;

          Vue.nextTick(() => {
            let selector = '.bookero-plugin-form .inquiries-list-add';
            let scrollOffset = 50;

            if (window.bookero_config.override && window.bookero_config.override.scroll_after_add_to_cart_selector) {
              selector = window.bookero_config.override.scroll_after_add_to_cart_selector;
            }

            if (window.bookero_config.override && window.bookero_config.override.scroll_after_add_to_cart_additional_offset) {
              scrollOffset = parseInt(window.bookero_config.override.scroll_after_add_to_cart_additional_offset, 10);
            }

            this.$smoothScrollTo(document.querySelector(selector), scrollOffset);
          });
        }, 350);
      }, 0);
    },
    hasValidUrlParams () {
      if (this.getCustomConfigField('forced_url_params') && this.getCustomConfigField('forced_url_params').length) {
        let paramsToCheck = this.getCustomConfigField('forced_url_params');

        for (let i = 0; i < paramsToCheck.length; i++) {
          if (!this.paramExistsInUrl(paramsToCheck[i])) {
            return false;
          }
        }
      }

      return true;
    },
    getCustomerData () {
      if (window.bookero_default_values && window.bookero_default_values.customer) {
        if (window.bookero_default_values.customer.email) {
          this.$store.commit('setCustomerEmail', window.bookero_default_values.customer.email.value);
        }

        if (window.bookero_default_values.customer.phone) {
          this.$store.commit('setCustomerPhone', window.bookero_default_values.customer.phone.value);
        }

        if (window.bookero_default_values.customer.name) {
          this.$store.commit('setCustomerName', window.bookero_default_values.customer.name.value);
        }
      }
    },
    getTemporaryPriceStart () {
      this.temporaryPriceIsLoading = true;
    },
    getTemporaryPriceEnd () {
      this.temporaryPriceIsLoading = false;
    },
    getPriceStart () {
      this.priceIsLoading = true;
    },
    getPriceEnd () {
      this.priceIsLoading = false;
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-get-price', this.getPrice);
    this.$bus.$off('bookero-plugin-dispatch-event', this.$root.dispatchEvent);
    this.$bus.$off('bookero-plugin-show-inquiry-form', this.showInquiryForm);
    this.$bus.$off('bookero-plugin-hide-inquiry-form', this.hideInquiryForm);
    this.$bus.$off('bookero-plugin-get-temporary-price-start', this.getTemporaryPriceStart);
    this.$bus.$off('bookero-plugin-get-temporary-price-end', this.getTemporaryPriceEnd);
    this.$bus.$off('bookero-plugin-get-price-start', this.getPriceStart);
    this.$bus.$off('bookero-plugin-get-price-end', this.getPriceEnd);
    this.$bus.$off('bookero-plugin-get-price-reload', this.getPrice);
    this.$bus.$off('bookero-plugin-get-temporary-price-reload', this.getTemporaryPrice);
  }
}
</script>

<style lang="scss">
@import './../assets/scss/variables.scss';
@import './../assets/scss/common.scss';

body {
  .bookero-plugin {
    &-form {
      font-weight: 300;
      margin: 50px auto;
      max-width: 560px;
      position: relative;
      width: 100%;

      &-cart-step-1,
      &-cart-step-2 {
        display: flex;
        flex-wrap: wrap;

        .bookero-plugin-header {
          margin-top: 0;
        }
      }

      &.is-initalized {

      }

      &-heading {
        color: $color-text-dark;
        font-size: 23px;
        font-weight: 200;
        margin: 25px 0 10px 0;
        text-align: left;
        width: 100%;

        &.has-separator {
          border-top: 1px solid $color-border;
          margin: 20px 0 10px 0;
          padding-top: 30px;
        }

        &.is-inactive {
          opacity: .25;
          pointer-events: none;
        }
      }

      /**
       * Vue Select overrides
       */
      .field {
        .multiselect__input {
          border: none;
          height: 30px!important;
          margin: -5px 0 0 0!important;
          padding: 0 5px!important;
          position: absolute;
        }

        .bookero-plugin-services-list-styled-all {
          .multiselect__content {
            padding: 0!important;
          }

          .multiselect__option.multiselect__option--group.multiselect__option--disabled {
            background: transparent;
            border-top: 1px solid $color-border;
            border-bottom: 1px solid $color-border;
            border-left: none;
            border-right: none;
            border-radius: 0;
            display: block;
            font-size: 12px;
            font-weight: bold;
            line-height: 22px;
            margin: 5px 0;
            min-height: 24px;
            padding: 0 12px;
          }
        }
      }

      .params-section + .add-to-cart-section,
      .dates-section + .add-to-cart-section,
      .hours-section + .add-to-cart-section,
      .inquries-list-section + .submit-section {
        width: 100%;
      }
    }

    &-error {
      background: $color-bg-light;
      border: 1px solid $color-border;
      border-radius: $border-radius-small;
      padding: 30px;
      text-align: center;

      &-msg {
        font-size: 20px;
        margin: 0;
        padding: 10px 0;
      }

      &-btn {
        background: $color-primary;
        border: none;
        border-radius: $border-radius-small;
        color: $color-light;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 0;
        margin: 20px 0 0 0;
        padding: 10px 20px;
        text-transform: none;

        &:active,
        &:focus,
        &:hover {
          background: darken($color-primary, 10%);
        }
      }
    }

    &-header {
      background: $color-primary;
      border-radius: $border-radius-small;
      color: $color-light;
      font-size: 24px;
      font-weight: 300;
      margin: 20px 0;
      padding: 18px 25px;
      position: relative;
      width: 100%;

      &-close {
        height: 24px;
        position: absolute;
        right: 25px;
        top: 50%;
        transform: translateY(-50%);
        transition: all .3s ease-out;
        width: 24px;

        &:before,
        &:after {
          content: "";
          background: $color-light;
          height: 2px;
          left: 50%;
          position: absolute;
          top: 50%;
          transform: translateX(-50%) translateY(-50%) rotate(45deg);
          width: 24px;
        }

        &:before {
          transform: translateX(-50%) translateY(-50%) rotate(-45deg);
        }

        &:hover {
          cursor: pointer;
          transform: translateY(-50%) scale(1.2);
        }
      }
    }

    &-footer {
      color: rgba(0, 0, 0, .66);
      font-size: 12px;
      padding: 50px 0 0 0;
      text-align: center;
    }

    &-hideable-part {
      height: auto;
      margin: 0;
      min-height: 750px;
      transition: all .3s ease-out;

      &.is-hidden {
        min-height: 0;
        opacity: 0;
        overflow: hidden;
      }

      &.has-overflow {
        overflow: hidden;
      }

      .bookero-plugin-header {
        margin-top: 0;
      }
    }

    &-form-message {
      border: 1px solid #eee;
      border-radius: 3px;
      font-weight: 500;
      margin-bottom: 32px;
      padding: 15px 20px;

      &[data-type="info"] {
        background: lighten($color-primary, 45%);
        border-color: lighten($color-primary, 30%);
        color: $color-primary;
      }

      &[data-type="warning"] {
        background: lighten($color-warning, 37.5%);
        border-color: lighten($color-warning, 20%);
        color: darken($color-warning, 20%);
      }

      &[data-type="danger"] {
        background: lighten($color-danger, 40%);
        border-color: lighten($color-danger, 30%);
        color: $color-danger;
      }
    }
  }

  .bookero-plugin-form .people-section.is-without-children-field + .days-section {
    padding-left: 10px;
    width: 50%;

    .field {
      width: 100%;
    }
  }
}

@media (max-width: 580px) {
  body {
    .bookero-plugin-form {
      padding: 0 10px;

      .bookero-plugin-header {
        font-size: 18px;
        padding: 10px 15px;

        &-close {
          right: 10px;
        }
      }
    }
  }
}
</style>
