// Modules
import Vuex from 'vuex';
import Vue from 'vue';
// Helpers
import defaultState from './default.state';
import mutations from './mutations';
// Getters
import getters from './getters';
// Actions
import actions from './actions';

// Add Vuex to the app
Vue.use(Vuex);

export default new Vuex.Store({
  state: JSON.parse(JSON.stringify(defaultState)),
  mutations,
  getters,
  actions
});
