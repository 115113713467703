<template>
  <div :class="{ 'avatar': true, 'has-image': avatarUrl }">
    <img
      v-if="avatarUrl"
      :src="avatarUrl"
      alt="" />

    <span
      v-if="!avatarUrl"
      :style="'background-color:' + avatarColor + '; color: ' + textColor + ';'"
      class="initials">
      <template v-if="worker.avatar_initials">{{ worker.avatar_initials }}</template>
      <template v-else>{{ initials }}</template>
    </span>
  </div>
</template>

<script>
export default {
  name: 'avatar',
  props: {
    'worker': {
      default: null
    },
    'name': {
      default: null
    }
  },
  computed: {
    avatarColor () {
      if (!this.worker || !this.worker.avatar_color) {
        return '#b6d8e9';
      }

      return this.worker.avatar_color;
    },
    textColor () {
      let hex = this.avatarColor.slice(1);

      if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
      }

      let r = parseInt(hex.slice(0, 2), 16);
      let g = parseInt(hex.slice(2, 4), 16);
      let b = parseInt(hex.slice(4, 6), 16);

      return (r * 0.299 + g * 0.587 + b * 0.114) > 186 ? '#000000' : '#FFFFFF';
    },
    avatarUrl () {
      if (this.worker && this.worker.avatar && this.worker.avatar.indexOf('http') === -1) {
        return this.$config.apiServerUrl + '/data/avatars/' + this.worker.avatar;
      }

      if (this.worker && this.worker.avatar && this.worker.avatar.indexOf('http') > -1) {
        return this.worker.avatar;
      }

      return false;
    },
    initials () {
      if (typeof this.name !== 'string') {
        return '??';
      }

      let shortName = this.name.trim().split(' ').filter(word => word.length > 2).map(word => word[0].toUpperCase());

      if (shortName.length > 2) {
        return shortName[0] + shortName[1];
      }

      return shortName.join('');
    }
  }
}
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.avatar {
  background: #eee;
  border-radius: 50%;
  min-height: 90px!important;
  max-height: 90px!important;
  height: 90px!important;
  position: relative;
  min-width: 90px!important;
  max-width: 90px!important;
  width: 90px!important;

  &:hover {
    z-index: 10;
  }

  &.has-image {
    background: #fff;
    border: 1px solid #eee;
  }

  .initials {
    border-radius: 50%;
  }

  img {
    border-radius: 50%;
    display: block;
    min-height: 90px!important;
    max-height: 90px!important;
    height: 90px!important;
    object-fit: cover;
    object-position: center top;
    overflow: hidden;
    min-width: 90px!important;
    max-width: 90px!important;
    width: 90px!important;
  }

  span {
    align-items: center;
    display: flex;
    font-size: 32px;
    font-weight: 600;
    min-height: 90px!important;
    max-height: 90px!important;
    height: 90px!important;
    justify-content: center;
    line-height: 1;
    user-select: none;
    min-width: 90px!important;
    max-width: 90px!important;
    width: 90px!important;
  }
}
</style>
