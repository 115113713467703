export default {
  isInitialized: (state) => {
    return !!state.initialized;
  },
  getCalendarData: (state) => {
    return state.calendarData;
  },
  getCalendarCurrentMonth: (state) => {
    return state.calendarState.currentMonth;
  },
  getWeekData: (state) => {
    return state.weekData;
  },
  getCurrentWeek: (state) => {
    return state.weekState.currentWeek;
  },
  getConfig: (state) => {
    return state.config;
  },
  getCustomConfigField: (state) => (fieldName) => {
    if (typeof state.custom_config[fieldName] !== 'undefined') {
      return state.custom_config[fieldName];
    }

    return null;
  },
  getMode: (state) => {
    return state.pluginConfig.type;
  },
  getHoursVisibility: (state) => {
    let hideHoursIsActive = state.config.hide_hours_on_widget && state.config.only_first_hour;

    if (hideHoursIsActive) {
      return false;
    }

    return true;
  },
  getCurrency: (state) => {
    return state.formState.currency;
  },
  getTemporaryCurrency: (state) => {
    return state.formState.temporaryCurrency;
  },
  getErrors: (state) => {
    return state.errors;
  },
  hasError: (state) => (errorName) => {
    return state.errors.indexOf(errorName) > -1;
  },
  getPrice: (state) => {
    return state.formState.price;
  },
  getTemporaryPrice: (state) => {
    return state.formState.temporaryPrice;
  },
  getInquiryPrice: (state) => (inquiryID) => {
    if (inquiryID === null) {
      inquiryID = 'no_plugin_inquiry_id';
    }

    if (!state.formState.prices || !state.formState.prices[inquiryID]) {
      return '';
    }

    return state.formState.prices[inquiryID];
  },
  getDate: (state) => {
    return state.formState.date;
  },
  getDateMonthOffset: (state) => {
    return state.calendarState.currentMonthOnDateSelect;
  },
  getDateWeekOffset: (state) => {
    return state.weekState.currentWeekOnDateSelect;
  },
  getTime: (state) => {
    return state.formState.time;
  },
  getCommonPaymentMethods: (state) => {
    if (!state.config) {
      return [];
    }

    return state.config.payment_methods.filter(method => state.commonPaymentMethods.indexOf(method.id) > -1);
  },
  getFilteredWorkers: (state) => {
    if (state.config.services && state.formState.selectedService !== 0) {
      let workers = state.workers.filter(worker => {
        let service = state.services.filter(service => service.id === state.formState.selectedService);
        return service[0].workers.indexOf(worker.id) > -1;
      });

      return workers;
    }

    return state.workers;
  },
  getFilteredPaymentMethods: (state) => {
    if (state.config.services && state.formState.selectedService) {
      return state.config.payment_methods.filter(method => method.services.indexOf(state.formState.selectedService) > -1);
    }

    return state.config.payment_methods;
  },
  getAllPaymentMethods: (state) => {
    return state.config.payment_methods;
  },
  getInquiries: (state) => {
    return state.inquiries;
  },
  getFormState: (state) => {
    return state.formState;
  },
  getSelectedPaymentMethodName: (state) => {
    if (!state.config.payment_methods || !state.config.payment_methods.length) {
      return '';
    }

    if (state.config.payment_methods.filter(method => method.id === state.formState.selectedPaymentMethod)[0]) {
      return state.config.payment_methods.filter(method => method.id === state.formState.selectedPaymentMethod)[0].name;
    }

    return '';
  },
  getServices: (state) => {
    return state.services;
  },
  getSelectedService: (state) => {
    return state.formState.selectedService;
  },
  getSelectedServiceCategory: (state) => {
    return state.formState.selectedServiceCategory;
  },
  getServiceName: (state) => (serviceID, servicesBackup = false) => {
    if (servicesBackup !== false && Array.isArray(servicesBackup)) {
      if (serviceID === 0) {
        return 'Rezerwacja';
      }

      return servicesBackup.filter(service => service.id === serviceID)[0].name;
    }

    if (serviceID === 0) {
      return 'Rezerwacja';
    }

    return state.services.filter(service => service.id === serviceID)[0].name;
  },
  getServiceNameWithCategory: (state) => (serviceID) => {
    let service = state.services.filter(service => service.id === serviceID)[0];
    let categoryID = service.category_id;
    let category = state.services.filter(service => service.category_id === categoryID)[0].category;

    if (category) {
      return category.name + ': ' + service.name;
    }

    return service.name;
  },
  getServicePeriodicityPaymentType: (state) => (serviceID) => {
    let service = state.services.filter(service => service.id === serviceID);

    if (service && service[0]) {
      return service[0].periodicity_payment_type;
    }

    return 0;
  },
  getServiceCustomLabel: (state) => {
    if (state.config.service_name) {
      return state.config.service_name;
    }

    return null;
  },
  getServiceCategoryName: (state) => (categoryID) => {
    let category = state.services.filter(service => service.category_id === categoryID);

    if (category[0] && category[0].category) {
      return category[0].category.name;
    }

    return null;
  },
  getServiceCategoryNameByServiceID: (state) => (serviceID, servicesBackup = false) => {
    let servicesList = state.services;

    if (servicesBackup !== false && Array.isArray(servicesBackup)) {
      servicesList = servicesBackup;
    }

    let service = servicesList.filter(service => service.id === serviceID)[0];
    let categoryID = service.category_id;
    let category = servicesList.filter(service => service.category_id === categoryID)[0].category;

    if (category) {
      return category.name;
    }

    return null;
  },
  getSelectedWorker: (state) => {
    return state.formState.selectedWorker;
  },
  getPreSelectedWorker: (state) => {
    return state.formState.preSelectedWorker;
  },
  getWorkers: (state) => {
    return state.workers;
  },
  getWorkerName: (state) => (workerID, workersBackup = false) => {
    if (!workerID) {
      return state.translations.default_worker;
    }

    if (workersBackup !== false && Array.isArray(workersBackup)) {
      return workersBackup.filter(worker => worker.id === workerID)[0].name;
    }

    return state.workers.filter(worker => worker.id === workerID)[0].name;
  },
  getParamName: (state) => (paramID) => {
    return state.config.parameters.filter(param => param.id === paramID)[0].name;
  },
  getSpecialParams: (state) => {
    return state.specialParams;
  },
  getParams: (state) => {
    return state.params;
  },
  hasDiscountCode: (state) => {
    return state.config.services &&
      state.config.payment_methods &&
      state.config.payment_methods.length &&
      state.config.discount_code;
  },
  hasPaymentMethods: (state) => {
    return state.config.payment_methods && state.config.payment_methods.length;
  },
  hasSelectedService: (state) => {
    return !!state.formState.selectedService;
  },
  hasServices: (state) => {
    return state.config.services && state.services.length;
  },
  hasSpecialParams: (state) => {
    if (!state.config.parameters) {
      return false;
    }

    return state.config.parameters.filter(parameter => parameter.published && parameter.update_hours).length;
  },
  hasParams: (state) => {
    if (!state.config.parameters) {
      return false;
    }

    return state.config.parameters.filter(parameter => parameter.published && !parameter.update_hours).length;
  },
  hasVisibleParams: (state) => {
    if (state.config.services && state.formState.selectedService === 0) {
      return state.params && state.params.filter(param => {
        return param.connected_services.length === 0;
      }).length;
    }

    if (state.config.services) {
      return state.params && state.params.filter(param => {
        return param.connected_services.length === 0 || param.connected_services.indexOf(state.formState.selectedService) > -1;
      }).length;
    }

    return state.params && state.params.length;
  },
  hasWorkers: (state) => {
    return state.config.workers && state.workers.length;
  },
  peopleAreRequired: (state) => {
    return !!state.config.people_required;
  },
  getPeopleNumber: (state) => {
    return state.formState.peopleNumber;
  },
  getChildrenNumber: (state) => {
    return state.formState.childrenNumber;
  },
  getDaysNumber: (state) => {
    return state.formState.daysNumber;
  },
  agreementsAreAvailable: (state) => {
    return (
      state.config.information_agree ||
      state.config.agree_tp ||
      state.config.agree_newsletter ||
      state.config.agree_telemarketing
    );
  },
  showPaymentsInForm: (state) => {
    return state.config.show_prices === 1 || state.config.show_prices === 3;
  },
  showPaymentsInSummary: (state) => {
    return state.config.show_prices === 2 || state.config.show_prices === 3;
  },
  getCartID: (state) => {
    return state.formState.cartID;
  },
  getWaitingListInCurrentSelection: (state) => {
    return state.formState.waitingList;
  },
  getWaitingListInInquiries: (state) => {
    return state.waitingListInInquiries;
  },
  getCurrentlyBlockedDay: (state) => (date) => {
    return state.currentlyBlockedTermins.filter(termin => termin.date === date);
  },
  getCurrentlyBlockedHours: (state) => (date, time) => {
    return state.currentlyBlockedTermins.filter(termin => termin.date === date && termin.time === time);
  },
  getDiscountCodeStatus: (state) => {
    return state.formState.discountCodeStatus;
  },
  getPriceBeforeDiscount: (state) => {
    return state.formState.priceBeforeDiscount;
  },
  getPriceAfterDiscount: (state) => {
    return state.formState.priceAfterDiscount;
  },
  getDiscountValue: (state) => {
    return state.formState.discountValue;
  },
  selectedPaymentMethodIsCash: (state) => {
    let selectedPaymentMethod = state.formState.selectedPaymentMethod;

    if (!state.config) {
      return false;
    }

    if (!state.config.payment_methods) {
      return false;
    }

    let foundedMethod = state.config.payment_methods.filter(method => method.id === selectedPaymentMethod);

    if (foundedMethod.length && foundedMethod[0].payment_system === 'cash') {
      return true;
    }

    return false;
  },
  selectedServiceHasCustomDurations: (state) => (serviceID) => {
    let filteredServices = state.services.filter(service => service.id === serviceID);

    if (filteredServices.length) {
      return filteredServices[0].custom_duration;
    }

    return false;
  },
  getCustomDuration: (state) => {
    return state.formState.selectedCustomDuration;
  },
  getCustomDurationLabel: (state) => (serviceID, customDurationID) => {
    let filteredDurationData = state.customDurations[serviceID].filter(duration => duration.id === customDurationID);

    if (filteredDurationData.length) {
      return filteredDurationData[0].name;
    }

    return false;
  },
  gaIntegrationEnabled: (state) => {
    return !!state.config.integration_use_ga;
  },
  fbIntegrationEnabled: (state) => {
    return !!state.config.integration_use_fb;
  },
  selectedServiceIsDailyService: (state) => {
    if (state.formState.selectedService !== 0) {
      let service = state.services.filter(service => service.id === state.formState.selectedService)[0];

      if (service && service.available_in_dates === 3) {
        return true;
      }
    }

    return false;
  },
  selectedServiceRequiresChildren: (state) => {
    if (state.formState.selectedService !== 0) {
      let service = state.services.filter(service => service.id === state.formState.selectedService)[0];

      if (service && service.children_required) {
        return true;
      }
    }

    return false;
  },
  selectedServiceHasDailyEndAvailable: (state) => {
    if (state.formState.selectedService !== 0) {
      let service = state.services.filter(service => service.id === state.formState.selectedService)[0];

      if (service && service.daily_end_available) {
        return true;
      }
    }

    return false;
  }
};
