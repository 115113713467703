<template>
  <div class="required-fields-notice">
    {{ getTranslation('form_required_fields') }}
  </div>
</template>

<script>
import Translations from './../mixins/Translations.vue';

export default {
  name: 'required-fields-notice',
  mixins: [
    Translations
  ]
}
</script>

<style lang="scss" scoped>
.required-fields-notice {
  font-size: 12px;
  font-style: normal;
  margin: 10px 0;
  opacity: .66;
}
</style>
