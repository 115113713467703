<template>
  <div class="inquiries-list-section">
    <inquiry
      v-for="(inquiry, index) of getInquiriesData"
      :key="'inquiry-' + index"
      :inquiryData="inquiry"
      :cartEnabled="getConfig.cart_enabled"
      :isEditable="isEditable"
      :listIndex="index" />
    <a
      v-if="isEditable && !$parent.inquiryFormVisible && !getWaitingListInInquiries"
      href="#add-new-inquiry"
      class="inquiries-list-add"
      @click.prevent="showInquiryForm()">
      <span class="inquiries-list-add-icon"></span>
      {{ getTranslation('add_new_reservation') }}
    </a>

    <span
      v-if="getConfig.waiting_list && isEditable && !$parent.inquiryFormVisible && getWaitingListInInquiries"
      class="inquiries-list-waiting-list-notification">
      {{ getTranslation('blocked_cart_beacuse_of_reservelist') }}
    </span>
  </div>
</template>

<script>
import CommonMethods from './../mixins/CommonMethods';
import Inquiry from './Inquiry.vue';
import Translations from './../mixins/Translations.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'InquiriesList',
  components: {
    'inquiry': Inquiry
  },
  mixins: [
    CommonMethods,
    Translations
  ],
  props: [
    'isEditable'
  ],
  computed: {
    ...mapGetters([
      'getConfig',
      'getFormState',
      'getInquiries',
      'getSelectedService',
      'getServices',
      'getWaitingListInInquiries',
      'selectedServiceRequiresChildren',
      'selectedServiceIsDailyService'
    ]),
    getInquiriesData () {
      if (!this.getConfig.cart_enabled) {
        let paramsData = [];
        paramsData = paramsData.concat(this.$store.state.formState.specialParamsValues);
        paramsData = paramsData.concat(this.$store.state.formState.paramsValues);

        let params = {
          'data': {
            'name': this.getFormState.name,
            'phone': this.getFormState.phone,
            'parameters': paramsData.filter(obj => !(Object.keys(obj).length === 0 && obj.constructor === Object)),
            'services_names': this.getServiceNames(),
            'services_values': this.getServiceValues()
          }
        };

        let workerID = null;

        if (this.getFormState.selectedWorker) {
          workerID = this.getFormState.selectedWorker;
        } else if (this.getFormState.preSelectedWorker) {
          workerID = this.getFormState.preSelectedWorker;
        }

        let inquiryData = {
          plugin_comment: JSON.stringify(params),
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          date: this.getFormState.date,
          hour: this.getFormState.time,
          email: this.getFormState.email,
          service: this.getFormState.selectedService,
          worker: workerID,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          plugin_inquiry_id: null
        };

        if (this.selectedServiceIsDailyService) {
          inquiryData.days = this.getFormState.daysNumber;
          inquiryData.worker = null;
          inquiryData.hour = null;

          if (this.selectedServiceRequiresChildren) {
            inquiryData.children = this.getFormState.childrenNumber;
          }
        }

        return [inquiryData];
      } else {
        return this.getInquiries;
      }
    }
  },
  methods: {
    showInquiryForm () {
      this.$bus.$emit('bookero-plugin-show-inquiry-form');

      setTimeout(() => {
        let scrollOffset = 0;

        if (window.bookero_config.override && window.bookero_config.override.scroll_after_add_to_cart_additional_offset) {
          scrollOffset = parseInt(window.bookero_config.override.scroll_after_add_to_cart_additional_offset, 10);
        }

        this.$smoothScrollTo(document.querySelector('.bookero-plugin-new-reservation-form'), scrollOffset);
      }, 350);
    }
  }
}
</script>

<style lang="scss" scoped>
@import './../../assets/scss/variables.scss';

.inquiries-list {
  &-section {
    margin: 0 0 20px 0;
    width: 100%;
  }

  &-header {
    font-size: 20px;
    font-weight: 500;
  }

  &-add {
    align-items: center;
    background: lighten($color-primary, 45%);
    border-radius: $border-radius-small;
    color: $color-primary;
    display: flex;
    height: 48px;
    justify-content: center;
    letter-spacing: 0;
    margin: 20px 0;
    text-decoration: none;
    text-transform: none;
    transition: all .3s ease-out;
    width: 100%;

    &-icon {
      display: inline-block;
      height: 12px;
      margin: 0 10px 0 0;
      position: relative;
      width: 12px;

      &:before,
      &:after {
        background: $color-primary;
        content: "";
        display: block;
        height: 2px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        transform-origin: 50% 50%;
        width: 12px;
      }

      &:before {
        transform: translateY(-50%) rotate(90deg);
      }
    }

    &:active,
    &:focus,
    &:hover {
      background: lighten($color-primary, 42.5%);
      color: $color-primary;
      text-decoration: none;
    }
  }

  &-waiting-list-notification {
    color: $color-text-medium;
    display: block;
    font-size: 14px;
    line-height: 1.6;
    padding: 20px 0;
    text-align: center;
  }
}
</style>
