<template>
  <div
    v-if="invoicesAvailable && userReserveServiceWithInvoices"
    class="invoice-section">
    <div
      v-if="!invoicesRequired"
      class="field">
      <switcher
        v-model="user_want_invoice"
        :label="getTranslation('invoice_field_i_want_invoice')"
        key="toggle-invoice-form" />
    </div>

    <div
      v-if="user_want_invoice || invoicesRequired"
      class="field button-group">
      <a
        href="#is-company"
        :class="{
          'button-group-button': true,
          'is-active': is_company
        }"
        @click.prevent="setInvoiceType('company')">
        {{ getTranslation('invoice_type_company') }}
      </a>
      <a
        href="#is-personal"
        :class="{
          'button-group-button': true,
          'is-active': !is_company
        }"
        @click.prevent="setInvoiceType('personal')">
        {{ getTranslation('invoice_type_personal') }}
      </a>
    </div>

    <div
      v-if="user_want_invoice || invoicesRequired"
      class="invoice-form-fields">
      <div
        data-field-name="company_title"
        :class="{
          'field': true,
          'is-required': true,
          'is-invalid': hasError('invoice_company_title')
        }">
        <label
          for="invoice-company-title"
          v-pure-html="getTranslation('invoice_field_company_title')"></label>
        <div class="field-invoice-company-title">
          <input
            v-model="company_title"
            @input="invoiceFieldChanged($event, 'company_title')"
            name="invoice-company-title"
            key="field-invoice-input-company-title">
          <div
            v-if="hasError('invoice_company_title')"
            class="error-message">
            {{ getTranslation('validation_the_field_is_required') }}
          </div>
        </div>
      </div>

      <div
        data-field-name="address"
        :class="{
          'field': true,
          'is-required': true,
          'is-invalid': hasError('invoice_address')
        }">
        <label
          for="invoice-address"
          v-pure-html="getTranslation('invoice_field_address')"></label>
        <div class="field-invoice-address">
          <input
            v-model="address"
            @input="invoiceFieldChanged($event, 'address')"
            name="invoice-address"
            key="field-invoice-input-address">
          <div
            v-if="hasError('invoice_address')"
            class="error-message">
            {{ getTranslation('validation_the_field_is_required') }}
          </div>
        </div>
      </div>

      <div
        data-field-name="post_code"
        :class="{
          'field': true,
          'is-required': true,
          'is-invalid': hasError('invoice_post_code')
        }">
        <label
          for="invoice-post-code"
          v-pure-html="getTranslation('invoice_field_post_code')"></label>
        <div class="field-invoice-post-code">
          <input
            v-model="post_code"
            @input="invoiceFieldChanged($event, 'post_code')"
            name="invoice-post-code"
            key="field-invoice-input-post-code">
          <div
            v-if="hasError('invoice_post_code')"
            class="error-message">
            {{ getTranslation('validation_the_field_is_required') }}
          </div>
        </div>
      </div>

      <div
        data-field-name="city"
        :class="{
          'field': true,
          'is-required': true,
          'is-invalid': hasError('invoice_city')
        }">
        <label
          for="invoice-city"
          v-pure-html="getTranslation('invoice_field_city')"></label>
        <div class="field-invoice-city">
          <input
            v-model="city"
            @input="invoiceFieldChanged($event, 'city')"
            name="invoice-city"
            key="field-invoice-input-city">
          <div
            v-if="hasError('invoice_city')"
            class="error-message">
            {{ getTranslation('validation_the_field_is_required') }}
          </div>
        </div>
      </div>

      <div
        data-field-name="country"
        :class="{
          'field': true,
          'is-required': true,
          'is-invalid': hasError('invoice_country')
        }">
        <label for="invoice-country" v-pure-html="getTranslation('invoice_field_country')"></label>
        <div class="field-invoice-country">
          <select
            v-model="country"
            @input="invoiceFieldChanged($event, 'country')"
            name="invoice-country"
            class="is-short"
            key="field-invoice-select-country">
            <option
              v-for="(countryCode, index) of countryCodes"
              :key="'country-code-' + index"
              :value="countryCode">
              {{ countryCode }}
            </option>
          </select>

          <div
            v-if="hasError('invoice_country')"
            class="error-message">
            {{ getTranslation('validation_the_field_is_required') }}
          </div>
        </div>
      </div>

      <div
        v-if="is_company"
        data-field-name="nip"
        :class="{
          'field': true,
          'is-required': is_company,
          'is-invalid': hasError('nip')
        }">
        <label
          for="invoice-nip"
          v-pure-html="getTranslation('invoice_field_nip')"></label>
        <div class="field-invoice-nip">
          <input
            v-model="nip"
            @input="invoiceFieldChanged($event, 'nip')"
            name="invoice-nip"
            key="field-invoice-input-nip">
          <div
            v-if="hasError('invoice_nip')"
            class="error-message">
            {{ getTranslation('validation_the_field_is_required') }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Switcher from './../elements/Switcher';
import Translations from './../mixins/Translations';
import ISOCountryCodes from './../config/iso-country-codes';
import { mapGetters } from 'vuex';

export default {
  name: 'invoice',
  mixins: [
    Translations
  ],
  components: {
    'switcher': Switcher
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getInquiries',
      'getFormState',
      'hasError'
    ]),
    invoicesAvailable () {
      return this.getConfig.invoice_settings && !!this.getConfig.invoice_settings.invoices_available;
    },
    invoicesRequired () {
      return this.getConfig.invoice_settings && !!this.getConfig.invoice_settings.invoices_required;
    },
    userReserveServiceWithInvoices () {
      let serviceIDs = [];
      let servicesWithInvoices = [];

      if (this.getConfig.invoice_settings) {
        servicesWithInvoices = this.getConfig.invoice_settings.services.map(serviceItem => serviceItem.id);
      }

      if (this.getInquiries.length) {
        serviceIDs = this.getInquiries.map(serviceData => serviceData.service);
      } else {
        serviceIDs = [this.getFormState.selectedService];
      }

      return servicesWithInvoices.some(serviceID => serviceIDs.includes(serviceID));
    },
    countryCodes () {
      return Object.keys(ISOCountryCodes);
    }
  },
  data () {
    return {
      user_want_invoice: false,
      is_company: false,
      company_title: '',
      address: '',
      post_code: '',
      city: '',
      country: '',
      nip: '',
      unwatcher: false
    };
  },
  watch: {
    'user_want_invoice': function (newValue, oldValue) {
      if (!newValue && !!oldValue) {
        this.$store.commit('removeError', 'invoice_company_title');
        this.$store.commit('removeError', 'invoice_address');
        this.$store.commit('removeError', 'invoice_post_code');
        this.$store.commit('removeError', 'invoice_city');
        this.$store.commit('removeError', 'invoice_country');
        this.$store.commit('removeError', 'invoice_nip');
      }
    },
    'is_company': function (newValue, oldValue) {
      if (!newValue && !!oldValue) {
        this.$store.commit('removeError', 'invoice_nip');
      }
    }
  },
  mounted () {
    this.$bus.$on('bookero-plugin-validate', this.validate);
    this.user_want_invoice = this.getFormState.user_want_invoice;
    this.is_company = this.getFormState.invoice_is_company;
    this.company_title = this.getFormState.invoice_company_title;
    this.address = this.getFormState.invoice_address;
    this.post_code = this.getFormState.invoice_post_code;
    this.city = this.getFormState.invoice_city;
    this.country = this.getFormState.invoice_country;
    this.nip = this.getFormState.invoice_nip;

    if (!this.country && this.getConfig.invoice_settings) {
      this.country = this.getConfig.invoice_settings.country;

      this.$store.commit('setInvoiceField', {
        field: 'country',
        value: this.country
      });
    }

    this.unwatcher = this.$watch('user_want_invoice', (newValue) => {
      this.$store.commit('userWantInvoice', newValue);
    });
  },
  methods: {
    invoiceFieldChanged (event, fieldName) {
      this.$store.commit('setInvoiceField', {
        field: fieldName,
        value: event.target.value
      });

      if (this.hasError('invoice_' + fieldName)) {
        this.$store.commit('removeError', 'invoice_' + fieldName);
      }

      if (fieldName === 'is_company' && this[fieldName] === false && this.hasError('invoice_nip')) {
        this.$store.commit('removeError', 'invoice_nip');
      }
    },
    setInvoiceType (invoiceType) {
      if (invoiceType === 'personal') {
        this.is_company = false;
        this.$store.commit('setInvoiceField', {
          field: 'is_company',
          value: false
        });
      } else if (invoiceType === 'company') {
        this.is_company = true;
        this.$store.commit('setInvoiceField', {
          field: 'is_company',
          value: true
        });
      }
    },
    validate () {
      if (!this.invoicesAvailable || !this.userReserveServiceWithInvoices) {
        return;
      }

      if (!this.user_want_invoice && !this.invoicesRequired) {
        return;
      }

      let fieldsToValidate = ['company_title', 'address', 'post_code', 'city', 'country'];

      if (this.is_company) {
        fieldsToValidate = ['company_title', 'address', 'post_code', 'city', 'country', 'nip'];
      }

      for (let i = 0; i < fieldsToValidate.length; i++) {
        let fieldName = fieldsToValidate[i];

        if (this.getFormState['invoice_' + fieldName] === '') {
          this.$store.commit('addError', 'invoice_' + fieldName);
        }
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);

    if (typeof this.unwatcher === 'function') {
      this.unwatcher();
    }
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.invoice-section {
  padding: 10px 0;
  width: 100%;

  .field[data-field-name="company_title"],
  .field[data-field-name="address"] {
    width: 100%;
  }

  .field[data-field-name="post_code"] {
    width: 120px;
  }

  .field[data-field-name="country"] {
    width: 80px;
  }

  .field[data-field-name="city"] {
    margin-left: 20px;
    width: calc(100% - 140px);
  }

  .field[data-field-name="nip"] {
    margin-left: 20px;
    width: calc(100% - 100px);
  }

  .invoice-form-fields {
    display: flex;
    flex-wrap: wrap;
  }

  .button-group {
    display: flex;
    margin: 10px auto;

    .button-group-button {
      border: 1px solid $color-primary;
      border-radius: $border-radius-small 0 0 $border-radius-small;
      display: block;
      padding: 10px;
      text-align: center;
      width: 50%;
      &.is-active {
        background: $color-primary;
        color: $color-light!important;
      }

      & + .button-group-button {
        border-radius: 0 $border-radius-small $border-radius-small 0;
      }
    }
  }
}
</style>
