<script>
export default {
  name: 'UrlHelpers',
  methods: {
    getParamsAlreadyLoadedFromUrl () {
      let wrapper = document.querySelector('div[data-instance-id="' + this.$root.instanceID + '"]');
      return wrapper.getAttribute('data-loaded-from-url').split(',');
    },
    paramExistsInUrl (name) {
      let urlSearchPart = window.location.search.replace('?', '');
      let queryString = urlSearchPart.split('&').map(chunk => chunk.split('='));
      let value = queryString.filter(paramKeyValue => paramKeyValue[0] === name);

      if (value[0]) {
        return true;
      }

      return false;
    },
    getParamFromUrl (name, type = 'int') {
      let urlSearchPart = window.location.search.replace('?', '');
      let queryString = urlSearchPart.split('&').map(chunk => chunk.split('='));
      let value = queryString.filter(paramKeyValue => paramKeyValue[0] === name);

      if (value[0]) {
        if (type === 'int') {
          return parseInt(value[0][1], 10);
        } else if (type === 'comma-separated-int') {
          return value[0][1].split(',').map(n => parseInt(n, 10));
        }
      }

      return null;
    },
    setParamAsLoadedFromUrl (name) {
      let wrapper = document.querySelector('div[data-instance-id="' + this.$root.instanceID + '"]');
      let params = wrapper.getAttribute('data-loaded-from-url').split(',');
      params.push(name);
      params = params.join(',');
      wrapper.setAttribute('data-loaded-from-url', params);
    }
  }
}
</script>
