<template>
  <div
    v-if="shouldDisplayHours"
    :class="{ 'hours-section': true, 'is-loading': hoursAreLoading, 'has-error': lastLoadError !== false }">
    <h3 class="bookero-plugin-form-heading">
      {{ getTranslation('time') }}
    </h3>

    <div :class="{ 'field': true, 'is-required': true, 'is-invalid': hasError('hour') }">
      <div
        :class="{ 'hours-wrapper': true, 'is-loading': hoursAreLoading }"
        :data-mode="getMode">
        <div
          v-if="hoursData && !hoursData.valid_day && !hoursAreLoading && hoursData.message !== ''"
          class="hours-error">
          {{ hoursData.message }}
        </div>

        <div
          v-if="hoursData && !hoursData.valid_day && !hoursAreLoading && hoursData.message === '' && !filteredHours.length"
          class="hours-error">
          {{ getTranslation('no_free_terms') }}
        </div>

        <div
          v-if="hoursData && hoursData.hours && hoursData.hours.length && getMode !== 'standard' && filteredHours.length"
          :class="{ 'hours-list': true, 'wide-hours': hoursHaveInformation }">
          <div
            v-for="(item, index) of filteredHours"
            :key="'hours-list-item-' + index"
            :ref="'hours-list-item-' + index"
            :class="{ 'hours-list-item': true, 'is-waiting-list': item.valid === -1, 'is-selected': getTime === item.hour, 'is-in-cart': isInCart(item.hour), 'is-blocked': isBlocked(item.hour) }"
            @click="changeHour(item.hour, item.valid, item.free_workers)"
            @mouseenter="showTooltip(item.valid, 'hours-list-item-' + index)"
            @mouseleave="hideTooltip()">
            {{ item.hour }}
            <span
              v-if="item.information !== ''"
              class="hours-list-item-info"
              v-pure-html="item.information" />
            <!--<div
              v-if="isInCart(item.hour)"
              class="hours-list-item-tooltip">
              <template v-if="getConfig.cart_enabled">
                {{ getTranslation('termin_exisits_in_cart') }}
              </template>
              <template v-else>
                {{ getTranslation('choosen_termin') }}
              </template>
              <template v-if="isBlocked(item.hour)">
                <br>
                {{ getTranslation('cant_add_more_reservation_to_termin') }}
              </template>
            </div>-->
          </div>
        </div>

        <div
          v-if="hoursData && hoursData.hours && hoursData.hours.length && getMode === 'standard' && filteredHours.length"
          class="hours-list">
          <select
            v-if="!getConfig.use_styled_dropdowns"
            @change="changeHourSelect">
            <option
              v-for="(item, index) of filteredHours"
              :key="'hours-list-item-' + index"
              :disabled="isInCart(item.hour) || isBlocked(item.hour)"
              :value="item.hour"
              :selected="getTime === item.hour">
              {{ item.hour }}
              <template v-if="item.valid === -1">
                - {{ getTranslation('waiting_list') }}
              </template>
            </option>
          </select>

          <v-select
            v-if="getConfig.use_styled_dropdowns"
            @input="changeHourSelect"
            v-model="selectedHour"
            :options="filteredHoursForSelect"
            :custom-label="hourLabelForSelect"
            :allowEmpty="false"
            :showLabels="false"
            :searchable="false">
          </v-select>
        </div>
      </div>

      <div
        v-if="hasError('hour')"
        class="error-message">
        {{ getTranslation('validation_choose_the_hour') }}
      </div>
    </div>

    <div
      v-if="typeof lastLoadError.checkTime !== 'undefined'"
      class="hours-section-load-error">
      <span class="hours-section-load-error-label">
        {{ getTranslation('error_when_loading_hours') }}

        <a
          @click.prevent="reloadAfterError"
          class="hours-section-load-error-reload-link"
          href="#reload">
          {{ getTranslation('click_to_refresh') }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import CommonMethods from './../mixins/CommonMethods';
import Debounce from './../mixins/Debounce.vue';
import MultiSelect from 'vue-multiselect';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'hours',
  mixins: [
    CommonMethods,
    Debounce,
    Translations
  ],
  components: {
    'v-select': MultiSelect
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getCurrentlyBlockedHours',
      'getDate',
      'getTime',
      'getMode',
      'getFormState',
      'getInquiries',
      'getMode',
      'getSelectedService',
      'getServices',
      'getSelectedWorker',
      'getWaitingListInInquiries',
      'hasError',
      'getHoursVisibility',
      'selectedServiceIsDailyService'
    ]),
    shouldDisplayHours () {
      return !this.selectedServiceIsDailyService && this.getSelectedService && (this.getHoursVisibility && !this.selectedServiceHasDates && (this.getMode === 'calendar' || this.getMode === 'standard' || this.getMode === 'sticky') && this.getDate);
    },
    filteredHours () {
      if (!this.hoursData.hours) {
        return [];
      }

      if (this.getConfig.waiting_list && !this.getInquiries.length) {
        return this.hoursData.hours.filter(hourData => hourData.valid !== 0);
      }

      return this.hoursData.hours.filter(hourData => hourData.valid > 0);
    },
    filteredHoursForSelect () {
      if (!this.hoursData || !this.hoursData.hours) {
        return [];
      }

      if (this.getConfig.waiting_list && !this.getInquiries.length) {
        return this.hoursData.hours.filter(hourData => hourData.valid !== 0).map(hourData => hourData.hour);
      }

      return this.hoursData.hours.filter(hourData => hourData.valid > 0).map(hourData => hourData.hour);
    },
    selectedServiceHasDates () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === this.getSelectedService)[0];

        if (!service) {
          return false;
        }

        return service.available_in_dates === 1;
      }

      return false;
    },
    hoursHaveInformation () {
      if (!this.hoursData || !this.hoursData.hours || !this.hoursData.hours.length) {
        return false;
      }

      for (let i = 0; i < this.hoursData.hours.length; i++) {
        if (this.hoursData.hours[i].information !== '') {
          return true;
        }
      }

      return false;
    }
  },
  data () {
    return {
      pluginConfig: false,
      hoursAreLoading: false,
      hoursData: false,
      selectedHour: '',
      lastRequestID: 0,
      lastLoadError: false
    };
  },
  watch: {
    getDate (newState, oldState) {
      if ((this.getSelectedService !== 0 && !this.selectedServiceHasDates) || !this.getServices.length) {
        this.hoursAreLoading = true;
        this.$store.dispatch('setTime', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
        this.getHours();
      }
    },
    getSelectedService (newState) {
      if (!this.selectedServiceHasDates && this.getMode === 'standard') {
        this.hoursAreLoading = true;
        this.$store.dispatch('setTime', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
        this.getHours();
      }
    }
  },
  mounted () {
    this.pluginConfig = this.$store.state.pluginConfig;
    this.$bus.$on('bookero-plugin-validate', this.validate);
    this.$bus.$on('bookero-plugin-reload-time', this.reload);
    this.selectedHour = this.getTime;
    this.reload();
  },
  debounceMethods: {
    getHours: 500
  },
  methods: {
    getHours (checkTime = false) {
      if (this.selectedServiceIsDailyService || this.selectedServiceHasDates || !this.getFormState.selectedService) {
        return;
      }

      let requestID = +new Date();
      this.hoursAreLoading = true;
      this.lastLoadError = false;
      this.lastRequestID = requestID;

      this.$http.get(this.$endpointURL + '/getMonthDay', {
        params: {
          bookero_id: this.pluginConfig.id,
          plugin_comment: JSON.stringify({
            data: {
              parameters: this.getFormState.specialParamsValues
            }
          }),
          date: this.getFormState.date,
          hour: this.getFormState.time,
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          email: this.getFormState.email,
          service: this.getFormState.selectedService,
          lang: this.pluginConfig.lang,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          worker: this.getFormState.selectedWorker
        }
      }).then(res => {
        if (this.lastRequestID !== 0 && this.lastRequestID !== requestID) {
          return;
        }

        if (res.data && res.data.data) {
          Vue.set(this, 'hoursData', res.data.data);

          if (checkTime && res.data.data.hours) {
            let hour = res.data.data.hours.filter(hourData => hourData.hour === this.getTime);

            if (!(hour[0] && hour[0].valid === 1) && !this.selectedServiceHasDates) {
              this.$store.dispatch('setTime', '');
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
            }
          }
        }

        this.hoursAreLoading = false;

        if (
          this.getMode === 'standard' &&
          this.filteredHoursForSelect.length
        ) {
          this.changeHourSelect(this.filteredHoursForSelect[0]);
        }

        if (!this.getHoursVisibility && this.getDate) {
          this.selectFirstAvailableHour();
        }

        setTimeout(() => {
          if (this.getMode !== 'full' && document.querySelector('#bookero-plugin .hours-section')) {
            if ('IntersectionObserver' in window) {
              let elementToObserve = document.querySelector('#bookero-plugin .hours-section');
              let hoursObserver = new IntersectionObserver((entries, observerInstance) => {
                entries.forEach(entry => {
                  if (entry.intersectionRatio < 0.2) {
                    elementToObserve.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
                  }

                  observerInstance.unobserve(entry.target);
                });
              }, { threshold: [0.2] });

              hoursObserver.observe(elementToObserve);
            }
          }
        }, 100);
      }).catch((e) => {
        console.log(e);
        this.lastLoadError = {
          checkTime: checkTime
        };
        this.hoursAreLoading = false;
      });
    },
    changeHour (time, isValid, freeWorkers) {
      if (isValid === 0) {
        return;
      }

      freeWorkers = freeWorkers.map(worker => worker.id);

      if (!this.getSelectedWorker && freeWorkers.length) {
        if (freeWorkers.length === 1) {
          let preselectedWorker = freeWorkers[0];
          this.$store.commit('setPreSelectedWorker', preselectedWorker);
        } else {
          let randomIndex = Math.floor(Math.random() * freeWorkers.length);
          let preselectedWorker = freeWorkers[randomIndex];
          this.$store.commit('setPreSelectedWorker', preselectedWorker);
        }
      } else {
        this.$store.commit('setPreSelectedWorker', 0);
      }

      this.$store.dispatch('setTime', {
        time: time,
        isWaitingList: isValid
      });
      this.$store.commit('removeError', 'hour');
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: time });
    },
    selectFirstAvailableHour () {
      if (!this.filteredHours[0] || (this.filteredHours[0] && !this.filteredHours[0].valid)) {
        return;
      }

      let isValid = this.filteredHours[0].valid;
      let time = this.filteredHours[0].hour;

      this.$store.dispatch('setTime', {
        time: time,
        isWaitingList: isValid
      });

      this.selectedHour = time;
      this.$store.commit('removeError', 'hour');
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time });
      this.$bus.$emit('bookero-plugin-hour-auto-select');
    },
    changeHourSelect (e) {
      let time = e.target ? e.target.value : e;
      let isValid = this.filteredHours.filter(hourItem => hourItem.hour === time)[0].valid;
      let availableWorkers = this.filteredHours.filter(hourItem => hourItem.hour === time)[0].free_workers;
      availableWorkers = availableWorkers.map(worker => worker.id);

      if (!this.getSelectedWorker && availableWorkers.length) {
        if (availableWorkers.length === 1) {
          let preselectedWorker = availableWorkers[0];
          this.$store.commit('setPreSelectedWorker', preselectedWorker);
        } else {
          let randomIndex = Math.floor(Math.random() * availableWorkers.length);
          let preselectedWorker = availableWorkers[randomIndex];
          this.$store.commit('setPreSelectedWorker', preselectedWorker);
        }
      } else {
        this.$store.commit('setPreSelectedWorker', 0);
      }

      this.$store.dispatch('setTime', {
        time: time,
        isWaitingList: isValid
      });

      this.selectedHour = time;
      this.$store.commit('removeError', 'hour');
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: time });
    },
    validate () {
      if (this.selectedServiceIsDailyService) {
        return;
      }

      if (!this.selectedServiceHasDates && !this.getFormState.time) {
        this.$store.commit('addError', 'hour');
      }
    },
    reload () {
      this.lastLoadError = false;
      this.getHours(true);
    },
    isInCart (hour) {
      return this.getCurrentlyBlockedHours(this.getDate, hour).length > 0;
    },
    isBlocked (hour) {
      return this.isInCart(hour) && this.getConfig.block_user_reservation && this.getConfig.require_mail && this.getConfig.customers_based_on === 'email';
    },
    hourLabelForSelect (hour) {
      if (typeof hour !== 'string') {
        return '';
      }

      if (this.filteredHours.filter(hourData => hourData.hour === hour)[0]) {
        return this.filteredHours.filter(hourData => hourData.hour === hour)[0].valid === -1 ? hour + ' - ' + this.getTranslation('waiting_list') : hour;
      }

      return '';
    },
    showTooltip (itemValid, refID) {
      if (itemValid === -1) {
        this.$bus.$emit('show-tooltip', this.getTranslation('waiting_list'), this.$refs[refID][0]);
      }
    },
    hideTooltip () {
      this.$bus.$emit('hide-tooltip');
    },
    reloadAfterError () {
      if (this.lastLoadError === false) {
        return;
      }

      this.getHours(this.lastLoadError.checkTime);
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);
    this.$bus.$off('bookero-plugin-reload-time', this.reload);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.hours-section {
  border-top: 1px solid $color-border;
  padding: 10px 0 20px 0;
  position: relative;
  scroll-margin-top: 80px;
  width: 100%;

  &-load-error {
    align-items: center;
    background: rgba(255, 255, 255, .9);
    display: none;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    &-label {
      color: $color-danger;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      width: 80%;
    }

    &-reload-link {
      color: $color-link;
      display: block;
      margin: 15px 0 0 0;
    }
  }

  &.has-error {
    .hours-section-load-error {
      display: flex;
    }
  }

  .bookero-plugin-form-heading {
    margin: 0 0 30px 0;
  }

  .is-loading {
    opacity: .5;
    pointer-events: none;
  }

  .hours-wrapper {
    position: relative;
    margin: 0 -8px;

    &[data-mode="standard"] {
      margin: 0;
    }

    &.is-loading {
      &:after {
        background: rgba(255, 255, 255, .5);
        content: "";
        height: 100%;
        position: absolute;
        top: 0;
        width: 100%;
      }

      &:before {
        animation: spinner 1s linear infinite;
        border: 2px solid $color-primary;
        border-top-color: $color-light!important;
        border-radius: $border-radius-full;
        content: "";
        display: block;
        height: 24px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 24px;
        z-index: 1;
      }
    }

    .hours-error {
      background-color: $color-light;
      border: 1px solid $color-danger;
      border-radius: $border-radius-small;
      color: $color-danger;
      font-weight: bold;
      margin: 5px 8px;
      padding: 10px;
      text-align: center;
    }

    .hours-list {
      display: flex;
      flex-wrap: wrap;

      &-item {
        background: $color-primary-light;
        border: 1px solid $color-primary-light;
        border-radius: $border-radius-small;
        color: $color-primary;
        cursor: pointer;
        font-size: 16px;
        font-weight: 500;
        margin: 8px;
        padding: 10px 2px;
        position: relative;
        text-align: center;
        white-space: nowrap;
        width: calc(14.28% - 16px);

        &-info {
          color: $color-text;
          display: block;
          font-size: 11px;
          font-weight: 400;
          opacity: .66;
          text-align: center;
          white-space: normal;

          /deep/ span {
            font-weight: bold;
          }
        }

        &.is-waiting-list {
          &:after {
            background: $color-danger;
            bottom: 3px;
            content: "";
            height: 2px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 75%;
          }
        }

        &.is-selected {
          background: $color-primary;
          border-color: $color-primary;
          color: $color-light;
          cursor: pointer;
          font-weight: bold;

          .hours-list-item-info {
            color: $color-light;
            opacity: 1;

            /deep/ span {
              color: $color-light;
            }
          }
        }

        &.is-in-cart {
          background: $color-light;
          border: 2px solid $color-primary;
          color: $color-primary;
          cursor: not-allowed;
          font-weight: bold;

          &.is-valid {
            cursor: pointer;
          }

          &.is-selected {
            background: $color-primary;
            border-color: $color-primary;
            color: $color-light;
            font-weight: bold;
          }
        }
      }

      &.wide-hours {
        .hours-list-item {
          width: calc((100% / 3) - 16px);
        }
      }
    }
  }
}

@keyframes spinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}

@media (max-width: 540px) {
  .hours-section {
    .hours-wrapper {
      .hours-list {
        &-item {
          padding: 10px 5px;
          width: calc((100% / 5) - 10px);
        }

        &.wide-hours {
          .hours-list-item {
            width: calc((100% / 2) - 16px);
          }
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .hours-section {
    .hours-wrapper {
      .hours-list {
        &-item {
          padding: 10px 5px;
          width: calc((100% / 3) - 10px);
        }

        &.wide-hours {
          .hours-list-item {
            width: 100%;
          }
        }
      }
    }
  }
}
</style>
