/* eslint-disable */
export default {
  'PLN': {
    'currencySymbol': 'zł',
    'format': '{value} {symbol}'
  },
  'EUR': {
    'currencySymbol': '€',
    'format': '{value} {symbol}'
  },
  'USD': {
    'currencySymbol': '$',
    'format': '{symbol} {value}'
  },
  'GBP': {
    'currencySymbol': '£',
    'format': '{symbol} {value}'
  }
};
