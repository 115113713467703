var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
  'submit-section': true,
  'is-active': _vm.isActive,
  'submit-section-cart-enabled': _vm.getConfig.cart_enabled,
  'submit-section-cart-is-empty': !_vm.getInquiries.length,
  'submit-section-cart-step-1': _vm.$parent.cartStep === 1,
  'submit-section-cart-step-2': _vm.$parent.cartStep === 2
}},[(_vm.step === 'cart-step-1')?[_c('button',{staticClass:"submit-button submit-button-step-1",attrs:{"disabled":_vm.getConfig.cart_enabled && !_vm.hasInquiries},on:{"click":function($event){$event.preventDefault();return _vm.goToStep(2)}}},[_vm._v("\n      "+_vm._s(_vm.getTranslation('go_to_summary'))+"\n    ")]),(_vm.getWaitingListInCurrentSelection)?_c('div',{staticClass:"add-to-cart-waiting-list-notice"},[_vm._v("\n      "+_vm._s(_vm.getTranslation('you_are_adding_waiting_list_inquiry'))+"\n    ")]):_vm._e()]:_vm._e(),(_vm.step === 'cart-step-2')?[_c('button',{class:{
        'submit-button': true,
        'submit-button-step-2': true,
        'is-blocked': _vm.isSending
      },attrs:{"disabled":_vm.isSending},on:{"click":function($event){$event.preventDefault();return _vm.submit($event)}}},[(_vm.hasPaymentMethods && _vm.getPrice !== '0.00' && !!_vm.getPrice)?[(_vm.selectedPaymentMethodIsCash || _vm.getWaitingListInInquiries)?[(!_vm.isSending)?[_vm._v("\n            "+_vm._s(_vm.getTranslation('send'))+"\n          ")]:[_vm._v("\n            "+_vm._s(_vm.getTranslation('sent'))+"\n          ")]]:[(!_vm.isSending)?[(_vm.getConfig.auto_accept)?[_vm._v("\n              "+_vm._s(_vm.getTranslation('summary_payment_button'))+"\n            ")]:[_vm._v("\n              "+_vm._s(_vm.getTranslation('send'))+"\n            ")]]:[_vm._v("\n            "+_vm._s(_vm.getTranslation('sent'))+"\n          ")]]]:[(!_vm.isSending)?[_vm._v("\n          "+_vm._s(_vm.getTranslation('send'))+"\n        ")]:[_vm._v("\n          "+_vm._s(_vm.getTranslation('sent'))+"\n        ")]]],2),_c('button',{staticClass:"back-button",on:{"click":function($event){$event.preventDefault();return _vm.goToStep(1)}}},[(_vm.getConfig.cart_enabled)?[_vm._v(_vm._s(_vm.getTranslation('back_to_cart')))]:[_vm._v(_vm._s(_vm.getTranslation('back_to_reservation')))]],2)]:_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }