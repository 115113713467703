export default class CalendarUtils {
  static getMonthsDiff (date1, date2) {
    let months = (date2.getFullYear() - date1.getFullYear()) * 12;
    months -= date1.getMonth() + 1;
    months += date2.getMonth();

    return months + 1;
  }

  static getWeeksDiff (date1, date2) {
    date1.setHours(0);
    date1.setMinutes(0);
    date1.setSeconds(0);
    date1.setMilliseconds(0);
    let diff = (date2.getTime() - date1.getTime()) / 1000;
    diff = diff / (60 * 60 * 24 * 6);

    return Math.floor(diff);
  }
}
