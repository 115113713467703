<script>
export default {
  name: 'CustomConfigHelpers',
  methods: {
    getInitializedCustomConfig () {
      let wrapper = document.querySelector('div[data-instance-id="' + this.$root.instanceID + '"]');
      return wrapper.getAttribute('data-initialized-custom-config').split(',');
    },
    setCustomConfigAsInitialized (name) {
      let wrapper = document.querySelector('div[data-instance-id="' + this.$root.instanceID + '"]');
      let params = wrapper.getAttribute('data-initialized-custom-config').split(',');
      params.push(name);
      params = params.join(',');
      wrapper.setAttribute('data-initialized-custom-config', params);
    }
  }
}
</script>
