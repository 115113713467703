<template>
  <div :class="{
    'params-section': true,
    'is-active': isActive
  }">
    <div
      :class="{ 'field': true, 'is-required': param.required, 'is-invalid': hasError('field-' + param.id) }"
      v-for="(param, index) of filteredParams"
      :key="'special-param-' + index">
      <label
        v-if="param.type !== 'infotext'"
        slot="label"
        v-pure-html="param.name"></label>

      <div
        v-if="param.type === 'infotext'"
        slot="field"
        class="field-desc" v-pure-html="param.name">
      </div>

      <input
        v-if="param.type === 'text'"
        v-model="paramsValues[param.id]"
        :key="'additional-param-field-' + param.id"
        slot="field" />

      <textarea
        v-if="param.type === 'textarea'"
        v-model="paramsValues[param.id]"
        :key="'additional-param-field-' + param.id"
        slot="field"></textarea>

      <select
        v-if="!getConfig.use_styled_dropdowns && param.type === 'select'"
        v-model="paramsValues[param.id]"
        slot="field"
        :key="'additional-param-field-' + param.type + '-' + param.id">
        <option :value="undefined">
          {{ getTranslation('choose_value') }}
        </option>
        <option
          v-for="(opt, oindex) of param.values"
          :key="'additional-param-field-' + param.id + '-option-' + oindex"
          :value="opt.id">
          {{ opt.name }}
        </option>
      </select>

      <v-select
        v-if="getConfig.use_styled_dropdowns && param.type === 'select'"
        v-model="paramsValues[param.id]"
        :options="filteredParamValues(param.values)"
        :custom-label="paramLabelByID"
        :placeholder="getTranslation('choose_value')"
        :allowEmpty="false"
        :showLabels="false"
        :searchable="false">
        <div slot="noOptions">
          {{ getTranslation('cant_find_option') }}
        </div>
      </v-select>

      <multi-switcher
        v-if="param.type === 'checkbox'"
        slot="field"
        v-model="paramsValues[param.id]"
        :key="'additional-param-field-' + param.id"
        :options="param.values" />

      <small
        v-if="hasError('field-' + param.id)"
        class="error-message">
        {{ getTranslation('the_parameter_is_required') }}
      </small>
    </div>
  </div>
</template>

<script>
import MultiSelect from 'vue-multiselect';
import MultiSwitcher from './../elements/MultiSwitcher.vue';
import Translations from './../mixins/Translations';
import Vue from 'vue';
import { mapGetters } from 'vuex';

export default {
  name: 'params',
  props: [
    'isActive',
    'paramsType'
  ],
  mixins: [
    Translations
  ],
  components: {
    'v-select': MultiSelect,
    'multi-switcher': MultiSwitcher
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getFormState',
      'getSelectedService',
      'getSpecialParams',
      'getParams',
      'hasError'
    ]),
    params () {
      if (this.paramsType === 'special') {
        return this.getSpecialParams;
      }

      return this.getParams;
    },
    filteredParams () {
      if (this.getSelectedService === 0) {
        return this.params.filter(param => {
          return !!param.is_global_parameter;
        });
      }

      return this.params.filter(param => {
        return !!param.is_global_parameter || param.connected_services.indexOf(this.getSelectedService) > -1;
      });
    }
  },
  watch: {
    'paramsValues': {
      handler: function (newValue) {
        let existingValues = Object.keys(newValue);

        for (let i = 0; i < existingValues.length; i++) {
          if (newValue[existingValues[i]] !== '' && this.hasError('field-' + existingValues[i])) {
            this.$store.commit('removeError', 'field-' + existingValues[i]);
          }
        }

        let payload = {
          type: this.paramsType,
          values: this.getData()
        };

        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:params-changed', { paramsType: this.paramsType, paramsValues: this.getData() });
        this.$store.commit('setParamValues', payload);
      },
      deep: true
    },
    'filteredParams': {
      handler: function (newValue) {
        if (!newValue.length) {
          this.$store.commit('setParamValues', {
            type: this.paramsType,
            values: []
          });
        }
      },
      deep: true
    }
  },
  data () {
    return {
      paramsValues: {}
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-validate', this.validate);
    this.$bus.$on('bookero-plugin-form-clear', this.setDefaultParamValues);
    this.$bus.$on('bookero-plugin-reset-params', this.resetParams);

    Vue.nextTick(() => {
      if (
        (this.paramsType === 'special' && Object.keys(this.getFormState.specialParamsValues).length) ||
        (this.paramsType === 'normal' && Object.keys(this.getFormState.paramsValues).length)
      ) {
        this.setParamsValues();
      } else {
        this.resetParams(true);
      }
    });
  },
  methods: {
    setDefaultParamValues (paramIDsToCheck = false) {
      let filteredParams = this.filteredParamsIds();
      let allNecessaryParams = this.filteredParamsIds();

      if (paramIDsToCheck) {
        filteredParams = [...filteredParams, ...paramIDsToCheck];
        filteredParams = [...new Set(filteredParams)];
      }

      for (let i = 0; i < this.params.length; i++) {
        let paramID = this.params[i].id;

        if (this.params[i].type === 'select' && this.params[i].values && this.params[i].values[0]) {
          if (filteredParams.indexOf(paramID) > -1 && this.params[i].first_selected) {
            let paramValue = this.params[i].values[0].id;
            Vue.set(this.paramsValues, paramID, paramValue);
          }
        }

        if (window.bookero_default_values && window.bookero_default_values.params && window.bookero_default_values.params[paramID]) {
          if (allNecessaryParams.indexOf(paramID) > -1) {
            let paramValue = window.bookero_default_values.params[paramID];
            Vue.set(this.paramsValues, paramID, paramValue);
          }
        }
      }
    },
    setParamsValues () {
      let newParamsValues = {};
      let paramsKey = this.paramsType === 'special' ? 'specialParamsValues' : 'paramsValues';
      let existingParamsValues = this.getFormState[paramsKey];

      for (let i = 0; i < existingParamsValues.length; i++) {
        if (existingParamsValues[i].value_id === null) {
          newParamsValues[existingParamsValues[i].id] = existingParamsValues[i].value;
        } else {
          newParamsValues[existingParamsValues[i].id] = existingParamsValues[i].value_id;
        }
      }

      Vue.set(this, 'paramsValues', JSON.parse(JSON.stringify(newParamsValues)));
    },
    filteredParamValues (paramValues) {
      if (!paramValues) {
        return [];
      }

      return paramValues.map(value => value.id);
    },
    filteredParamsIds () {
      return this.filteredParams.map(param => param.id);
    },
    paramLabelByID (valueID) {
      if (valueID === null) {
        return this.getTranslation('choose_value');
      }

      return this.params.filter(param => param.type === 'select' && param.values && param.values.map(val => val.id).indexOf(valueID) > -1)[0].values.filter(val => val.id === valueID)[0].name;
    },
    getData () {
      let paramsObject = [];
      let paramIDs = Object.keys(this.paramsValues);

      for (let i = 0; i < paramIDs.length; i++) {
        let param = {
          id: paramIDs[i],
          value: '',
          value_id: null
        };
        let paramType = this.typeOfParamByID(paramIDs[i]);

        switch (paramType) {
          case 'text':
          case 'textarea':
            param.value = this.paramsValues[paramIDs[i]];
            break;
          case 'select':
            param.value = this.getSelectParamValue(paramIDs[i], this.paramsValues[paramIDs[i]]);
            param.value_id = this.paramsValues[paramIDs[i]];

            if (param.value === '') {
              continue;
            }

            break;
          case 'checkbox':
            param.value = this.getCheckboxParamValues(paramIDs[i], this.paramsValues[paramIDs[i]]);
            param.value_id = this.paramsValues[paramIDs[i]];
            break;
          case 'infotext':
            continue;
        }

        paramsObject.push(param);
      }

      return paramsObject;
    },
    typeOfParamByID (paramID) {
      return this.params
        .filter(param => param.id === parseInt(paramID, 10))[0].type;
    },
    getSelectParamValue (paramID, valueID) {
      if (!valueID) {
        return '';
      }

      return this.params
        .filter(param => param.id === parseInt(paramID, 10))[0].values
        .filter(value => value.id === parseInt(valueID, 10))[0].name;
    },
    getCheckboxParamValues (paramID, valueIDs) {
      let values = [];
      let valuesData = this.params.filter(param => param.id === parseInt(paramID, 10))[0].values;

      for (let i = 0; i < valuesData.length; i++) {
        if (valueIDs.indexOf(valuesData[i].id) > -1) {
          values.push(valuesData[i].name);
        }
      }

      return values;
    },
    validate () {
      if (!this.filteredParams || !this.filteredParams.length) {
        return;
      }

      for (let i = 0; i < this.filteredParams.length; i++) {
        let paramID = this.filteredParams[i].id;

        if (!this.filteredParams[i].required) {
          continue;
        }

        if (
          this.paramsValues[paramID] &&
          (
            (Array.isArray(this.paramsValues[paramID]) && this.paramsValues[paramID].length) ||
            (!Array.isArray(this.paramsValues[paramID]) && !!this.paramsValues[paramID])
          )
        ) {
          continue;
        } else {
          this.$store.commit('addError', 'field-' + paramID);
        }
      }
    },
    resetParams (fullReset = false) {
      if (fullReset) {
        this.paramsValues = {};
        this.setDefaultParamValues();
      } else {
        let assignedParamIds = this.filteredParamsIds();
        let paramIDs = Object.keys(this.paramsValues);
        let resetedParamIDs = [];

        for (let i = 0; i < paramIDs.length; i++) {
          let paramID = parseInt(paramIDs[i], 10);
          // if param is not assigned to the selected service - reset it
          if (assignedParamIds.indexOf(paramID) === -1) {
            Vue.delete(this.paramsValues, paramID);
            resetedParamIDs.push(paramID);
          }
        }

        this.setDefaultParamValues(resetedParamIDs);
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);
    this.$bus.$off('bookero-plugin-form-clear', this.setDefaultParamValues);
    this.$bus.$off('bookero-plugin-reset-params', this.resetParams);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.params-section {
  width: 100%;

  &:not(.is-active) {
    opacity: .25;
    pointer-events: none;
  }

  .field {
    margin: 0;
    padding: 10px 0;

    &:first-child {
      margin: 10px 0 0 0;
    }

    &:last-child {
      margin: 0 0 10px 0;
    }
  }
}
</style>
