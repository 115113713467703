<template>
  <div class="multi-switcher">
    <div
      v-for="(option, index) of options"
      class="multi-switcher-item"
      :key="'multi-switcher-option-' + index">
      <label>
        <switcher
          :value="selectedValues.indexOf(option.id) !== -1"
          :label="option.name"
          @click.native="toggleSwitcher(option.id)" />
      </label>
    </div>
  </div>
</template>

<script>
import Switcher from './Switcher.vue';

export default {
  name: 'multi-switcher',
  components: {
    'switcher': Switcher
  },
  props: {
    options: {
      type: Array
    },
    value: {
      default: () => [],
      type: Array
    }
  },
  data () {
    return {
      selectedValues: []
    };
  },
  watch: {
    value (newValue, oldValue) {
      this.selectedValues = newValue.slice();
    }
  },
  mounted () {
    this.selectedValues = this.value.slice();
  },
  methods: {
    toggleSwitcher (value) {
      if (this.selectedValues.indexOf(value) === -1) {
        this.selectedValues.push(value);
      } else {
        let indexToRemove = this.selectedValues.indexOf(value);
        this.selectedValues.splice(indexToRemove, 1);
      }

      this.$emit('input', this.selectedValues);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../assets/scss/variables.scss';

.multi-switcher {
  width: 100%;

  &-item {
    align-items: center;
    display: flex;
    padding: 0;

    & > label {
      align-items: center;
      display: flex!important;
      font-weight: 400!important;
      width: 100%;

      .switcher {
        margin: 0 10px 0 0;
      }

      span {
        width: calc(100% - 50px);
      }

      small {
        color: $color-text;
        margin: 0 0 0 10px;
      }
    }
  }
}
</style>
