<template>
  <div
    :data-error-text="getTranslation('price_load_error')"
    :class="{
      'payment-summary-section': true,
      'is-loading': priceIsLoading,
      'is-error': !priceIsLoading && priceLoadError
    }"
    @click="reloadPrice()">
    <div
      v-if="showDiscountCodeCalculations && getDiscountCodeStatus === 'valid'"
      class="payment-summary-section-discount">
      <p class="payment-summary-section-discount-price-before">
        <span>{{ getTranslation('summary_cost') }}:</span>
        <span>{{ priceBeforeDiscount }}</span>
      </p>
      <p class="payment-summary-section-discount-quote">
        <span>{{ getTranslation('discount_code') }}:</span>
        <span>{{ priceAfterDiscount }}</span>
      </p>
    </div>

    <p
      v-if="getFinalPrice().replace(/[^0-9,.]/gmi, '') !== '0,00' || getDiscountCodeStatus === 'valid'"
      class="payment-summary-quote">
      {{ getTranslation('summary_total_cost') }}:
      <strong class="payment-summary-price">
        {{ getFinalPrice() }}
      </strong>
    </p>
  </div>
</template>

<script>
import CurrencyUtils from './../helpers/CurrencyUtils';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'payment-summary',
  props: {
    showOnlyPrice: {
      default: false
    },
    showDiscountCodeCalculations: {
      default: false
    },
    priceLoadError: {
      defaulf: false
    }
  },
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'getDiscountCodeStatus',
      'getPriceBeforeDiscount',
      'getPriceAfterDiscount',
      'getPrice',
      'getCurrency'
    ]),
    priceBeforeDiscount () {
      return CurrencyUtils.getQuote(Number(this.getPriceBeforeDiscount).toFixed(2), this.getCurrency);
    },
    priceAfterDiscount () {
      return '-' + CurrencyUtils.getQuote(Number(this.getPriceBeforeDiscount - this.getPriceAfterDiscount).toFixed(2), this.getCurrency);
    }
  },
  data () {
    return {
      priceIsLoading: false
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-get-price-start', this.getPriceStart);
    this.$bus.$on('bookero-plugin-get-price-end', this.getPriceEnd);
  },
  methods: {
    getFinalPrice () {
      return CurrencyUtils.getQuote(this.getPrice, this.getCurrency);
    },
    getPriceStart () {
      this.priceIsLoading = true;
    },
    getPriceEnd () {
      this.priceIsLoading = false;
    },
    reloadPrice () {
      if (this.priceLoadError) {
        this.priceIsLoading = true;
        this.$bus.$emit('bookero-plugin-get-price-reload');
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-get-price-start', this.getPriceStart);
    this.$bus.$off('bookero-plugin-get-price-end', this.getPriceEnd);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.payment-summary-section {
  border-top: 1px solid $color-border;
  margin: 30px 0 0 0;
  padding: 30px 0 20px 0;
  position: relative;
  width: 100%;

  &.is-loading {
    &:after {
      background: rgba(255, 255, 255, .95);
      content: "";
      height: 100%;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:before {
      animation: spinner 1s linear infinite;
      border: 2px solid $color-primary;
      border-top-color: $color-light!important;
      border-radius: $border-radius-full;
      content: "";
      display: block;
      height: 24px;
      left: 50%;
      position: absolute;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 24px;
      z-index: 1;
    }
  }

  &.is-error {
    &:after {
      background: rgba(255, 255, 255, .95);
      content: "";
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }

    &:before {
      color: $color-danger;
      content: attr(data-error-text);
      display: block;
      height: auto;
      left: 50%;
      line-height: 18px;
      position: absolute;
      text-align: center;
      top: 50%;
      transform: translateX(-50%) translateY(-50%);
      width: 100%;
      z-index: 1;
    }
  }

  &-discount {
    border-bottom: 1px solid $color-border;
    margin: 0 0 30px 0;
    padding: 0 0 30px 0;

    p {
      font-size: 16px;
      margin: 0;
      padding: 4px 0;
      text-align: right;

      & > span:last-child {
        display: inline-block;
        width: 120px;
      }
    }

    &-quote {
      & > span:last-child {
        color: $color-success;
      }
    }
  }

  .payment-summary {
    &-quote {
      font-size: 16px;
      margin: 0;
      padding: 0;
      text-align: right;
    }

    &-price {
      color: $color-text-dark;
      font-size: 32px;
      font-weight: 500;
    }
  }
}

@keyframes spinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}
</style>
