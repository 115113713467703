<template>
  <div :class="{ 'debug': true, 'is-visible': isVisible }">
    <h2 @click="showDebug">Ustawienia</h2>
    <div class="debug-fields">
      <div class="debug-field">
        <label>Tryb działania wtyczki:</label>
        <select
          v-model="mode"
          @change="setOption('mode')">
          <option value="standard">Inline Box</option>
          <option value="calendar">Monthly Box</option>
          <option value="full">Weekly Box</option>
          <option value="sticky">Sticky Box</option>
        </select>
      </div>

      <button
        @click="refreshPlugin">
        Odśwież wtyczkę
      </button>

      <button
        @click="closeDebug">
        Zamknij
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Debug',
  data () {
    return {
      isVisible: false,
      mode: this.getValue('mode', 'standard')
    };
  },
  methods: {
    refreshPlugin () {
      window.location.reload();
    },
    showDebug () {
      this.isVisible = true;
    },
    closeDebug () {
      this.isVisible = false;
    },
    getValue (optionName, defaultValue) {
      let lsOptionValue = localStorage.getItem('bookero-plugin-option-' + optionName);

      if (lsOptionValue !== null) {
        if (lsOptionValue === 'true') {
          return true;
        }

        if (lsOptionValue === 'false') {
          return false;
        }

        return lsOptionValue;
      }

      return defaultValue;
    },
    setOption (optionName) {
      localStorage.setItem('bookero-plugin-option-' + optionName, this[optionName]);
    }
  }
};
</script>

<style scoped lang="scss">
.debug {
  background: #222;
  bottom: 0;
  left: 0;
  position: fixed;
  z-index: 10000;

  h2 {
    color: #fff;
    cursor: pointer;
    font-size: 20px;
    font-weight: normal;
    margin: 0;
    padding: 10px 20px;
  }

  &.is-visible {
    background: #fff;
    height: 100%;
    left: 0;
    overflow: auto;
    top: 0;
    width: 100%;

    h2 {
      color: #222;
      margin: 20px auto;
      padding: 0;
      width: 720px;
    }

    .debug-fields {
      display: block;
    }
  }

  &-fields {
    display: none;
    margin: 0 auto;
    width: 720px;
  }

  &-field {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    margin: 5px 0;

    span {
      margin-left: auto;
      width: calc(100% - 50px);
    }

    label,
    select {
      width: 100%;
    }

    label {
      margin-bottom: 5px;
    }

    select {
      height: 30px;
      margin-bottom: 10px;
    }
  }

  button {
    background: #5a5;
    border: none;
    color: #fff;
    cursor: pointer;
    font-size: 16px;
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 5px 10px;

    & + button {
      background: #a55;
      margin-left: 10px;
    }
  }
}
</style>
