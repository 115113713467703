<template>
  <div class="inquiry">
    <div class="inquiry-heading">
      <h3 class="inquiry-title">
        <template v-if="cartEnabled && !isWaitingListItem">
          {{ getTranslation('cart_order') }} {{ listIndex + 1 }}
        </template>
        <template v-if="isWaitingListItem">
          {{ getTranslation('reserve_list_header') }}
        </template>
        <template v-if="!cartEnabled && !isWaitingListItem">
          {{ getTranslation('cart_your_order') }}
        </template>
      </h3>
      <div
        v-if="showPaymentsInSummary && hasPaymentMethods && getInquiryPrice(this.inquiryData.plugin_inquiry_id) && inquiryPrice.replace(/[^0-9,.]/gmi, '') !== '0,00'"
        class="inquiry-price">
        {{ inquiryPrice }}
      </div>
    </div>
    <small
      v-if="getServicePeriodicityPaymentType(this.inquiryData.service)"
      class="inquiry-single-reservation-price">
      {{ getTranslation('price_for_single_reservation') }}
    </small>
    <div class="inquiry-datetime">
      <span class="inquiry-label">
        <template v-if="inquiryData.connectedTermins && inquiryData.connectedTermins.length">
          {{ getTranslation('calendar_terms') }}:
        </template>
        <template v-else>
          {{ getTranslation('term') }}:
        </template>
      </span>
      {{ inquiryData.date }}
      <template v-if="inquiryUseDailyService && inquiryData.days">
        &mdash; {{ calculateEndDate(inquiryData) }}
      </template>
      <template v-if="!inquiryUseDailyService && getHoursVisibility">
        {{ getTranslation('cart_inquiry_hour') }} {{ inquiryData.hour }}
      </template>
      <span
        v-if="inquiryData.connectedTermins && inquiryData.connectedTermins.length"
        class="inquiry-connected-termins">
        {{ getConnectedTerminsLabel(inquiryData.connectedTermins) }}
        <span class="inquiry-connected-termins-popup">
          <span
            v-for="(termin, index) of inquiryData.connectedTermins"
            :key="'connected-termin-' + index"
            class="inquiry-connected-termins-popup-item">
            <template v-if="getHoursVisibility">
              {{ termin.start_at.replace(' ', ' ' + getTranslation('cart_inquiry_hour') + ' ') }}
            </template>
            <template v-else>
              {{ termin.start_at.split(' ')[0] }}
            </template>
          </span>
        </span>
      </span>
    </div>
    <div
      v-if="inquiryData.service"
      class="inquiry-service">
      <span class="inquiry-label">
        <template v-if="getServiceCategoryNameByServiceID(inquiryData.service)">
          {{ getServiceCategoryNameByServiceID(inquiryData.service) }}:
        </template>
        <template v-else>
          {{ getTranslation('cart_inquiry_service') }}:
        </template>
      </span>

      {{ getServiceName(inquiryData.service) }}
    </div>
    <div
      v-if="inquiryData.custom_duration_id"
      class="inquiry-custom-duration">
      <span class="inquiry-label">
        {{ getTranslation('custom_duration_label') }}:
      </span>

      {{ getCustomDurationLabel(inquiryData.service, inquiryData.custom_duration_id) }}
    </div>
    <div
      v-if="!inquiryUseDailyService && hasWorkers && (inquiryData.worker || inquiryData.worker === 0) && !getCustomConfigField('hide_worker_info')"
      class="inquiry-worker">
      <span class="inquiry-label">{{ getTranslation('cart_inquiry_worker') }}:</span>
      {{ getWorkerName(inquiryData.worker) }}
    </div>
    <div class="inquiry-actions">
      <a
        href="#"
        class="inquiry-actions-details"
        @click.prevent="showDetails(listIndex + 1)">
        {{ getTranslation('show_the_details') }}
      </a>
      <a
        v-if="isEditable"
        href="#"
        @click.prevent="removeInquiry(listIndex)"
        :class="{ 'inquiry-actions-delete': true, 'is-blocked': isRemoving }">
        <template v-if="!isRemoving">
          {{ getTranslation('cart_remove') }}
        </template>
        <template v-if="isRemoving">
          {{ getTranslation('cart_removing') }}
        </template>
      </a>
    </div>
  </div>
</template>

<script>
import CurrencyUtils from './../helpers/CurrencyUtils';
import Tracking from './../mixins/Tracking.vue';
import Translations from './../mixins/Translations.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'Inquiry',
  props: [
    'cartEnabled',
    'isEditable',
    'inquiryData',
    'listIndex'
  ],
  mixins: [
    Tracking,
    Translations
  ],
  computed: {
    ...mapGetters([
      'getInquiries',
      'getServiceCategoryNameByServiceID',
      'getServiceCustomLabel',
      'getServiceName',
      'getWorkerName',
      'getInquiryPrice',
      'getCurrency',
      'hasPaymentMethods',
      'hasWorkers',
      'showPaymentsInSummary',
      'getHoursVisibility',
      'getCustomDurationLabel',
      'getServices',
      'getCustomConfigField',
      'getServicePeriodicityPaymentType'
    ]),
    inquiryPrice () {
      return CurrencyUtils.getQuote(this.getInquiryPrice(this.inquiryData.plugin_inquiry_id), this.getCurrency);
    },
    isWaitingListItem () {
      return this.inquiryData.plugin_inquiry_id === 0;
    },
    inquiryUseDailyService () {
      let serviceID = this.inquiryData.service;
      let services = this.getServices;
      let usedService = services.filter(service => service.id === serviceID);

      if (usedService[0]) {
        return usedService[0].available_in_dates === 3;
      }

      return false;
    }
  },
  data () {
    return {
      isRemoving: false
    };
  },
  methods: {
    showDetails (listIndex) {
      this.$bus.$emit('bookero-plugin-show-details-popup', this.inquiryData, listIndex);
    },
    removeInquiry (index) {
      if (this.isRemoving) {
        return;
      }

      if (!this.inquiryData.plugin_inquiry_id) {
        this.$store.commit('removeInquiry', this.inquiryData.plugin_inquiry_id);

        if (!this.getInquiries.length) {
          this.$bus.$emit('bookero-plugin-show-inquiry-form');
        }

        return;
      }

      let pluginConfig = this.$store.state.pluginConfig;
      this.isRemoving = true;

      this.$http.post(this.$endpointURL + '/unblockTermin', {
        bookero_id: pluginConfig.id,
        plugin_inquiry_id: this.inquiryData.plugin_inquiry_id
      }).then(res => {
        if (res.data.result === 1) {
          this.$store.commit('removeInquiry', this.inquiryData.plugin_inquiry_id);
          // WAITS FOR ISSUE #43
          // this.$store.commit('removeCurrentlyBlockedTermin', this.inquiryData.plugin_inquiry_id);
          this.$bus.$emit('bookero-plugin-get-price');
          this.isRemoving = false;
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:inquiry-deleted', { inquiryID: this.inquiryData.plugin_inquiry_id });
          this.$bus.$emit('bookero-plugin-reload-time');
          this.trackingRemoveFromCart();

          if (!this.getInquiries.length) {
            this.$bus.$emit('bookero-plugin-show-inquiry-form');
          }
        } else {
          this.isRemoving = false;
          this.$bus.$emit('bookero-plugin-show-alert-popup', {
            title: this.getTranslation('validation_error_has_occurred'),
            desc: this.getTranslation('validation_remove_from_cart_failed')
          });
        }
      }).catch(() => {
        this.isRemoving = false;
        this.$bus.$emit('bookero-plugin-show-alert-popup', {
          title: this.getTranslation('validation_error_has_occurred'),
          desc: this.getTranslation('validation_remove_from_cart_failed')
        });
      });
    },
    getConnectedTerminsLabel (connectedTermins) {
      let count = connectedTermins.length;

      if (count === 1) {
        return this.getTranslation('periodicity_and_one_more');
      }

      if (
        count % 10 >= 2 &&
        count % 10 <= 4 &&
        (
          count % 100 <= 10 ||
          count % 100 >= 20
        )
      ) {
        return this.getTranslation('periodicity_and_s_more').replace('%s', count);
      }

      return this.getTranslation('periodicity_and_n_more').replace('%s', count);
    },
    calculateEndDate (inquiryData) {
      let serviceEndsInTheNextDay = false;
      let serviceData = this.getServices.filter(service => service.id === inquiryData.service);
      let daysOffset = inquiryData.days;
      let startDateParts = inquiryData.date.split('-').map(n => parseInt(n, 10));

      if (serviceData && serviceData[0] && serviceData[0].daily_end_available === 1) {
        serviceEndsInTheNextDay = true;
      }

      if (!serviceEndsInTheNextDay) {
        daysOffset--;
      }

      let endDate = new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2], 12, 0, 0, 0);
      endDate.setDate(endDate.getDate() + daysOffset);

      return [endDate.getFullYear(), endDate.getMonth() + 1, endDate.getDate()].map(n => n < 10 ? '0' + n : n).join('-');
    }
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.inquiry {
  background: $color-light;
  border: 1px solid $color-border;
  border-radius: $border-radius-small;
  color: $color-text;
  display: flex;
  flex-wrap: wrap;
  font-size: 14px;
  margin: 20px 0 0 0;
  padding: 20px 25px;

  &:first-child {
    margin: 0;
  }

  & > div {
    padding: 3px 0;
  }

  &-label {
    font-weight: 600;
  }

  &-datetime,
  &-service,
  &-worker,
  &-custom-duration {
    font-size: 14px;
    margin: 0 0 3px 0;
    width: 100%;
  }

  &-heading {
    align-items: center;
    display: flex;
    margin: 0 0 10px 0;
    width: 100%;
  }

  &-title {
    font-size: 24px;
    font-weight: 400;
    margin: 0;
    width: 75%;
  }

  &-price {
    font-size: 24px;
    text-align: right;
    width: 25%;
  }

  &-actions {
    display: flex;
    font-size: 13px;
    font-weight: 500;
    margin: 15px 0 0 0;
    width: 100%;

    &-details {
      letter-spacing: 0;
      padding-right: 10px;
      position: relative;
      text-transform: none;

      &:after {
        border-top: 2px solid $color-primary;
        border-right: 2px solid $color-primary;
        content: "";
        display: block;
        height: 5px;
        position: absolute;
        right: 0;
        top: 50%;
        transition: all .3s ease-out;
        transform: rotate(45deg) translateY(-60%);
        width: 5px;
      }

      &:hover {
        &:after {
          border-top-color: darken($color-primary, 15%);
          border-right-color: darken($color-primary, 15%);
          transform:  translateX(3px) rotate(45deg) translateY(-60%);
        }
      }
    }

    &-delete {
      color: $color-danger!important;
      letter-spacing: 0;
      margin: 0 0 0 auto;
      text-transform: none;

      &:active,
      &:focus,
      &:hover {
        color: darken($color-danger, 15%);
      }

      &.is-blocked {
        position: relative;
        pointer-events: none;

        &:before {
          animation: spinner 1s infinite;
          border: 2px solid $color-danger;
          border-top-color: $color-light!important;
          border-radius: 50%;
          content: "";
          display: block;
          height: 8px;
          left: -20px;
          position: absolute;
          top: calc(50% - 5px);
          width: 8px;
        }
      }
    }
  }

  &-single-reservation-price {
    display: block;
    margin-bottom: 5px;
    margin-top: -15px;
    text-align: right;
    width: 100%;
  }

  &-connected-termins {
    cursor: pointer;
    color: $color-primary;
    display: inline-block;
    font-weight: 500;
    position: relative;

    &-popup {
      background: $color-light;
      border-radius: $border-radius-small;
      box-shadow: 0 4px 8px rgba(0, 0, 0, .15);
      display: block;
      font-weight: 300;
      left: 50%;
      opacity: 0;
      pointer-events: none;
      padding: 3px 0;
      position: absolute;
      text-decoration: none;
      top: 20px;
      transform: translateX(-50%) translateY(10px);
      transition: all .25s ease-out;
      width: 260px;
      z-index: 1;

      &-item {
        border-top: 1px solid $color-border;
        color: $color-text;
        display: block;
        padding: 6px 0;
        text-align: center;
        text-decoration: none;

        &:first-child {
          border-top: none;
        }
      }
    }

    &:hover {
      .inquiry-connected-termins-popup {
        opacity: 1;
        pointer-events: auto;
        transform: translateX(-50%) translateY(0);
      }
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 540px) {
  .inquiry {
    padding: 10px 15px;

    &-datetime,
    &-price,
    &-service,
    &-worker {
      width: 100%;
    }

    &-title,
    &-price {
      font-size: 16px;
      font-weight: bold;
      width: auto;
    }

    &-price {
      margin: 0 0 0 auto;
    }
  }
}
</style>
