var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.selectedServiceRequiresPeopleNumber())?_c('div',{class:{
    'people-section': true,
    'is-active': _vm.isActive,
    'is-without-children-field': !_vm.selectedServiceRequiresChildren && _vm.selectedServiceIsDailyService
  }},[_c('div',{staticClass:"w-50"},[_c('div',{class:{
      'field': true,
      'is-required': true,
      'is-invalid': _vm.hasError('people-number')
    }},[_c('label',{directives:[{name:"pure-html",rawName:"v-pure-html",value:(_vm.getTranslation('people_count')),expression:"getTranslation('people_count')"}],attrs:{"for":"bookero-plugin-people-number"}}),_c('div',{staticClass:"people-number-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.peopleNumber),expression:"peopleNumber"}],attrs:{"type":"number","id":"bookero-plugin-people-number","min":_vm.minPeople,"max":_vm.maxPeople,"step":"1"},domProps:{"value":(_vm.peopleNumber)},on:{"change":function($event){return _vm.emitChange()},"input":function($event){if($event.target.composing){ return; }_vm.peopleNumber=$event.target.value}}}),_c('div',{staticClass:"people-number-plus",on:{"click":function($event){return _vm.increasePeople()}}},[_vm._v("+")]),_c('div',{staticClass:"people-number-minus",on:{"click":function($event){return _vm.decreasePeople()}}},[_vm._v("–")])])]),(_vm.hasError('people-number'))?_c('div',{staticClass:"error-message"},[_vm._v("\n      "+_vm._s(_vm.getTranslation('validation_wrong_people_number'))+"\n    ")]):_vm._e(),(_vm.servicePeopleRange)?_c('small',{staticClass:"field-note"},[_vm._v("\n        "+_vm._s(_vm.servicePeopleRange)+"\n    ")]):_vm._e()]),_c('div',{staticClass:"w-50"},[(_vm.selectedServiceRequiresChildren)?_c('div',{class:{
        'field': true,
        'is-required': true,
        'is-invalid': _vm.hasError('children-number')
      }},[_c('label',{directives:[{name:"pure-html",rawName:"v-pure-html",value:(_vm.getTranslation('children_count')),expression:"getTranslation('children_count')"}],attrs:{"for":"bookero-plugin-children-number"}}),_c('div',{staticClass:"children-number-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.childrenNumber),expression:"childrenNumber"}],attrs:{"type":"number","id":"bookero-plugin-children-number","min":0,"step":"1"},domProps:{"value":(_vm.childrenNumber)},on:{"change":function($event){return _vm.emitChange()},"input":function($event){if($event.target.composing){ return; }_vm.childrenNumber=$event.target.value}}}),_c('div',{staticClass:"children-number-plus",on:{"click":function($event){return _vm.increaseChildren()}}},[_vm._v("+")]),_c('div',{staticClass:"children-number-minus",on:{"click":function($event){return _vm.decreaseChildren()}}},[_vm._v("–")])])]):_vm._e(),(_vm.hasError('children-number'))?_c('div',{staticClass:"error-message"},[_vm._v("\n      "+_vm._s(_vm.getTranslation('validation_wrong_children_number'))+"\n    ")]):_vm._e()])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }