<script>
import { mapGetters } from 'vuex';

export default {
  name: 'Tracking',
  computed: {
    ...mapGetters([
      'getInquiries',
      'fbIntegrationEnabled',
      'gaIntegrationEnabled'
    ])
  },
  data () {
    return {
      debugMode: false,
      tracker: null
    };
  },
  mounted () {
    if ('ga' in window && 'getAll' in window.ga) {
      this.tracker = window.ga.getAll()[0];
    }
  },
  methods: {
    trackingDisplay () {
      this.$root.dispatchEvent('bookero-plugin:tracking:form-loaded');

      if (this.debugMode) console.log('🛠 GA INTEGRATION ENABLED:', this.gaIntegrationEnabled ? '✅' : '⛔️');
      if (this.debugMode) console.log('🛠 FB INTEGRATION ENABLED:', this.fbIntegrationEnabled ? '✅' : '⛔️');

      if (this.gaIntegrationEnabled && 'gtag' in window) {
        window.gtag('event', 'view_cart');
        if (this.debugMode) console.log('🟢 GTAG SEND:', 'event', 'view_cart');
      }
    },
    trackingAddToCart () {
      this.$root.dispatchEvent('bookero-plugin:tracking:add-to-cart');

      if (this.fbIntegrationEnabled && 'fbq' in window) {
        window.fbq('track', 'AddToCart');
        if (this.debugMode) console.log('🟢 FBQ SEND', 'track', 'AddToCart');
      }

      if (this.gaIntegrationEnabled && 'gtag' in window) {
        window.gtag('event', 'add_to_cart');
        if (this.debugMode) console.log('🟢 GTAG SEND:', 'event', 'add_to_cart');
      }
    },
    trackingRemoveFromCart () {
      this.$root.dispatchEvent('bookero-plugin:tracking:remove-from-cart');

      if (this.gaIntegrationEnabled && 'gtag' in window) {
        window.gtag('event', 'remove_from_cart');
        if (this.debugMode) console.log('🟢 GTAG SEND:', 'event', 'remove_from_cart');
      }
    },
    trackingSubmitReservation () {
      this.$root.dispatchEvent('bookero-plugin:tracking:start-checkout');

      if (!this.getConfig.cart_enabled) {
        this.$parent.trackingTemporaryData = {
          cartItems: [{
            id: 's-' + (+new Date()),
            serviceName: this.getServiceName(this.getFormState.selectedService),
            sku: 'service-' + this.getFormState.selectedService,
            price: this.getPrice
          }],
          totalPrice: this.getPrice
        };
      } else {
        let inquiries = JSON.parse(JSON.stringify(this.getInquiries));

        this.$parent.trackingTemporaryData = {
          cartItems: [],
          totalPrice: 0
        };

        for (let i = 0; i < inquiries.length; i++) {
          let serviceName = this.getServiceName(inquiries[i].service);
          let findPreviousDuplicates = this.$parent.trackingTemporaryData.cartItems.filter(item => item.serviceName === serviceName).length;
          let skuSuffix = '';

          if (findPreviousDuplicates > 0) {
            skuSuffix = '-' + (findPreviousDuplicates + 1);
          }

          this.$parent.trackingTemporaryData.cartItems.push({
            id: this.getCartID,
            inquiryID: inquiries[i].plugin_inquiry_id,
            sku: 'service-' + this.getFormState.selectedService + skuSuffix,
            serviceName: serviceName,
            price: this.getInquiryPrice(inquiries[i].plugin_inquiry_id)
          });

          this.$parent.trackingTemporaryData.totalPrice += parseFloat(this.getInquiryPrice(inquiries[i].plugin_inquiry_id));
        }
      }

      if (this.fbIntegrationEnabled && 'fbq' in window) {
        window.fbq('track', 'InitiateCheckout');
        if (this.debugMode) console.log('🟢 FBQ SEND', 'track', 'InitiateCheckout');
      }

      if (this.gaIntegrationEnabled && 'gtag' in window) {
        window.gtag('event', 'begin_checkout');
        if (this.debugMode) console.log('🟢 GTAG SEND:', 'event', 'begin_checkout');
      }
    },
    trackingPurchase (cartID, value, currency) {
      if (this.fbIntegrationEnabled && 'fbq' in window) {
        window.fbq('track', 'Purchase', {
          value: value,
          currency: currency
        });

        if (this.debugMode) {
          console.log('🟢 FBQ SEND', 'track', 'Purchase', {
            value: value,
            currency: currency
          });
        }
      }

      this.$root.dispatchEvent('bookero-plugin:tracking:purchase', {
        value: value,
        currency: currency,
        cartItems: JSON.parse(JSON.stringify(this.$parent.trackingTemporaryData.cartItems))
      });

      if (this.gaIntegrationEnabled && 'gtag' in window) {
        let items = [];

        for (let i = 0; i < this.$parent.trackingTemporaryData.cartItems.length; i++) {
          let itemData = this.$parent.trackingTemporaryData.cartItems[i];

          items.push({
            'item_id': itemData.id,
            'item_name': itemData.serviceName,
            'price': Number(itemData.price),
            'quantity': 1,
            'currency': currency
          });
        }

        window.gtag('event', 'purchase', {
          transaction_id: this.$parent.trackingTemporaryData.cartItems[0].id,
          affiliation: 'Bookero plugin',
          value: Number(this.$parent.trackingTemporaryData.totalPrice).toFixed(2),
          currency: currency,
          items: items
        });

        if (this.debugMode) {
          console.log('🟢 GTAG SEND:', 'event', 'purchase', {
            transaction_id: this.$parent.trackingTemporaryData.cartItems[0].id,
            affiliation: 'Bookero plugin',
            value: Number(this.$parent.trackingTemporaryData.totalPrice).toFixed(2),
            currency: currency,
            items: items
          });
        }
      }
    },
    trackingFailedPurchase () {
      this.$root.dispatchEvent('bookero-plugin:tracking:failed-purchase');
    },
    trackingWaitingPurchase () {
      this.$root.dispatchEvent('bookero-plugin:tracking:waiting-purchase');
    },
    trackingMixedPurchase (statuses, currency) {
      let cartValue = 0;
      let cartItems = [];

      for (let i = 0; i < this.$parent.trackingTemporaryData.cartItems.length; i++) {
        let itemData = this.$parent.trackingTemporaryData.cartItems[i];
        let status = statuses.filter(item => item.id === itemData.inquiryID);

        if (status[0] === 3) {
          cartItems.push({
            id: itemData.id,
            inquiryID: itemData.inquiryID,
            sku: itemData.sku,
            serviceName: itemData.serviceName,
            price: itemData.price
          });

          cartValue += parseFloat(itemData.price);
        }
      }

      this.$root.dispatchEvent('bookero-plugin:tracking:mixed-purchase', {
        value: cartValue,
        currency: currency,
        cartItems: JSON.parse(JSON.stringify(cartItems))
      });

      if (this.fbIntegrationEnabled) {
        let finalPrice = 0;

        for (let i = 0; i < this.$parent.trackingTemporaryData.cartItems.length; i++) {
          let itemData = this.$parent.trackingTemporaryData.cartItems[i];
          let status = statuses.filter(item => item.id === itemData.inquiryID);

          if (status[0] === 3) {
            finalPrice += parseFloat(itemData.price);
          }
        }

        if (this.gaIntegrationEnabled && 'gtag' in window) {
          let finalPrice = 0;
          let items = [];

          for (let i = 0; i < this.$parent.trackingTemporaryData.cartItems.length; i++) {
            let itemData = this.$parent.trackingTemporaryData.cartItems[i];
            let status = statuses.filter(item => item.id === itemData.inquiryID);

            if (status[0] === 3) {
              items.push({
                'item_id': itemData.id,
                'item_name': itemData.serviceName,
                'price': Number(itemData.price),
                'quantity': 1,
                'currency': currency
              });

              finalPrice += parseFloat(itemData.price);
            }
          }

          window.gtag('event', 'purchase', {
            transaction_id: this.$parent.trackingTemporaryData.cartItems[0].id,
            affiliation: 'Bookero plugin',
            value: Number(finalPrice - (finalPrice * (this.getDiscountValue / 100))),
            currency: currency,
            items: items
          });

          if (this.debugMode) {
            console.log('🟢 ➡️GTAG SEND:', 'event', 'purchase', {
              transaction_id: this.$parent.trackingTemporaryData.cartItems[0].id,
              affiliation: 'Bookero plugin',
              value: Number(finalPrice - (finalPrice * (this.getDiscountValue / 100))),
              currency: currency,
              items: items
            });
          }
        }

        if (this.fbIntegrationEnabled && 'fbq' in window) {
          window.fbq('track', 'Purchase', {
            value: finalPrice,
            currency: currency
          });

          if (this.debugMode) {
            console.log('🟢 FBQ SEND', 'track', 'Purchase', {
              value: finalPrice,
              currency: currency
            });
          }
        }

        this.$parent.trackingTemporaryData = {};
      }
    },
    trackingDiscountCode () {
      this.$root.dispatchEvent('bookero-plugin:tracking:used-discount-code');
    }
  }
}
</script>
