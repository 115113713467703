<template>
  <div :class="{ 'calendar': true }">
    <span
      v-if="getConfig.waiting_list && getInquiries.length && !getWaitingListInInquiries"
      class="waiting-list-notification"
      v-pure-html="getTranslation('blocked_reservelist_beacuse_of_cart')">
    </span>

    <div class="calendar-nav">
      <a
        href="#"
        :class="{ 'calendar-nav-prev': true, 'is-hidden': !showPrevNav }"
        @click.prevent="getMonth(-1)">
        &lsaquo;
      </a>

      <strong class="calendar-nav-month">
        {{ monthName }}
      </strong>

      <a
        v-if="!monthIsOvertake"
        href="#"
        class="calendar-nav-next"
        @click.prevent="getMonth(1)">
        &rsaquo;
      </a>
    </div>

    <div
      v-if="!monthIsValid && getCalendarData.first_free_term === null"
      :class="{ 'calendar-message': true, 'is-loading': !getCalendarData.days }">
      {{ getTranslation('month_no_free_terms') }}
    </div>

    <div
      v-if="!monthIsValid && getCalendarData.first_free_term"
      :class="{
        'calendar-message': true,
        'is-notice': true,
        'is-loading': !getCalendarData.days
      }">
      {{ getTranslation('month_no_free_terms') }} <br>
      <strong>{{ getTranslation('nearest_first_free_term') }} {{ getCalendarData.first_free_term }}.</strong> <br>
      <a href="#" @click.prevent="goToFirstFreeTerm()">{{ getTranslation('go_to_first_free_term') }}</a>
    </div>

    <div
      v-if="monthIsOvertake"
      :class="{ 'calendar-message': true, 'is-loading': !getCalendarData.days }">
      {{ getTranslation('validation_max_reservation_overtake') }}
    </div>

    <div
      v-if="getCalendarData.short_days"
      :class="{ 'calendar-days': true, 'is-loading': !getCalendarData.days }" >
      <div class="calendar-days-heading">
        <div
          v-for="(day, index) of getCalendarData.short_days"
          :key="'calendar-days-heading-' + index"
          class="calendar-days-heading-cell">
          {{ day }}.
        </div>
      </div>

      <div class="calendar-days-list">
        <div
          v-for="(item, index) of Array(getOffsetDays()).fill(0)"
          :key="'calendar-days-list-cell-offset-' + index"
          class="calendar-days-list-cell is-offset">
          &nbsp;
        </div>
        <div
          v-for="(day, index) of getCalendarData.days"
          :key="'calendary-days-list-cell-' + index"
          :ref="'calendary-days-list-cell-' + index"
          :class="{
            'calendar-days-list-cell': true,
            'is-open': !!day.open,
            'is-valid': day.valid_day || (day.waiting_list && !getInquiries.length),
            'is-waiting-list': day.waiting_list && !getInquiries.length,
            'is-selected': isSelectedDate(day.date),
            'is-sub-selected': isSubSelectedDate(day.date),
            'is-in-cart': isInCart(day.date),
            'auto-select-in-progress': hourAutoSelectInProgress
          }"
          @click="selectDate(day.date, (day.valid_day || (day.waiting_list && !getInquiries.length)), day.waiting_list)"
          @mouseenter="showTooltip(day.waiting_list && !getInquiries.length, 'calendary-days-list-cell-' + index)"
          @mouseleave="hideTooltip()">
          {{ index }}

          <!--<div
            v-if="isInCart(day.date)"
            class="calendar-days-list-cell-tooltip">
            <template v-if="getConfig.cart_enabled">
              {{ getTranslation('termin_exisits_in_cart') }}
            </template>
            <template v-else>
              {{ getTranslation('choosen_termin') }}
            </template>
            <template v-if="!day.valid_day">
              <br>
              {{ getTranslation('cant_add_more_reservation_to_termin') }}
            </template>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'calendar',
  mixins: [
    Translations
  ],
  computed: {
    ...mapGetters([
      'getCalendarData',
      'getConfig',
      'getCurrentlyBlockedDay',
      'getDate',
      'getDateMonthOffset',
      'getFormState',
      'getInquiries',
      'getMode',
      'getWaitingListInInquiries',
      'getHoursVisibility',
      'getSelectedService',
      'getServices',
      'selectedServiceIsDailyService',
      'getDaysNumber',
      'selectedServiceHasDailyEndAvailable'
    ]),
    showPrevNav () {
      return this.currentMonth > 0;
    },
    monthName () {
      if (this.getCalendarData.month_name && this.getCalendarData.year) {
        return this.getCalendarData.month_name + ' ' + this.getCalendarData.year;
      }

      return '';
    },
    monthIsValid () {
      if (typeof this.getCalendarData.valid_month === 'undefined') {
        return false;
      }

      return this.getCalendarData.valid_month;
    },
    monthIsOvertake () {
      if (typeof this.getCalendarData.valid_month === 'undefined') {
        return false;
      }

      return this.getCalendarData.valid_month === -1;
    }
  },
  watch: {
    getDate (newState, oldState) {
      this.selectedDate = newState;
    }
  },
  data () {
    return {
      currentMonth: 0,
      selectedDate: '',
      hourAutoSelectInProgress: false
    };
  },
  mounted () {
    this.currentMonth = this.getDateMonthOffset;
    this.$bus.$on('bookero-plugin-hour-auto-select', this.autoSelectDone);
  },
  methods: {
    getMonth (offset) {
      this.currentMonth = Math.max(0, this.currentMonth + offset);
      this.$store.commit('setCalendarCurrentMonth', this.currentMonth);
    },
    getOffsetDays () {
      if (!this.getCalendarData.days) {
        return;
      }

      let firstDate = this.getCalendarData.days[1].date;
      firstDate = firstDate.split('-').map(n => parseInt(n, 10));
      let dayOffset = new Date(firstDate[0], firstDate[1] - 1, firstDate[2]).getDay();

      if (dayOffset === 0) {
        dayOffset = 6;
      } else {
        dayOffset = dayOffset - 1;
      }

      return dayOffset;
    },
    getOffsetMonths () {
      return this.currentMonth;
    },
    selectDate (date, isValid, isWaitingList = false) {
      if (!isValid) {
        return;
      }

      if (this.selectedServiceIsDailyService) {
        if (date === this.getFormState.date) {
          this.$store.dispatch('setDate', '');
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        } else {
          this.$store.dispatch('setDate', {
            date,
            isWaitingList
          });
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: date });
          this.$store.commit('setDateMonthOffset', this.currentMonth);
          this.$store.commit('removeError', 'date');
        }
      } else {
        if (date === this.getFormState.date) {
          this.$store.dispatch('setDate', '');
          this.$store.dispatch('setTime', {
            time: '',
            isWaitingList: false
          });
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
        } else {
          this.$store.dispatch('setDate', date);
          this.$store.dispatch('setTime', {
            time: '',
            isWaitingList: false
          });
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: date });
          this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
          this.$store.commit('setDateMonthOffset', this.currentMonth);
          this.$store.commit('removeError', 'date');

          if (!this.getHoursVisibility) {
            this.hourAutoSelectInProgress = true;
          }
        }
      }

      setTimeout(() => {
        if (this.getMode !== 'full' && document.querySelector('#bookero-plugin .hours-section')) {
          if ('IntersectionObserver' in window) {
            let elementToObserve = document.querySelector('#bookero-plugin .hours-section');
            let hoursObserver = new IntersectionObserver((entries, observerInstance) => {
              entries.forEach(entry => {
                if (entry.intersectionRatio < 0.2) {
                  elementToObserve.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'center' });
                }

                observerInstance.unobserve(entry.target);
              });
            }, { threshold: [0.2] });

            hoursObserver.observe(elementToObserve);
          }
        }
      }, 250);
    },
    isSelectedDate (date) {
      return this.getFormState.date === date;
    },
    isSubSelectedDate (date) {
      if (!this.selectedServiceIsDailyService || !this.getFormState.date) {
        return false;
      }

      let daysNumber = this.getDaysNumber;
      let baseDate = new Date(this.getFormState.date);
      let toCompareDate = new Date(date).toISOString().split('T')[0];

      if (this.selectedServiceHasDailyEndAvailable) {
        daysNumber++;
      }

      for (let i = 2; i <= daysNumber; i++) {
        baseDate.setDate(baseDate.getDate() + 1);
        let baseDateToCompare = baseDate.toISOString().split('T')[0];

        if (baseDateToCompare === toCompareDate) {
          return true;
        }
      }

      return false;
    },
    isInCart (date) {
      return this.getCurrentlyBlockedDay(date).length > 0;
    },
    showTooltip (containsWaitingList, refID) {
      if (containsWaitingList) {
        this.$bus.$emit('show-tooltip', this.getTranslation('day_only_with_waiting_list'), this.$refs[refID][0]);
      }
    },
    hideTooltip () {
      this.$bus.$emit('hide-tooltip');
    },
    autoSelectDone () {
      this.hourAutoSelectInProgress = false;
    },
    goToFirstFreeTerm () {
      let firstFreeTerm = new Date(this.getCalendarData.first_free_term);
      let currentTime = new Date();
      let year1 = currentTime.getFullYear();
      let month1 = currentTime.getMonth();
      let year2 = firstFreeTerm.getFullYear();
      let month2 = firstFreeTerm.getMonth();
      let monthDiff = Math.abs(((year2 - year1) * 12) + (month2 - month1));
      this.currentMonth = monthDiff;
      this.$store.commit('setCalendarCurrentMonth', this.currentMonth);
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-hour-auto-select', this.autoSelectDone);
  }
}
</script>

<style lang="scss" scoped>
@import './../../assets/scss/variables.scss';

.calendar {
  margin: 0 0 30px 0;
  position: relative;

  .waiting-list-notification {
    color: $color-text-medium;
    display: block;
    font-size: 14px;
    line-height: 1.6;
    padding: 10px 0;
    text-align: center;

    & > strong {
      color: $color-primary;
      font-weight: bold;
    }
  }

  &-nav {
    background: $color-light;
    border: 1px solid $color-border;
    border-radius: $border-radius-small;
    display: flex;
    min-height: 40px;
    padding: 10px;
    position: relative;

    &-prev,
    &-next {
      border: none;
      color: $color-dark;
      font-size: 24px;
      height: 40px;
      line-height: 20px;
      overflow: hidden;
      position: absolute;
      right: 0;
      text-align: center;
      text-decoration: none;
      text-indent: -999px;
      top: 50%;
      transform: translateY(-50%);
      transform-origin: center center;
      transition: $transition-basic;
      width: 100px;

      &:before {
        border: 2px solid $color-border-alt;
        border-left: none;
        border-bottom: none;
        content: "";
        height: 8px;
        position: absolute;
        right: 30px;
        top: 50%;
        transform-origin: center center;
        transform: translateY(-50%) rotate(45deg);
        width: 8px;
      }

      &:hover {
        text-decoration: none;
        transform: translateX(5px) translateY(-50%);
      }
    }

    &-prev {
      left: 0;
      right: auto;

      &:before {
        left: 30px;
        right: auto;
        transform: translateY(-50%) rotate(-135deg);
      }

      &.is-hidden {
        opacity: 0;
        pointer-events: none;
      }

      &:hover {
        text-decoration: none;
        transform: translateX(-5px) translateY(-50%);
      }
    }

    &-month {
      color: $color-text;
      font-size: 16px;
      font-weight: bold;
      line-height: 24px;
      text-align: center;
      width: 100%;
    }
  }

  &-message {
    border: 1px solid $color-danger;
    border-radius: $border-radius-small;
    color: $color-danger;
    font-size: 14px;
    margin: 5px 0;
    padding: 5px 10px;
    text-align: center;

    &.is-notice {
      border: 1px solid $color-link;
      color: $color-link;

      a {
        text-decoration: underline!important;

        &:active,
        &:focus,
        &:hover {
          text-decoration: none!important;
        }
      }
    }
  }

  &-days {
    &-heading {
      display: flex;
      margin: 0 -8px;

      &-cell {
        color: $color-text;
        font-size: 13px;
        font-weight: bold;
        padding: 16px 0 4px 0;
        text-align: center;
        width: 14.28%;
      }
    }

    &-list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -8px;

      &-cell {
        background: $color-light;
        border: 1px solid $color-border;
        border-radius: $border-radius-small;
        color: $color-text-very-light;
        cursor: not-allowed;
        font-size: 16px;
        font-weight: 500;
        line-height: 1;
        margin: 8px;
        padding: 10px;
        position: relative;
        text-align: center;
        width: calc(14.28% - 16px);

        &.is-offset {
          background: transparent;
          border: none;
          color: $color-text;
          cursor: default;
        }

        &.is-valid {
          background: $color-primary-light;
          border: 1px solid $color-primary-light;
          color: $color-primary;
          cursor: pointer;
        }

        &.is-waiting-list {
          &:after {
            background: $color-danger;
            bottom: 3px;
            content: "";
            height: 2px;
            left: 50%;
            position: absolute;
            transform: translateX(-50%);
            width: 75%;
          }
        }

        &.is-selected {
          background: $color-primary;
          border-color: $color-primary;
          color: $color-light;
          cursor: pointer;
          font-weight: bold;
          position: relative;
          z-index: 1;

          &.auto-select-in-progress {
            color: $color-primary;

            &:before {
              animation: spinner 1s linear infinite;
              border: 2px solid $color-light;
              border-left-color: transparent!important;
              border-radius: 50%;
              content: "";
              height: 16px;
              left: calc(50% - 10px);
              position: absolute;
              top: calc(50% - 10px);
              transform-origin: center center;
              width: 16px;
            }
          }
        }

        &.is-sub-selected {
          background: lighten($color-primary, 15%);
          border-color: lighten($color-primary, 15%);
          color: $color-light;
          cursor: pointer;
          font-weight: bold;
          position: relative;

          &:before {
            background: lighten($color-primary, 15%);
            content: "";
            height: 50%;
            left: -20px;
            position: absolute;
            top: 25%;
            width: 20px;
            z-index: 0;
          }

          &:nth-child(7n + 1) {
            &:before {
              display: none;
            }
          }
        }

        &.is-selected + .is-sub-selected {
          &:before {
            background: linear-gradient(to right, $color-primary, lighten($color-primary, 15%));
          }
        }

        &.is-in-cart {
          background: $color-light;
          border: 2px solid $color-primary;
          border-width: 2px;
          color: $color-primary;
          cursor: not-allowed;
          font-weight: bold;

          &.is-valid {
            cursor: pointer;
          }

          &.is-selected {
            background: $color-primary;
            border-color: $color-primary;
            color: $color-light;
            font-weight: bold;
          }
        }
      }
    }
  }
}

@keyframes spinner {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 720px) {
  .calendar {
    &-days {
      &-list {
        margin: 0 -3px;

        &-cell {
          margin: 3px;
          padding: 10px 0;
          width: calc(14.28% - 6px);

          &.is-sub-selected {
            &:before {
              left: -10px;
              width: 10px;
            }
          }
        }
      }
    }
  }
}
</style>
