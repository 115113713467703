<template>
  <div class="customer-section">
    <div :class="{ 'field': true, 'is-required': true, 'is-invalid': hasError('name') || hasError('name-format') }">
      <label for="bookero-plugin-customer-name" v-pure-html="getTranslation('name_surname')"></label>
      <input
        v-model="name"
        @input="nameChanged"
        :readonly="nameReadonly"
        key="customer-data-name"
        id="bookero-plugin-customer-name">
      <div
        v-if="hasError('name')"
        class="error-message">
        {{ getTranslation('validation_the_field_is_required') }}
      </div>

      <div
        v-if="hasError('name-format')"
        class="error-message">
        {{ getTranslation('validation_wrong_name_format') }}
      </div>
    </div>

    <div :class="{ 'field': true, 'is-required': this.getConfig.require_mail, 'is-invalid': hasError('email') || hasError('email-format') }">
      <label for="bookero-plugin-customer-email" v-pure-html="getTranslation('email')"></label>
      <input
        v-model="email"
        @input="emailChanged"
        key="customer-data-email"
        :readonly="emailReadonly"
        id="bookero-plugin-customer-email">
      <div
        v-if="hasError('email')"
        class="error-message">
        {{ getTranslation('validation_the_field_is_required') }}
      </div>

      <div
        v-if="hasError('email-format')"
        class="error-message">
        {{ getTranslation('validation_wrong_email_format') }}
      </div>
    </div>

    <div
      v-if="!getConfig.hide_phone_field"
      :class="{ 'field': true, 'is-required': this.getConfig.require_phone, 'is-invalid': hasError('phone') || hasError('phone-format') }">
      <label for="bookero-plugin-customer-phone" v-pure-html="getTranslation('phone')"></label>
      <div class="field-phone">
        <vue-tel-input
          :defaultCountry="getConfig.phone_prefix"
          :disabledFetchingCountry="true"
          :dynamicPlaceholder="false"
          :placeholder="''"
          :validCharactersOnly="true"
          ref="phoneInput"
          v-model="phone"
          @input="phoneChanged"
          @focus="setCursorAtEnd"
          :readonly="phoneReadonly"
          id="bookero-plugin-customer-phone" />
        <div
          v-if="hasError('phone')"
          class="error-message">
          {{ getTranslation('validation_the_field_is_required') }}
        </div>
      </div>

      <div
        v-if="hasError('phone-format')"
        class="error-message">
        {{ getTranslation('validation_wrong_phone_format') }}
      </div>
    </div>
  </div>
</template>

<script>
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';
import { VueTelInput } from 'vue-tel-input';

export default {
  name: 'customer',
  mixins: [
    Translations
  ],
  components: {
    'vue-tel-input': VueTelInput
  },
  computed: {
    ...mapGetters([
      'getConfig',
      'getFormState',
      'hasError'
    ]),
    emailReadonly () {
      if (window.bookero_default_values && window.bookero_default_values.customer && window.bookero_default_values.customer.email) {
        return window.bookero_default_values.customer.email.readonly;
      }

      return false;
    },
    nameReadonly () {
      if (window.bookero_default_values && window.bookero_default_values.customer && window.bookero_default_values.customer.name) {
        return window.bookero_default_values.customer.name.readonly;
      }

      return false;
    },
    phoneReadonly () {
      if (window.bookero_default_values && window.bookero_default_values.customer && window.bookero_default_values.customer.phone) {
        return window.bookero_default_values.customer.phone.readonly;
      }

      return false;
    }
  },
  data () {
    return {
      name: '',
      email: '',
      phone: ''
    };
  },
  mounted () {
    this.$bus.$on('bookero-plugin-validate', this.validate);
    this.name = this.getFormState.name;
    this.email = this.getFormState.email;
    this.phone = this.getFormState.phone;
  },
  methods: {
    setCursorAtEnd () {
      setTimeout(() => {
        let valueToSet = this.$refs['phoneInput'].$refs['input'].value;
        this.$refs['phoneInput'].$refs['input'].value = '';
        this.$refs['phoneInput'].$refs['input'].value = valueToSet;
      }, 0);
    },
    nameChanged () {
      this.$store.commit('setCustomerName', this.name);

      if (this.hasError('name')) {
        this.$store.commit('removeError', 'name');
      }

      if (this.hasError('name-format')) {
        this.$store.commit('removeError', 'name-format');
      }
    },
    emailChanged () {
      this.$store.commit('setCustomerEmail', this.email);

      if (this.hasError('email')) {
        this.$store.commit('removeError', 'email');
      }

      if (this.hasError('email-format')) {
        this.$store.commit('removeError', 'email-format');
      }
    },
    phoneChanged (newPhone, newPhoneData) {
      if (newPhoneData.number.international) {
        this.phone = newPhoneData.number.international;
      } else {
        this.phone = '';
      }

      this.$store.commit('setCustomerPhone', this.phone);

      if (this.hasError('phone')) {
        this.$store.commit('removeError', 'phone');
      }

      if (this.hasError('phone-format')) {
        this.$store.commit('removeError', 'phone-format');
      }
    },
    validate () {
      if (this.getFormState.name === '') {
        this.$store.commit('addError', 'name');
      }

      if (this.getFormState.name !== '' && this.isInvalidName(this.getFormState.name)) {
        this.$store.commit('addError', 'name-format');
      }

      if (this.getConfig.require_mail && this.getFormState.email === '') {
        this.$store.commit('addError', 'email');
      }

      if (this.getFormState.email !== '' && this.isInvalidEmail(this.getFormState.email)) {
        this.$store.commit('addError', 'email-format');
      }

      if (this.getConfig.require_phone && this.getFormState.phone === '') {
        this.$store.commit('addError', 'phone');
      }
    },
    isInvalidName (name) {
      if (name.length < 3) {
        return true;
      }

      return false;
    },
    isInvalidEmail (email) {
      email = email.trim();

      if (email.indexOf('@') === -1) {
        return true;
      }

      if (email.split('@').length > 2) {
        return true;
      }

      if (email.indexOf('.') === -1) {
        return true;
      }

      if (email.indexOf(' ') > -1) {
        return true;
      }

      return false;
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.customer-section {
  padding: 10px 0;
  width: 100%;

  .field-phone {
    position: relative;

    /deep/ .vti__dropdown {
      &:active,
      &:focus {
        border-radius: 3px 0 0 3px;
        outline: 1px solid $color-primary!important;
      }
    }
  }

  .is-invalid {
    .field-phone {
      &:before {
        height: 45px;
      }
    }
  }
}
</style>
