<template>
  <div class="workers-section">
    <div class="field is-required">
      <label
        v-if="!getCustomConfigField('hide_worker_info')"
        for="bookero-plugin-service"
        v-pure-html="getTranslation('worker')"></label>

      <span v-if="!getConfig.show_workers_details && getCustomConfigField('use_worker_id') && !getCustomConfigField('hide_worker_info')">
        {{ workerNameById(getCustomConfigField('use_worker_id')) }}
      </span>

      <select
        v-if="!getConfig.use_styled_dropdowns && !getCustomConfigField('use_worker_id')"
        v-model="selectedWorker"
        @change="changedWorker">
        <option
          :value="0"
          key="worker-00">
          <template v-if="defaultWorkerDisabled">
            {{ getTranslation('worker') }}
          </template>
          <template v-else>
            {{ getTranslation('default_worker') }}
          </template>
        </option>
        <option
          v-for="workerData of getFilteredWorkers"
          :key="'worker-' + workerData.id"
          :value="workerData.id">
          {{ workerData.name }}
        </option>
      </select>

      <v-select
        v-if="getConfig.use_styled_dropdowns && !getCustomConfigField('use_worker_id')"
        v-model="selectedWorker"
        @input="changedWorker"
        :options="workersIds"
        :placeholder="defaultWorkerDisabled ? getTranslation('worker') : getTranslation('default_worker')"
        :custom-label="workerNameById"
        :allowEmpty="false"
        :showLabels="false"
        :searchable="false">
        <template slot="option" slot-scope="props">
          {{ workerNames[props.option] }}
        </template>
        <div slot="noOptions">
          {{ getTranslation('the_list_is_empty') }}
        </div>
      </v-select>

      <div
        v-if="getConfig.show_workers_details && selectedWorker !== 0"
        class="worker-info">
        <avatar
          :worker="workerAvatars[selectedWorker]"
          :name="workerNames[selectedWorker]" />
        <div class="worker-info-desc" v-pure-html="workerDescs[selectedWorker]"></div>
      </div>

      <div
        v-if="hasError('worker')"
        class="error-message">
        {{ getTranslation('no_worker_selected') }}
      </div>
    </div>
  </div>
</template>

<script>
import Avatar from './../elements/Avatar.vue';
import MultiSelect from 'vue-multiselect';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';

export default {
  name: 'workers',
  mixins: [
    Translations
  ],
  components: {
    'avatar': Avatar,
    'v-select': MultiSelect
  },
  computed: {
    ...mapGetters([
      'getCustomConfigField',
      'getFilteredWorkers',
      'getFormState',
      'getConfig',
      'hasError',
      'selectedServiceIsDailyService'
    ]),
    defaultWorkerDisabled () {
      return this.getCustomConfigField('disable_default_worker') || this.getConfig.disable_default_worker;
    },
    workersIds () {
      if (this.defaultWorkerDisabled) {
        return this.getFilteredWorkers.map(worker => worker.id);
      } else {
        return [0].concat(this.getFilteredWorkers.map(worker => worker.id));
      }
    },
    workerAvatars () {
      let ids = this.getFilteredWorkers.map(worker => worker.id);
      let avatars = this.getFilteredWorkers.map(worker => {
        return {
          avatar: worker.avatar,
          avatar_color: worker.avatar_color,
          avatar_initials: worker.avatar_initials
        };
      });

      let outputAvatars = {
        0: false
      };

      for (let i = 0; i < ids.length; i++) {
        outputAvatars[ids[i]] = avatars[i];
      }

      return outputAvatars;
    },
    workerNames () {
      let ids = this.getFilteredWorkers.map(worker => worker.id);
      let names = this.getFilteredWorkers.map(worker => worker.name);
      let outputNames = {
        0: this.defaultWorkerDisabled ? this.getTranslation('worker') : this.getTranslation('default_worker')
      };

      for (let i = 0; i < ids.length; i++) {
        outputNames[ids[i]] = names[i];
      }

      return outputNames;
    },
    workerDescs () {
      let ids = this.getFilteredWorkers.map(worker => worker.id);
      let descs = this.getFilteredWorkers.map(worker => worker.description);
      let outputDescs = {
        0: false
      };

      for (let i = 0; i < ids.length; i++) {
        outputDescs[ids[i]] = descs[i];
      }

      return outputDescs;
    }
  },
  data () {
    return {
      selectedWorker: 0
    };
  },
  mounted () {
    if (this.getCustomConfigField('use_worker_id')) {
      this.selectedWorker = this.getCustomConfigField('use_worker_id');
      this.$store.commit('setWorker', this.selectedWorker);
    } else {
      this.selectedWorker = this.getFormState.selectedWorker;
    }

    if (this.selectedWorker === 0 && this.getFilteredWorkers.length === 1) {
      let firstWorkerID = this.getFilteredWorkers[0].id;
      this.selectedWorker = firstWorkerID;
      this.$store.commit('setWorker', firstWorkerID);
    }

    this.$bus.$on('bookero-plugin-reset-worker', this.resetWorker);
    this.$bus.$on('bookero-plugin-validate', this.validate);
  },
  methods: {
    workerNameById (workerID) {
      if (workerID === 0) {
        if (this.defaultWorkerDisabled) {
          return this.getTranslation('worker');
        }

        return this.getTranslation('default_worker');
      }

      let filteredWorkers = this.getFilteredWorkers.filter(worker => worker.id === workerID);

      if (filteredWorkers.length) {
        return filteredWorkers[0].name;
      }

      return '';
    },
    changedWorker () {
      this.$store.commit('setWorker', this.selectedWorker);
      this.$store.commit('removeError', 'worker');
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:worker-updated', { workerID: this.selectedWorker });
    },
    resetWorker () {
      if (this.getFilteredWorkers.length === 1) {
        let firstWorkerID = this.getFilteredWorkers[0].id;
        this.selectedWorker = firstWorkerID;
        this.$store.commit('setWorker', firstWorkerID);
      } else {
        this.selectedWorker = 0;
        this.$store.commit('setWorker', 0);
      }
    },
    validate () {
      if (this.selectedServiceIsDailyService) {
        return;
      }

      if (this.defaultWorkerDisabled && !this.selectedWorker) {
        this.$store.commit('addError', 'worker');
      }
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-reset-worker', this.resetWorker);
    this.$bus.$off('bookero-plugin-validate', this.validate);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.workers-section {
  padding: 10px 0;
  width: 100%;

  .worker-wrapper {
    align-items: center;
    display: flex;

    .avatar {
      margin: 0 20px 0 0;
    }

    .worker-name {
      font-size: 16px;
    }

    .worker-desc {
      color: $color-text;
      font-size: 12px;
    }
  }

  .multiselect__option--highlight {
    .worker-wrapper {
      .worker-name {
        color: $color-text;
      }

      .worker-desc {
        color: $color-text;
      }
    }
  }

  .worker-info {
    background: lighten($color-primary, 45%);
    border-radius: $border-radius-small;
    display: flex;
    margin: 10px 0 0 0;
    padding: 20px;

    .avatar {
      margin: 0 30px 0 0;
    }

    &-desc {
      color: $color-text;
      font-size: 14px;
      width: calc(100% - 120px);
    }
  }
}

@media (max-width: 480px) {
  .workers-section {
    .worker-info {
      flex-wrap: wrap;

      .avatar {
        margin: 0 auto!important;
      }

      .worker-info-desc {
        margin-top: 20px;
        width: 100%!important;
      }
    }
  }
}
</style>
