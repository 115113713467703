<script>
export default {
  name: 'Debounce',
  created () {
    const debounceMethods = this.$options.debounceMethods;

    if (debounceMethods) {
      Object.keys(debounceMethods).forEach(methodName => {
        const delay = debounceMethods[methodName];

        if (typeof this[methodName] === 'function') {
          this[methodName] = this.debounce(this[methodName], delay);
        }
      });
    }
  },
  methods: {
    debounce (func, wait, immediate) {
      var timeout;
      return function () {
        var context = this;
        var args = arguments;
        clearTimeout(timeout);
        if (immediate && !timeout) {
          func.apply(context, args);
        }

        timeout = setTimeout(function () {
          timeout = null;
          if (!immediate) {
            func.apply(context, args);
          }
        }, wait);
      };
    }
  }
}
</script>
