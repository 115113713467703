<template>
  <div :class="{
    'dates-section': true,
    'is-active': isActive,
    'has-error': lastLoadError !== false,
    'is-worker-not-selected': defaultWorkerDisabled && !getFormState.selectedWorker,
    'is-service-not-selected': !getFormState.selectedService,
  }"
  :data-notactive-service-text="getTranslation('select_service')"
  :data-notactive-worker-text="getTranslation('select_worker')">
    <div v-if="shouldDisplayDatesList">
      <div :class="{ 'field': true, 'is-term': true, 'is-required': true, 'is-loading': terminsAreLoading, 'is-invalid': hasError('date') }">
        <label v-pure-html="getTranslation('termin')"></label>
        <select
          v-if="!getConfig.use_styled_dropdowns"
          id="bookero-plugin-termin"
          v-model="selectedTermin"
          @change="changedTermin"
          :class="{ 'is-loading': terminsAreLoading }">
          <option
            v-if="termins.length && filteredTerminsForSelect.length"
            :value="0"
            key="termin-list-00">
            {{ getTranslation('termin') }}
          </option>
          <option
            v-if="!termins.length && !filteredTerminsForSelect.length"
            :value="0"
            key="termin-list-01">
            {{ getTranslation('no_available_term') }}
          </option>
          <option
            v-if="termins.length && !filteredTerminsForSelect.length"
            :value="0"
            key="termin-list-01">
            {{ getTranslation('no_available_term') }}
          </option>
          <option
            v-for="(termin, subindex) of filteredTerminsForSelect"
            :value="termin.id"
            :key="'termin-list-' + subindex">
            <template v-if="termin.name">{{ termin.name }}</template>
            <template v-if="!termin.name"> {{ termin.date }} {{ termin.time }}</template>
            <template v-if="termin.information">
            {{ termin.information }}
            </template>
          </option>
        </select>

        <v-select
          v-if="getConfig.use_styled_dropdowns && (filteredTermins.length || (!termins.length && terminsAreLoading))"
          v-model="selectedTermin"
          @input="changedTermin"
          :class="{ 'is-loading': terminsAreLoading }"
          :options="filteredTermins"
          :custom-label="terminNameById"
          :placeholder="getTranslation('termin')"
          :allowEmpty="false"
          :showLabels="false"
          :searchable="false">
          <div slot="noOptions">
            {{ getTranslation('the_list_is_empty') }}
          </div>
        </v-select>

        <div
          v-if="!termins.length && !filteredTerminsForSelect.length && !terminsAreLoading"
          class="error-block">
          {{ getTranslation('no_available_term') }}
        </div>

        <div
          v-if="termins.length && !filteredTerminsForSelect.length && !terminsAreLoading"
          class="error-block">
          {{ getTranslation('no_available_term_waiting_list_available_but_cart_not_empty') }}
        </div>
      </div>
    </div>

    <div
      v-if="shouldDisplayCalendar"
      :class="{ 'calendar-wrapper': true, 'is-loading': calendarIsLoading, 'field': true, 'is-required': true }">
      <calendar ref="calendar" />
    </div>

    <div
      v-if="shouldDisplayWeekView"
      :class="{ 'week-wrapper': true, 'is-loading': weekIsLoading, 'field': true, 'is-required': true }">
      <week-view ref="week-view" />
    </div>

    <div
      v-if="shouldDisplayDatePicker"
      class="field is-required">
      <label>
        {{ getTranslation('date') }}
      </label>
      <date-picker
        :language="polishTranslation"
        :full-month-name="true"
        :value="selectedDate"
        @selected="setDate"
        :disabledDates="disabledDates"
        format="MMMM d, yyyy"
        :monday-first="true"></date-picker>
    </div>

    <div
      v-if="hasError('date')"
      class="error-message">
      {{ getTranslation('validation_choose_the_termin') }}
    </div>

    <div
      v-if="lastLoadError !== false"
      class="dates-section-load-error">
      <span class="dates-section-load-error-label">
        {{ getTranslation('error_when_loading_days') }}

        <a
          @click.prevent="reloadAfterError"
          class="dates-section-load-error-reload-link"
          href="#reload">
          {{ getTranslation('click_to_refresh') }}
        </a>
      </span>
    </div>
  </div>
</template>

<script>
import CommonMethods from './../mixins/CommonMethods';
import DatePicker from 'vuejs-datepicker';
import Debounce from './../mixins/Debounce.vue';
import { pl } from 'vuejs-datepicker/dist/locale';
import MultiSelect from 'vue-multiselect';
import Calendar from './../elements/Calendar';
import WeekView from './../elements/WeekView';
import Translations from './../mixins/Translations';
import { mapGetters } from 'vuex';
import Vue from 'vue';

export default {
  name: 'dates',
  mixins: [
    CommonMethods,
    Debounce,
    Translations
  ],
  props: [
    'isActive'
  ],
  components: {
    'calendar': Calendar,
    'date-picker': DatePicker,
    'v-select': MultiSelect,
    'week-view': WeekView
  },
  computed: {
    ...mapGetters([
      'getCalendarCurrentMonth',
      'getCurrentWeek',
      'getDateMonthOffset',
      'getDateWeekOffset',
      'getConfig',
      'getCustomConfigField',
      'getDate',
      'getTime',
      'getMode',
      'getFormState',
      'getSelectedService',
      'getServices',
      'hasError',
      'getInquiries',
      'selectedServiceIsDailyService',
      'selectedServiceRequiresChildren'
    ]),
    defaultWorkerDisabled () {
      return this.getCustomConfigField('disable_default_worker') || this.getConfig.disable_default_worker;
    },
    shouldDisplayDatesList () {
      return !this.selectedServiceIsDailyService && this.selectedServiceHasDates;
    },
    shouldDisplayCalendar () {
      return this.selectedServiceIsDailyService || (!this.selectedServiceHasDates && (this.getMode === 'calendar' || this.getMode === 'sticky'));
    },
    shouldDisplayWeekView () {
      return !this.selectedServiceIsDailyService && (!this.selectedServiceHasDates && this.getMode === 'full');
    },
    shouldDisplayDatePicker () {
      return !this.selectedServiceIsDailyService && (!this.selectedServiceHasDates && this.getMode === 'standard');
    },
    yesterdaysDate () {
      let dateObj = new Date();
      dateObj.setDate(dateObj.getDate() - 1);

      return dateObj;
    },
    disabledDates () {
      if (this.getConfig.max_reservation_overtake > 0) {
        let futureDateForOvertake = new Date();
        futureDateForOvertake.setDate(futureDateForOvertake.getDate() + parseInt(this.getConfig.max_reservation_overtake, 10));

        return {
          from: futureDateForOvertake,
          to: this.yesterdaysDate
        };
      }

      return {
        to: this.yesterdaysDate
      };
    },
    filteredTermins () {
      if (this.getInquiries.length) {
        return this.termins.map(termin => termin.id).filter(terminID => this.terminsWaitingList.indexOf(terminID) === -1);
      }

      return this.termins.map(termin => termin.id);
    },
    filteredTerminsForSelect () {
      if (this.getInquiries.length) {
        return this.termins.filter(termin => this.terminsWaitingList.indexOf(termin.id) === -1);
      }

      return this.termins;
    },
    selectedServiceHasDates () {
      if (this.getSelectedService !== 0) {
        let service = this.getServices.filter(service => service.id === this.getSelectedService)[0];

        if (!service) {
          return false;
        }

        return service.available_in_dates === 1;
      }

      return false;
    },
    inlineRequestTrigger () {
      return [
        this.$store.state.formState.selectedPeriodicity,
        this.getFormState.selectedCustomDuration,
        this.$store.state.formState.selectedService,
        this.$store.state.formState.selectedWorker,
        JSON.stringify(this.$store.state.formState.specialParamsValues),
        this.$store.state.formState.peopleNumber
      ].join(';');
    },
    terminsRequestTrigger () {
      return [
        this.$store.state.formState.selectedPeriodicity,
        this.getFormState.selectedCustomDuration,
        this.$store.state.formState.selectedService,
        this.$store.state.formState.selectedWorker,
        JSON.stringify(this.$store.state.formState.specialParamsValues),
        this.$store.state.formState.peopleNumber
      ].join(';');
    },
    calendarRequestTrigger () {
      if (this.selectedServiceIsDailyService) {
        return [
          this.$store.state.formState.selectedPeriodicity,
          this.getFormState.selectedCustomDuration,
          this.$store.state.formState.selectedService,
          JSON.stringify(this.$store.state.formState.specialParamsValues),
          this.$store.state.formState.peopleNumber,
          this.$store.state.formState.childrenNumber,
          this.$store.state.formState.daysNumber,
          this.$store.state.calendarState.currentMonth
        ].join(';');
      }

      return [
        this.$store.state.formState.selectedPeriodicity,
        this.getFormState.selectedCustomDuration,
        this.$store.state.formState.selectedService,
        this.$store.state.formState.selectedWorker,
        JSON.stringify(this.$store.state.formState.specialParamsValues),
        this.$store.state.formState.peopleNumber,
        this.$store.state.calendarState.currentMonth
      ].join(';');
    },
    weekRequestTrigger () {
      return [
        this.$store.state.formState.selectedPeriodicity,
        this.getFormState.selectedCustomDuration,
        this.$store.state.formState.selectedService,
        this.$store.state.formState.selectedWorker,
        JSON.stringify(this.$store.state.formState.specialParamsValues),
        this.$store.state.formState.peopleNumber,
        this.$store.state.weekState.currentWeek
      ].join(';');
    }
  },
  data () {
    return {
      pluginConfig: false,
      selectedTermin: 0,
      selectedDate: new Date(),
      terminsAreLoading: false,
      calendarIsLoading: false,
      weekIsLoading: false,
      termins: [],
      terminsWaitingList: [],
      polishTranslation: pl,
      lastRequestID: 0,
      lastLoadError: false
    };
  },
  watch: {
    terminsRequestTrigger (newState, oldState) {
      if (this.shouldDisplayDatesList) {
        this.terminsAreLoading = true;
        this.getTermins();
      }
    },
    inlineRequestTrigger (newState, oldState) {
      if (this.shouldDisplayDatePicker) {
        this.$store.dispatch('setDate', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        this.$store.dispatch('setTime', {
          time: '',
          isWaitingList: false
        });
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
      }
    },
    calendarRequestTrigger (newState, oldState) {
      if (this.shouldDisplayCalendar) {
        this.calendarIsLoading = true;
        this.$store.dispatch('setDate', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        this.$store.dispatch('setTime', {
          time: '',
          isWaitingList: false
        });
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
        this.getCalendar();
      }
    },
    weekRequestTrigger (newState, oldState) {
      if (this.shouldDisplayWeekView) {
        this.weekIsLoading = true;
        this.$store.dispatch('setDate', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        this.$store.dispatch('setTime', {
          time: '',
          isWaitingList: false
        });
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
        this.getWeek();
      }
    }
  },
  mounted () {
    this.pluginConfig = this.$store.state.pluginConfig;
    this.$bus.$on('bookero-plugin-validate', this.validate);
    this.$bus.$on('bookero-plugin-reload-date', this.reload);
    this.$bus.$on('bookero-plugin-reset-termin', this.resetTermin);
    this.selectedDate = this.getDate;
    this.lastLoadError = false;
    this.reload();
  },
  debounceMethods: {
    getTermins: 500,
    getCalendar: 500,
    getWeek: 500
  },
  methods: {
    terminNameById (terminID) {
      if (terminID === 0 && !this.termins.length) {
        return this.getTranslation('no_available_term');
      }

      if (terminID === 0 && this.termins.length) {
        return this.getTranslation('termin');
      }

      let terminToDisplay = this.termins.filter(termin => termin.id === terminID)[0];

      if (terminToDisplay) {
        let info = terminToDisplay.information || '';

        if (terminToDisplay.name) {
          return terminToDisplay.name + info;
        }

        return terminToDisplay.date + ' ' + terminToDisplay.time + info;
      }

      return '';
    },
    getTermins (checkDate = false) {
      if (!this.getFormState.selectedService) {
        return;
      }

      this.terminsAreLoading = true;
      this.lastLoadError = false;

      this.$http.get(this.$endpointURL + '/getTermins', {
        params: {
          bookero_id: this.pluginConfig.id,
          lang: this.pluginConfig.lang,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          service: this.getFormState.selectedService,
          worker: this.getFormState.selectedWorker,
          plugin_comment: JSON.stringify({
            data: {
              parameters: this.getFormState.specialParamsValues
            }
          }),
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          email: this.getFormState.email
        }
      }).then(res => {
        if (res.data.termins) {
          Vue.set(this, 'termins', JSON.parse(JSON.stringify(res.data.termins)));
          Vue.set(this, 'terminsWaitingList', JSON.parse(JSON.stringify(res.data.waiting_list)));

          if (checkDate && this.getDate !== '') {
            let termin = res.data.termins.filter(term => term.date === this.getDate && term.time === this.getTime);

            if (this.getInquiries.length) {
              termin = res.data.termins.filter(term => term.date === this.getDate && term.time === this.getTime && this.terminsWaitingList.indexOf(term.id) === -1);
            }

            if (this.selectedServiceHasDates && this.getDate && this.getTime) {
              let availableTermins = this.termins.filter(termin => termin.date === this.getDate && termin.time === this.getTime);

              if (this.getInquiries.length) {
                availableTermins = this.termins.filter(termin => termin.date === this.getDate && termin.time === this.getTime && this.terminsWaitingList.indexOf(termin.id) === -1);
              }

              if (availableTermins.length) {
                this.selectedTermin = availableTermins[0].id;
              }
            }

            if (!termin[0]) {
              this.$store.dispatch('setDate', '');
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
              this.$store.dispatch('setTime', {
                time: '',
                isWaitingList: false
              });
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
            }
          } else {
            if (this.selectedServiceHasDates && this.filteredTerminsForSelect.length === 1) {
              this.selectedTermin = this.filteredTerminsForSelect[0].id;
              this.$store.dispatch('setDate', this.filteredTerminsForSelect[0].date);
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: this.filteredTerminsForSelect[0].date });
              this.$store.dispatch('setTime', this.filteredTerminsForSelect[0].time);
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: this.filteredTerminsForSelect[0].time });
            }
          }
        } else {
          Vue.set(this, 'termins', []);
        }

        this.terminsAreLoading = false;
      }).catch(() => {
        this.lastLoadError = {
          type: 'getTermins',
          checkDate: checkDate
        };
        Vue.set(this, 'termins', []);
        Vue.set(this, 'terminsWaitingList', []);
        this.terminsAreLoading = false;
      });
    },
    resetTermin () {
      if (this.selectedServiceHasDates) {
        this.selectedTermin = 0;
        this.$store.dispatch('setDate', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
        this.$store.dispatch('setTime', '');
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
      }
    },
    changedTermin () {
      let date = '';
      let time = '';
      let isWaitingList = false;

      if (this.selectedTermin) {
        date = this.termins.filter(termin => termin.id === this.selectedTermin)[0].date;
        time = this.termins.filter(termin => termin.id === this.selectedTermin)[0].time;
        isWaitingList = this.terminsWaitingList.indexOf(this.selectedTermin) === -1 ? 1 : -1;
      }

      this.$store.dispatch('setDate', date);
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: date });
      this.$store.dispatch('setTime', {
        time: time,
        isWaitingList: isWaitingList
      });
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: time });
      this.$store.commit('removeError', 'date');
      this.$store.commit('removeError', 'hour');
    },
    getCalendar (checkDate = false) {
      if (!this.getSelectedService) {
        return;
      }

      let requestID = +new Date();
      this.lastLoadError = false;
      this.lastRequestID = requestID;
      this.calendarIsLoading = true;
      let offset = this.getCalendarCurrentMonth || 0;

      if (checkDate) {
        offset = this.getDateMonthOffset;
      }

      let paramsToSend = {
        bookero_id: this.pluginConfig.id,
        lang: this.pluginConfig.lang,
        periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
        custom_duration_id: this.getFormState.selectedCustomDuration,
        service: this.getSelectedService,
        worker: this.getFormState.selectedWorker,
        plugin_comment: JSON.stringify({
          data: {
            parameters: this.getFormState.specialParamsValues
          }
        }),
        phone: this.getFormState.phone,
        people: this.getFormState.peopleNumber,
        email: this.getFormState.email,
        plus_months: offset
      };

      if (this.selectedServiceIsDailyService) {
        paramsToSend.days = this.getFormState.daysNumber;
        paramsToSend.worker = null;

        if (this.selectedServiceRequiresChildren) {
          paramsToSend.children = this.getFormState.childrenNumber;
        }
      }

      this.$http.get(this.$endpointURL + '/getMonth', {
        params: paramsToSend
      }).then(res => {
        if (this.lastRequestID !== 0 && this.lastRequestID !== requestID) {
          return;
        }

        if (res.data.result) {
          this.$store.commit('setCalendarData', JSON.parse(JSON.stringify(res.data)));

          if (this.getDate !== '' && checkDate) {
            let days = Object.values(res.data.days);
            let day = null;

            for (let i = 0; i < days.length; i++) {
              if (days[i].date === this.getDate) {
                day = days[i];
              }
            }

            if (!(day && day.valid_day > 0)) {
              this.$store.dispatch('setDate', '');
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: '' });
            }
          }
        }

        this.calendarIsLoading = false;
      }).catch((e) => {
        console.log(e);
        this.lastLoadError = {
          type: 'getCalendar',
          checkDate: checkDate
        };
        this.calendarIsLoading = false;
      });
    },
    getWeek (checkDate = false) {
      if (!this.getSelectedService) {
        return;
      }

      let requestID = +new Date();
      this.weekIsLoading = true;
      this.lastLoadError = false;
      this.lastRequestID = requestID;
      let offset = this.getCurrentWeek || 0;

      if (checkDate) {
        offset = this.getDateWeekOffset;
      }

      this.$http.get(this.$endpointURL + '/getDays', {
        params: {
          bookero_id: this.pluginConfig.id,
          lang: this.pluginConfig.lang,
          periodicity_id: this.serviceIsCyclic() ? this.getFormState.selectedPeriodicity : 0,
          custom_duration_id: this.getFormState.selectedCustomDuration,
          service: this.getSelectedService,
          worker: this.getFormState.selectedWorker,
          plugin_comment: JSON.stringify({
            data: {
              parameters: this.getFormState.specialParamsValues
            }
          }),
          phone: this.getFormState.phone,
          people: this.getFormState.peopleNumber,
          email: this.getFormState.email,
          plus_weeks: offset,
          date: this.getDate,
          hour: this.getTime
        }
      }).then(res => {
        if (this.lastRequestID !== 0 && this.lastRequestID !== requestID) {
          return;
        }

        if (res.data.result) {
          this.$store.commit('setWeekData', JSON.parse(JSON.stringify(res.data)));

          if (this.getDate !== '' && checkDate) {
            let days = Object.values(res.data.days);
            let day = null;

            for (let i = 0; i < days.length; i++) {
              if (days[i].date === this.getDate) {
                day = days[i];
              }
            }

            if (!day) {
              this.$store.dispatch('setDate', '');
              this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { date: '' });
            }
          }
        }

        this.weekIsLoading = false;
      }).catch(() => {
        this.lastLoadError = {
          type: 'getWeek',
          checkDate: checkDate
        };
        this.weekIsLoading = false;
      });
    },
    setDate (date, checkDate = false) {
      if (typeof date === 'string' && date !== '') {
        date = date.split('-').map(n => parseInt(n, 10));
        date = new Date(date[0], date[1] - 1, date[2]);
      }

      if (!date) {
        date = new Date()
      }

      this.selectedDate = date;
      let formattedDate = [date.getFullYear(), date.getMonth() + 1, date.getDate()].map(n => n < 10 ? '0' + n : n).join('-');
      this.$store.dispatch('setDate', formattedDate);
      this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:date-set', { date: formattedDate });

      if (!checkDate) {
        this.$store.dispatch('setTime', {
          time: '',
          isWaitingList: false
        });
        this.$bus.$emit('bookero-plugin-dispatch-event', 'bookero-plugin:time-set', { time: '' });
      }

      this.$store.commit('removeError', 'date');
    },
    validate () {
      if (!this.getDate) {
        this.$store.commit('addError', 'date');
      }
    },
    reload () {
      if (this.selectedServiceHasDates) {
        this.getTermins(true);
        return;
      }

      if (this.getMode === 'calendar' || this.getMode === 'sticky') {
        this.getCalendar(true);
      }

      if (this.getMode === 'full') {
        this.getWeek(true);
      }

      if (this.getMode === 'standard') {
        this.setDate(this.getDate, true);
      }
    },
    reloadAfterError () {
      if (this.lastLoadError === false) {
        return;
      }

      this[this.lastLoadError.type](this.lastLoadError.checkDate);
    }
  },
  beforeDestroy () {
    this.$bus.$off('bookero-plugin-validate', this.validate);
    this.$bus.$off('bookero-plugin-reload-date', this.reload);
    this.$bus.$off('bookero-plugin-reset-termin', this.resetTermin);
  }
}
</script>

<style scoped lang="scss">
@import './../../assets/scss/variables.scss';

.dates-section {
  padding: 10px 0;
  position: relative;
  width: 100%;

  &:not(.is-active) {
    pointer-events: none;

    &:after {
      align-items: center;
      background: rgba(255, 255, 255, 1);
      color: rgba(0, 0, 0, .5);
      content: "";
      display: flex;
      font-size: 20px;
      font-weight: bold;
      height: 100%;
      justify-content: center;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
    }

    &.is-service-not-selected {
      min-height: 100px;
      overflow: hidden;

      &:after {
        content: attr(data-notactive-service-text);
      }
    }
    &.is-worker-not-selected {
      min-height: 100px;
      overflow: hidden;

      &:after {
        content: attr(data-notactive-worker-text);
      }
    }

    &.is-service-not-selected.is-worker-not-selected {
      min-height: 100px;
      overflow: hidden;

      &:after {
        content: attr(data-notactive-service-text);
      }
    }
  }

  &-load-error {
    align-items: center;
    background: rgba(255, 255, 255, .9);
    display: none;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 100;

    &-label {
      color: $color-danger;
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      width: 80%;
    }

    &-reload-link {
      color: $color-link;
      color: var(--bookero-plugin-color-link);
      display: block;
      margin: 15px 0 0 0;
    }
  }

  &.has-error {
    .dates-section-load-error {
      display: flex;
    }
  }

  .is-term {
    margin: 0 auto 10px auto;
    max-width: 640px;

    &.is-loading {
      & > * {
        opacity: 0.2;
      }

      &:after {
        animation: spinner 1s linear infinite;
        border: 2px solid $color-primary;
        border-color: var(--bookero-plugin-color-primary);
        border-top-color: $color-light!important;
        border-radius: $border-radius-full;
        content: "";
        display: block;
        height: 24px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 24px;
      }
    }
  }

  .week-wrapper {
    label {
      margin: 0 auto 10px auto;
      max-width: 640px;
    }
  }

  .week-wrapper,
  .calendar-wrapper {
    min-height: 100px;
    position: relative;

    &.is-loading {
      & > * {
        opacity: 0.2;
      }

      &:after {
        animation: spinner 1s linear infinite;
        border: 2px solid $color-primary;
        border-color: var(--bookero-plugin-color-primary);
        border-top-color: $color-light!important;
        border-radius: $border-radius-full;
        content: "";
        display: block;
        height: 24px;
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translateX(-50%) translateY(-50%);
        width: 24px;
      }
    }
  }

  /deep/ .vdp-datepicker {
    margin: 0 0 30px 0;
  }

  /deep/ .vdp-datepicker__calendar {
    border-radius: $border-radius-small;
    left: 50%;
    top: 54px;
    transform: translateX(-50%);

    .cell {
      border-radius: $border-radius-full;
      font-size: 13px;
      font-weight: 500;

      &.day,
      &.day-header {
        height: 36px;
        line-height: 34px;
        margin: 0 3px;
        width: 36px;
      }

      &:not(.blank):not(.disabled).day:hover,
      &:not(.blank):not(.disabled).month:hover,
      &:not(.blank):not(.disabled).year:hover {
        background: $color-primary-light;
        background: var(--bookero-plugin-color-primary-light);
        border-color: $color-primary-light;
        border-color: var(--bookero-plugin-color-primary-light);
        color: $color-primary;
        color: var(--bookero-plugin-color-primary);
      }

      &.selected {
        background: $color-primary;
        background: var(--bookero-plugin-color-primary);
        color: $color-light;
      }
    }
  }
}

@keyframes spinner {
  from {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }

  to {
    transform: translateX(-50%) translateY(-50%) rotate(360deg);
  }
}
</style>
